import { Modal, Button } from 'antd';
import React, { Component } from 'react';
import moment from "moment";

import { withRouter } from "react-router-dom";

const ModalContactNote = (props) => {
    const { visible, hide, item } = props;

    return <Modal
        title={'Nhắc nhở chăm sóc'}
        visible={visible}
        footer={null}
        onCancel={hide}
        centered
    >
        {
            item && <React.Fragment>
                <div>
                    Bạn có lịch chăm sóc khách hàng {item.thread_title} vào lúc {moment(item.notify_time).format("HH:mm DD/MM/YYYY")}
                </div>
                <div className='d-flex justify-content-end mt-2'>
                    <Button type="default" onClick={hide}>Bỏ qua</Button>
                    <Button className='ml-3' type="primary" onClick={() => {
                        hide();
                        props.history.push(`/chat/${item.contact_id}`)
                    }}>Chăm sóc ngay</Button>
                </div>
            </React.Fragment>
        }
    </Modal>
}

export default withRouter(ModalContactNote);
