import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Select, Tooltip, Row, Col, Result, Radio, Pagination, Spin, DatePicker, Table, Alert } from 'antd';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import NumberFormat from 'react-number-format';

import locale from 'antd/es/date-picker/locale/vi_VN';

import WidgetFormEdit from "./Widget/formEdit";

export default class BookingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            visibleFormEdit: {
                visible: false
            }
        }
        this.limit = 20;
        this.page = 1;

        this.timeSearch = null;
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (option = {}) => {
        try {
            let { type } = option;
            this.setState({ loading: true });
            if (type == 'reset') this.page = 1;
            let params = {
                limit: this.limit,
                page: this.page,
                booking_time: moment().format("YYYY-MM-DD")
            }
            if (this.formFilter){
                params = { ...params, ...this.formFilter.getFieldsValue() };
                if(params['booking_time']) params['booking_time'] = params['booking_time'].format("YYYY-MM-DD")            
            }

            if (params['time']) {
                params['time_start'] = params['time'][0].format('YYYY-MM-DD');
                params['time_end'] = params['time'][1].format('YYYY-MM-DD');
                delete params['time'];
            }

            let res = await fetchData({
                url: `api/v1/manager/booking?${qs.stringify(params)}`
            });
            this.setState({
                listData: res.data,
                total: res.total,
                loading: false
            }, () => {
                this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Danh sách người dùng",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    showCall = (id, type) => {
        this.setState({
            visibleCall: true,
            typeCalled: type,
            error: null
        }, () => {
            setTimeout(() => {
                this.formCall.setFieldsValue({
                    booking_id: id,
                    type_called: type
                })
            })
        })
    }

    submitCall = async (values) => {
        try {
            this.setState({ loading_call: true });

            await this.props.fetchData({
                url: `api/v1/callcenter/call2`,
                method: 'post',
                data: values
            })

            this.setState({ loading_call: false, visibleCall: false }, () => {
                this.props.message({
                    // title: "Thực hiện cuộc gọi",
                    content: "Đang kết nối cuộc gọi, vui lòng chờ chút!",
                    type: "success"
                })
            });
        } catch (err) {
            this.setState({ error: err.message || "Đã có lỗi xảy ra ", loading_call: false })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <WidgetFormEdit
                    {...this.state.visibleFormEdit}
                    hide={() => this.setState({ visibleFormEdit: { visible: false } })}
                    companySelected={this.props.companySelected}
                    onFinish={() => this.getListData()}
                />
                <Modal
                    title={"Thực hiện cuộc gọi"}
                    visible={this.state.visibleCall}
                    onCancel={() => this.setState({
                        visibleCall: false
                    })}
                    footer={null}
                >
                    <Form
                        ref={(evt) => this.formCall = evt}
                        onFinish={this.submitCall}
                        initialValues={{
                            type_called_staff: 1
                        }}
                        layout="vertical"
                    >
                        {
                            this.state.error ? <Alert type="error" description={this.state.error} /> : null
                        }
                        <Form.Item name="booking_id" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="type_called" noStyle>
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="type_caller" noStyle>
                            <Input type="hidden" />
                        </Form.Item>

                        {
                            this.state.typeCalled == 2 && <Form.Item name="type_called_staff" style={{ marginTop: "20px" }}>
                                <Select style={{ width: "100%" }}>
                                    <Select.Option value={1}>Máy lẻ của nhân viên</Select.Option>
                                    <Select.Option value={2}>Di động của nhân viên</Select.Option>
                                </Select>
                            </Form.Item>
                        }
                        <div className="d-flex justify-content-between" style={{ marginTop: "20px" }}>
                            <Button type="primary" loading={this.state.loading_call && this.formCall.getFieldValue("type_caller") == 1} size="large" onClick={() => {
                                this.formCall.setFieldsValue({ type_caller: 1 });
                                this.formCall.submit();
                            }}><i className="far fa-phone-office mr-2"></i> Kết nối máy lẻ</Button>
                            <Button type="primary" loading={this.state.loading_call && this.formCall.getFieldValue("type_caller") == 2} size="large" onClick={() => {
                                this.formCall.setFieldsValue({ type_caller: 2 });
                                this.formCall.submit();
                            }}><i className="far fa-mobile mr-2"></i> Kết nối di động</Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý lịch hẹn: {this.state.total}</h3>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                                booking_time: moment()
                            }}
                            onValuesChange={(e) => {
                                let timeout = 0;
                                if (e.fullname || e.booking_code) {
                                    timeout = 300;
                                }
                                if (this.searchFilter) clearTimeout(this.searchFilter)
                                this.searchFilter = setTimeout(() => {
                                    this.getListData()
                                }, timeout)
                            }}
                        >
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="fullname">
                                        <Input placeholder="Họ và tên" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="booking_code">
                                        <Input placeholder="Mã" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="address_code">
                                        <Input placeholder="Chi nhánh" />
                                    </Form.Item>
                                </div>
                                {
                                    this.props.UserLogin.role_action.indexOf("care_booking") == -1 && <div className="col-md-2">
                                        <Form.Item name="status">
                                            <Select onChange={(e) => this.handleFilterData({ type: 'select', value: e })}>
                                                <Select.Option value="">Trạng thái</Select.Option>
                                                <Select.Option value={1}>Đang chờ</Select.Option>
                                                <Select.Option value={2}>Thành công</Select.Option>
                                                <Select.Option value={3}>Thất bại</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                }
                                <Form.Item name="booking_time" className="col-md-3">
                                    <DatePicker
                                        locale={locale}
                                        format={'DD-MM-YYYY'}
                                        allowClear={true}
                                        style={{ width: "100%" }}
                                        placeholder="Ngày khám bệnh"
                                        defaultValue={moment()}
                                    />
                                </Form.Item>
                                <Form.Item name="time" className="col-md-3">
                                    <DatePicker.RangePicker
                                        locale={locale}
                                        format={'DD-MM-YYYY'}
                                        ranges={{
                                            'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                            'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                            'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                            'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                        }}
                                        allowClear={false}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                                <div className="col-auto">
                                    <Button type="primary" onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData();
                                    }}>Xóa lọc</Button>
                                </div>
                                <div className="col-auto" >
                                    <Tooltip title={'Làm mới'}>
                                        <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                                    </Tooltip>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Table
                            dataSource={this.state.listData}
                            columns={[
                                {
                                    title: "#",
                                    index: "#",
                                    render: (item, value, index) => {
                                        return (this.page * this.limit - this.limit) + index + 1
                                    }
                                },
                                {
                                    title: "Thông tin",
                                    render: (item) => {
                                        return <React.Fragment>
                                            <p className='mb-1'>Mã: {item.booking_code}</p>
                                            <p className='mb-0'>Họ tên: {item.fullname}</p>
                                        </React.Fragment>
                                    }
                                },
                                // {
                                //     title: "Tên",
                                //     dataIndex: "fullname"
                                // },
                                {
                                    title: "SDT",
                                    dataIndex: "phone",
                                    className: this.props.UserLogin.role_action.indexOf("care_booking") > -1 ? "d-none" : "",
                                },
                                // {
                                //     title: "Chi nhánh",
                                //     dataIndex: "address_code"
                                // },
                                {
                                    title: "Ghi chú",
                                    dataIndex: "note",
                                    render: (item, record) => {
                                        let texts = [];
                                        if (record.dich_vu) texts.push(record.dich_vu.name);
                                        if (record.bac_sy) texts.push(record.bac_sy.name);
                                        if (record.note) texts.push(record.note);
                                        return <Input.TextArea value={texts.join(" | ")} style={{width: "240px"}}/>
                                    }
                                },
                                {
                                    title: "Trạng thái",
                                    className: this.props.UserLogin.role_action.indexOf("care_booking") > -1 ? "d-none" : "",
                                    render: (item) => {
                                        return item.status != 0 ? <span className="text-success">Thành công</span> : item.status == 0 && item.booking_timestamp >= Date.now() ? <span>Đang chờ</span> : <span className="text-danger">Thất bại</span>
                                    }
                                },
                                {
                                    title: "Lịch hẹn",
                                    dataIndex: "booking_time"
                                },
                                {
                                    title: "Chi phí",
                                    className: this.props.UserLogin.role_action.indexOf("care_booking") > -1 ? "d-none" : "",
                                    render: (item) => {
                                        return <div><NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} /> đ</div>
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    render: (item) => moment(item.created_time).format('HH:mm DD-MM-YYYY')
                                },
                                {
                                    title: "Hành động",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            {
                                                this.props.UserLogin.role_action.indexOf("care_booking") > -1 ?
                                                    <div className="d-flex justify-content-end">
                                                        <Tooltip title="Liên hệ khách">
                                                            <Button size="small" type="primary" onClick={() => {
                                                                this.showCall(item._id, 1)
                                                            }}>
                                                                <i className="far fa-phone"></i>
                                                            </Button>
                                                        </Tooltip>
                                                        {
                                                            item.type == 0 || item.type == 3 ?
                                                                <Tooltip title="Liên hệ nhân viên">
                                                                    <Button className="ml-2" size="small" type="primary" onClick={() => {
                                                                        this.showCall(item._id, 2)
                                                                    }}>
                                                                        <i className="far fa-headset"></i>
                                                                    </Button>
                                                                </Tooltip> : null
                                                        }
                                                    </div> : <Tooltip title="Xem tin nhắn">
                                                        <Button size="small" type="primary" onClick={() => {
                                                            window.open(`/chat/${item.contact_id}`, '_blank');
                                                        }}>
                                                            <i className="far fa-eye"></i>
                                                        </Button>
                                                    </Tooltip>
                                            }
                                            <Tooltip title="Chỉnh sửa lịch hẹn">
                                                <Button className="ml-2" size="small" disabled={this.props.UserLogin._id != item.created_by || item.status != 0}
                                                    onClick={() => {
                                                        this.setState({
                                                            visibleFormEdit: {
                                                                visible: true,
                                                                item: item
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <i className="far fa-edit"></i>
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            rowKey="_id"
                            loading={this.state.loading}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                            scroll={{
                                x: true
                            }}
                        />
                </div>
            </div>
        )
    }
}
