import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';
import { apis, fetchData } from '../../../lib/apis';
import { ExclamationCircleOutlined, UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, LockOutlined, ReloadOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';
import NumberFormat from 'react-number-format';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';

import { list_year, list_trieuchung } from "../../khaibaoyte/data";
import { formatPrice } from '../../../lib/helpers';
import WidgetResultExport from './widgets/modalResultExport';

export default class StatisticView extends Component {
    constructor(props) {
        super(props);
        let tabActive = "statistic_revenue";

        switch (props.match.params.type) {
            case "doanh-thu":
                tabActive = "statistic_revenue";
                break;
            case "tuong-tac-tong-hop":
                tabActive = "statistic_interactive";
                break;
            case "tuong-tac-chi-tiet":
                tabActive = "statistic_interactive_detail";
                break;
            case "khao-sat":
                tabActive = "statistic_rating";
                break;
            case "nhan":
                tabActive = "statistic_tag";
                break;
            case "kham-benh":
                tabActive = "statistic_khambenh";
                break;
            case "dich-te":
                tabActive = "statistic_dichte";
                break;
            case "cham-soc-lich-hen":
                tabActive = "statistic_care_booking";
                break;
            case "khieu-nai":
                tabActive = "statistic_feedback";
                break;
            default:

        }

        this.tabs_list = [
            {
                title: "Doanh thu",
                key: "statistic_revenue"
            },
            {
                title: "Tương tác tổng hợp",
                key: "statistic_interactive"
            },
            {
                title: "Tương tác chi tiết",
                key: "statistic_interactive_detail"
            },
            {
                title: "Khảo sát",
                key: "statistic_rating"
            },
            {
                title: "Nhãn",
                key: "statistic_tag"
            },
            {
                title: "Khám bệnh",
                key: "statistic_khambenh"
            },
            {
                title: "Dịch tễ",
                key: "statistic_dichte"
            },
            {
                title: "Chăm sóc lịch hẹn",
                key: "statistic_care_booking"
            },
            {
                title: "Góp ý",
                key: "statistic_feedback"
            },
            {
                title: "Sms brandname",
                key: "statistic_autosms"
            },
        ]

        this.state = {
            listData: [],
            loading: false,
            listDataInteractive: [],
            totalDataInteractive: 0,
            listDataInteractiveAggregation: [],
            listDataRating: [],
            listDataContactTag: [],
            totalDataRating: 0,
            listCompany: [],
            listStaff: [],
            tabActive: tabActive,
            listService: [],
            listTag: [],
            listDataKhamBenh: [],
            listDoctor: [],
            listProvince: [],
            listDataDichTe: [],
            listDataCareBooking: [],
            dataSms: {
                total: 0,
                data: []
            },
            listBrandName: [],
            visibleExportFile: {
                visible: false,
                file: null
            }
        }
        this.dataInteracive = {
            limit: 20,
            page: 1
        }
        this.dataRating = {
            limit: 20,
            page: 1
        }
        this.pagination = {
            limit: 20,
            page: 1
        }
    }
    componentWillMount() {
        this.getListService();
        this.getListProvince();
        this.getListDoctor();
        this.getListTag();
        this.getListBrandName();
        this.getListStaff();

        let role_action = this.props.UserLogin.role_action;

        this.tabs_list = this.tabs_list.filter((item, i) => {
            if(role_action.indexOf("view_report") > -1 || role_action.indexOf(`view_report_${i+1}`) > -1){
                return true;
            }
            return false
        })

        this.setState({
            tabActive: this.tabs_list[0]['key']
        })
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
        this.getListData();
    }
    getListData = async (options = {}) => {
        try {
            const { statistic_type, export_file } = options;

            let type = statistic_type || this.state.tabActive;

            this.setState({ loading: true });
            let params = {
                time_start: moment().startOf("day").format('YYYY-MM-DD'),
                time_end: moment().format('YYYY-MM-DD')
            }
            if (this.formFilter) {
                params = {
                    ...params,
                    ...this.formFilter.getFieldsValue()
                };
            }
            if(export_file){
                params['export_file'] = export_file;
            }
            let url = "";
            if (type == "statistic_revenue") {
                url = `api/v1/statistic/revenue`;
            } else if (type == "statistic_interactive_detail") {
                url = `api/v1/statistic/interactive`;
            } else if (type == "statistic_interactive") {
                url = `api/v1/statistic/interactive/aggregation`;
            } else if (type == "statistic_rating") {
                url = `api/v1/statistic/rating`;
            } else if (type == "statistic_tag") {
                url = `api/v1/statistic/tag`;
            } else if (type == "statistic_khambenh") {
                url = `api/v1/statistic/khambenh`;
            } else if (type == "statistic_dichte") {
                url = `api/v1/statistic/dichte`;
            } else if (type == "statistic_care_booking") {
                url = `api/v1/statistic/care_booking`;
            } else if (type == "statistic_feedback") {
                url = `api/v1/statistic/feedback`;
            } else if (type == "statistic_autosms") {
                url = `api/v1/statistic/sms`;
                params = { ...params, ...this.pagination };
            }

            if (params['time']) {
                params['time_start'] = params['time'][0].format('YYYY-MM-DD');
                params['time_end'] = params['time'][1].format('YYYY-MM-DD');
                delete params['time'];
            }

            let res = await fetchData({
                url: url + `?${qs.stringify(params)}`
            });

            if(export_file == 1){
                this.setState({
                    loading: false,
                    visibleExportFile: {
                        visible: true,
                        file: res.data
                    }
                })
                return
            } else if(export_file == 2){
                this.setState({
                    loading: false
                })
                this.props.postMessageApp({
                    type: 'openURL',
                    url: `${apis.host}${res.data}`
                })
                return
            }

            if (type == "statistic_revenue") {
                this.setState({
                    listData: res.data,
                    loading: false
                })
            } else if (type == "statistic_interactive_detail") {
                this.setState({
                    listDataInteractive: res.data,
                    loading: false,
                    totalDataInteractive: res.total || res.data.length
                })
            } else if (type == "statistic_interactive") {
                this.setState({
                    listDataInteractiveAggregation: res.data,
                    loading: false
                })
            } else if (type == "statistic_rating") {
                this.setState({
                    listDataRating: res.data,
                    loading: false,
                    totalDataRating: res.total
                })
            } else if (type == "statistic_tag") {
                this.setState({
                    listDataContactTag: res.data,
                    loading: false
                })
            } else if (type == "statistic_khambenh") {
                this.setState({
                    listDataKhamBenh: res.data,
                    loading: false
                })
            } else if (type == "statistic_dichte") {
                this.setState({
                    listDataDichTe: res.data,
                    loading: false
                })
            } else if (type == "statistic_care_booking") {
                this.setState({
                    listDataCareBooking: res.data,
                    loading: false
                })
            } else if (type == "statistic_feedback") {
                this.setState({
                    listDataFeedback: res.data,
                    loading: false
                })
            } else if (type == "statistic_autosms") {
                this.setState({
                    dataSms: {
                        total: res.total,
                        data: res.data
                    },
                    loading: false
                })
            }
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    exportToCSV = (options = {}) => {
        let { dom, json, cols, rows, statistic_type } = options;

        if(this.props.isAppNative){
            // let export_file = (json || dom) && this.props.isAppNative ? 2 : 1;
            this.getListData({
                export_file: 2,
                statistic_type: statistic_type
            })
            return 
        }

        cols = cols || Array.apply(null, Array(8)).map(() => { return 20 })

        var fileName = prompt("Nhập tên file", "file-export");
        if (fileName == null || fileName == "") {
            return
        }

        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExtension = '.xlsx';

        let ws = null;

        if(json){
            ws = XLSX.utils.json_to_sheet(json);
        } else{
            ws = XLSX.utils.table_to_sheet(dom, {
                raw: true,
                type: 'string'
            });
        }

        if(cols) ws['!cols'] = cols.map((item) => {
            return {
                wch: item
            }
        });

        if(rows) ws['!rows'] = rows.map((item) => {
            return {
                hpx: item
            }
        });

        let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        let data = new Blob([excelBuffer], { type: fileType });

        setTimeout(() => {
            FileSaver.saveAs(data, fileName + fileExtension);
        }, 1000)

    }


    getListCompany = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/statistic/company`
            });
            this.setState({
                listCompany: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }
    getListService = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/service/list?type=LIST_DV`
            });
            this.setState({
                listService: this.parseFolder(res.data)
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListTag = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/company/tag/list?limit=0`
            });
            this.setState({
                listTag: this.parseFolderTag(res.data)
            }, () => {
                console.log(this.state.listTag);
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    parseFolder = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].service_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.service_id;
            node['value'] = node.service_id;
            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    parseFolderTag = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].tag_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.tag_name;
            node['key'] = node.tag_id;
            node['value'] = node.tag_id;
            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    getListStaff = async () => {
        try {
            this.setState({
                loadingGetStaff: true
            })
            let params = {
                
            }

            if (this.state.tabActive == "statistic_care_booking") {
                params['role_action'] = 'care_booking';
            }

            let res = await fetchData({
                url: `api/v1/statistic/staff?${qs.stringify(params)}`
            });

            console.log(this.parseDataSelectStaff(res.data));

            this.setState({
                listStaff: this.parseDataSelectStaff(res.data),
                loadingGetStaff: false
            })
        } catch (err) {
            this.setState({
                loadingGetStaff: false
            })
        }
    }

    parseDataSelectStaff = (data) => {
        let role_menus = {
            sale: "Tư vấn viên",
            collaborator: "Cộng Tác Viên",
            collaborator2 : "CTV Bác Sỹ"
        }
        let result = {};
        data.forEach((item) => {
            result[item.role_menu] = (result[item.role_menu] || []).concat([{
                key: item._id,
                value: item._id,
                title: `${item.code} - ${item.fullname}`
            }]);
        })

        return Object.keys(result).map((key) => {
            return {
                title: role_menus[key] || key,
                value: key,
                key: key,
                children: result[key]
            }
        })
    }

    getListProvince = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/geo/list`
            });
            this.setState({
                listProvince: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListDoctor = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/company/service/list?type=LIST_BS&parent_id=-1`
            });
            this.setState({
                listDoctor: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListBrandName = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/statistic/brandname`
            });
            this.setState({
                listBrandName: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    render() {
        return (
            <div className="list-connect content-m d-flex">
                <WidgetResultExport 
                    {...this.state.visibleExportFile}
                    onCancel={() => this.setState({ visibleExportFile: { visible: false }})}
                    copyText={this.props.copyText}
                />

                <div className="list-c">
                    <Tabs activeKey={this.state.tabActive} type="card" onChange={(e) => {
                        this.pagination = {
                            limit: 20,
                            page: 1
                        }
                        this.setState({
                            tabActive: e
                        }, () => {
                            this.formFilter.resetFields();
                            this.getListData();
                        })
                    }}
                    >
                        {
                            this.tabs_list.map((item, i) => {
                                return <Tabs.TabPane tab={item.title} key={item.key}>
                                    </Tabs.TabPane>;
                            })
                        }
                        
                        {/* <Tabs.TabPane tab="Tương tác tổng hợp" key="statistic_interactive">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Tương tác chi tiết" key="statistic_interactive_detail">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Khảo sát" key="statistic_rating">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Nhãn" key="statistic_tag">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Khám bệnh" key="statistic_khambenh">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Dịch tễ" key="statistic_dichte">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Chăm sóc lịch hẹn" key="statistic_care_booking">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Góp ý" key="statistic_feedback">
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sms brandname" key="statistic_autosms">
                        </Tabs.TabPane> */}
                    </Tabs>
                    <div style={{ marginBottom: "10px", marginTop: "15px" }} className="table-header">
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                time: [moment().startOf("day"), moment()],
                                cus_type: "",
                                is_cus_new: "",
                                birthday: "",
                                province: "",
                                doctor: "",
                                vung_dich: "",
                                tiem_vaccince: ""
                            }}
                            onValuesChange={(e) => {
                                if(e.time) return;
                                let timout = 0;
                                if (e.fullname || e.phone || e.booking_code) {
                                    timout = 300;
                                }
                                if (this.timeout_getdata) clearTimeout(this.timeout_getdata);

                                this.timeout_getdata = setTimeout(() => {
                                    this.getListData()
                                }, timout)
                            }}
                        >
                            <div className="row">
                                {
                                    ["statistic_revenue", "statistic_interactive", "statistic_interactive_detail", "statistic_tag", "statistic_care_booking", "statistic_khambenh"].indexOf(this.state.tabActive) > -1 ?
                                        <Form.Item name="sale_id" className="col-md-2">
                                            {/* <Select
                                                loading={this.state.loadingGetStaff}
                                                allowClear
                                                placeholder="Chọn nhân viên"
                                            >
                                                {
                                                    this.state.listStaff.map((item) => {
                                                        return <Select.Option key={item._id} value={item._id}>{item.code} - {item.fullname}</Select.Option>
                                                    })
                                                }
                                            </Select> */}
                                            <TreeSelect 
                                                loading={this.state.loadingGetStaff}
                                                allowClear
                                                placeholder="Chọn nhân viên"
                                                treeData={this.state.listStaff}
                                                treeDefaultExpandAll
                                            />
                                        </Form.Item> : null
                                }
                                {
                                    this.state.tabActive == "statistic_revenue" ?
                                        <React.Fragment>
                                            {/* <Form.Item name="service_id" className="col-md-2">
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.listService}
                                                    placeholder="Dịch vụ"
                                                    allowClear
                                                />
                                            </Form.Item> */}
                                            <Form.Item name="cus_type" className="col-md-2">
                                                <Select>
                                                    <Select.Option value="">Loại khách</Select.Option>
                                                    <Select.Option value={1}>Khách cũ</Select.Option>
                                                    <Select.Option value={2}>Khách mới</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="booking_code" className="col-md-2">
                                                <Input placeholder="Mã lịch hẹn vd: 030821FB001" />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_tag" ?
                                        <React.Fragment>
                                            <Form.Item name="tag_id" className="col-md-2">
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.listTag}
                                                    placeholder="Chọn nhãn"
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_interactive_detail" ?
                                        <React.Fragment>
                                            <Form.Item name="is_cus_new" className="col-md-2">
                                                <Select>
                                                    <Select.Option value="">Chọn loại khách</Select.Option>
                                                    <Select.Option value={1}>Khách mới</Select.Option>
                                                    <Select.Option value={2}>Khách cũ</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_khambenh" ?
                                        <React.Fragment>
                                            <Form.Item name="fullname" className="col-md-2">
                                                <Input placeholder="Tìm theo họ tên.." />
                                            </Form.Item>
                                            <Form.Item name="birthday" className="col-md-auto">
                                                <Select>
                                                    <Select.Option value="">Năm sinh</Select.Option>
                                                    {
                                                        list_year.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="province" className="col-md-2">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option value="">Tỉnh/ Tp</Select.Option>
                                                    {
                                                        this.state.listProvince.map((item) => {
                                                            return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="doctor" className="col-md-2">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option value="">Bác sỹ</Select.Option>
                                                    {
                                                        this.state.listDoctor.map((item) => {
                                                            return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_dichte" ?
                                        <React.Fragment>
                                            <Form.Item name="fullname" className="col-md-2">
                                                <Input placeholder="Tìm theo tên.." />
                                            </Form.Item>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Tìm theo số điện thoại.." />
                                            </Form.Item>
                                            <Form.Item name="booking_code" className="col-md-2">
                                                <Input placeholder="Tìm theo mã lịch hẹn.." />
                                            </Form.Item>

                                            <Form.Item name="trieu_chung" className="col-md-3">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    placeholder="Triệu chứng"
                                                    allowClear
                                                    mode="multiple"
                                                >
                                                    {
                                                        list_trieuchung.map((item) => {
                                                            return <Select.Option value={item.name}>{item.title}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="vung_dich" className="col-md-2">
                                                <Select
                                                >
                                                    <Select.Option value="">Đi tới vùng dịch</Select.Option>
                                                    <Select.Option value="1">Có</Select.Option>
                                                    <Select.Option value="2">Không</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="tiem_vaccince" className="col-md-2">
                                                <Select
                                                >
                                                    <Select.Option value="">Tiêm vaccine</Select.Option>
                                                    <Select.Option value="1">Đã tiêm đủ 2 mũi</Select.Option>
                                                    <Select.Option value="2">Chưa tiêm đủ 2 mũi</Select.Option>
                                                </Select>
                                            </Form.Item>

                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_care_booking" ?
                                        <React.Fragment>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Nhập số điện thoại.." />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_feedback" ?
                                        <React.Fragment>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Nhập số điện thoại.." />
                                            </Form.Item>
                                            <Form.Item name="contentment" className="col-md-2">
                                                <Select placeholder="Kết quả" allowClear>
                                                    <Select.Option value={1}>Hài lòng</Select.Option>
                                                    <Select.Option value={2}>Không hài lòng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_autosms" ?
                                        <React.Fragment>
                                            <Form.Item name="brandname_id" className="col-md-2">
                                                <Select placeholder="Brandname" allowClear>
                                                    {
                                                        this.state.listBrandName.map((item) => {
                                                            return <Select.Option value={item._id}>{item.brandname}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="campaign_code" className="col-md-2">
                                                <Input placeholder="Nhập mã chiến dịch.." />
                                            </Form.Item>
                                            <Form.Item name="campaign_type" className="col-md-2">
                                                <Select placeholder="Loại chiến dịch" allowClear>
                                                    <Select.Option value={"SMSMKT"}>SMS Marketing</Select.Option>
                                                    <Select.Option value={"SMSREMAKT"}>SMS Remarketing</Select.Option>
                                                    <Select.Option value={"SMSTARGET"}>SMS Retargeting</Select.Option>
                                                    <Select.Option value={"SMSSTAFF"}>SMS StaffSMS</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="status" className="col-md-2">
                                                <Select placeholder="Trạng thái" allowClear>
                                                    <Select.Option value={2}>Thành công</Select.Option>
                                                    <Select.Option value={3}>Thất bại</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }
                                <Form.Item className="col-md-3" name="time">
                                    <DatePicker.RangePicker
                                        locale={locale}
                                        ranges={{
                                            'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                            'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                            'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                            'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                        }}
                                        format="DD/MM/YYYY"
                                        allowClear={false}
                                        style={{ width: "100%" }}
                                        inputReadOnly
                                        onOpenChange={(e) => {
                                            if(!e){
                                                this.getListData();
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <div className="col-auto">
                                    <Button type="primary" onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData();
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <Tabs
                        type="line"
                        activeKey={this.state.tabActive}
                        renderTabBar={(props) => {
                            return null
                        }}
                    >
                        <Tabs.TabPane key="statistic_revenue">
                            <div className="table-responsive">
                                <div className="text-right">
                                    {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_revenue"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_revenue",
                                            json: this.state.listData.map((item, i) => {
                                                return {
                                                    "STT": `${i + 1}`,
                                                    "Mã lịch hẹn": item.booking_code,
                                                    "Bệnh nhân": item.ho_ten,
                                                    "SDT": item.so_dien_thoai,
                                                    "Doanh thu": formatPrice(item.total_amount),
                                                    "Nhân viên": item.from && `${item.from.fullname} - ${item.from.code}`,
                                                    "Loại khách": item.cus_old ? "Khách cũ" : "Khách mới"
                                                }
                                            }),
                                            cols: [5, 20, 40, 20, 20, 40, 20]
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }} disabled={this.state.loading}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>
                                <Table
                                    id="statistic_revenue"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listData}
                                    columns={[
                                        {
                                            title: "Nguồn",
                                            responsive: ["md"],
                                            render: (item) => item.alias || item.source
                                        },
                                        {
                                            title: "Mã lịch hẹn",
                                            dataIndex: "booking_code"
                                        },
                                        // {
                                        //     title: "Dịch vụ",
                                        //     dataIndex: "dich_vu",
                                        //     responsive: ["md"],
                                        //     render: (item) => {
                                        //         return item && item.name
                                        //     }
                                        // },
                                        {
                                            title: "Bệnh nhân",
                                            dataIndex: "ho_ten",
                                            render: (value, record) => {
                                                return <>
                                                    <p className='mb-2'>{value}</p>
                                                    <p className='mb-0'>{record.so_dien_thoai}</p>
                                                </>
                                            }
                                        },
                                        {
                                            title: "Doanh thu",
                                            dataIndex: "total_amount",
                                            render: (item) => {
                                                return <NumberFormat value={item} displayType={'text'} thousandSeparator={true} />
                                            }
                                        },
                                        {
                                            title: "Nhân viên",
                                            dataIndex: "from",
                                            render: (value) => {
                                                return value ? `${value.fullname} - ${value.code}` : ""
                                            },
                                            responsive: ["md"]
                                        },
                                        {
                                            title: "Loại khách",
                                            dataIndex: "cus_old",
                                            render: (item) => {
                                                return item ? "Khách cũ" : "Khách mới"
                                            },
                                            responsive: ["md"]
                                        },
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total = 0;
                                        let total_amount_1 = 0;

                                        pageData.forEach(({ total_amount }) => {
                                            total += 1;
                                            total_amount_1 += total_amount || 0;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                                                {/* <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell> */}
                                                <Table.Summary.Cell className="d-none d-md-table-cell text-danger"><b>Tổng tiền</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b><NumberFormat value={total_amount_1} displayType={'text'} thousandSeparator={true} /></b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_interactive">
                            <div className="table-responsive">
                                <div className="text-right">
                                    {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_interactive"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_interactive",
                                            dom: document.querySelector('#statistic_interactive')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_interactive"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataInteractiveAggregation}
                                    columns={[
                                        {
                                            title: "Nguồn",
                                            dataIndex: "source"
                                        },
                                        {
                                            title: "Nhân viên",
                                            dataIndex: "sale",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item ? item.fullname : ""
                                            }
                                        },
                                        {
                                            title: "Khách mới",
                                            dataIndex: "cus_new"
                                        },
                                        {
                                            title: "Khách cũ",
                                            dataIndex: "cus_old"
                                        }
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total_cus_new = 0;
                                        let total_cus_old = 0;

                                        pageData.forEach(({ cus_new, cus_old }) => {
                                            total_cus_new += cus_new;
                                            total_cus_old += cus_old;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_cus_new}</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_cus_old}</b></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_interactive_detail">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_interactive_detail"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_interactive_detail",
                                            dom: document.querySelector('#statistic_interactive_detail')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_interactive_detail"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataInteractive}
                                    columns={[
                                        {
                                            title: "Nguồn",
                                            dataIndex: "source"
                                        },
                                        {
                                            title: "Nhân viên",
                                            responsive: ["md"],
                                            dataIndex: "sale",
                                            render: (item) => {
                                                return item ? item.fullname : ""
                                            }
                                        },
                                        {
                                            title: "Khách hàng",
                                            responsive: ["md"],
                                            dataIndex: "user_id"
                                        },
                                        {
                                            title: "Khách mới",
                                            dataIndex: "is_cus_new",
                                            render: (item) => {
                                                return item ? <i className="far fa-check"></i> : null
                                            }
                                        },
                                        {
                                            title: "Khách cũ",
                                            dataIndex: "is_cus_new",
                                            render: (item) => {
                                                return !item ? <i className="far fa-check"></i> : null
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total_cus_new = 0;
                                        let total_cus_old = 0;

                                        pageData.forEach(({ is_cus_new }) => {
                                            total_cus_new += is_cus_new ? 1 : 0;
                                            total_cus_old += is_cus_new ? 0 : 1;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_cus_new}</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_cus_old}</b></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />

                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_rating">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_rating"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_rating",
                                            dom: document.querySelector('#statistic_rating')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>
                                <Table
                                    id="statistic_rating"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataRating}
                                    columns={[
                                        {
                                            title: "Chi nhánh (alias)",
                                            dataIndex: "alias",
                                            responsive: ["md"]
                                        },
                                        {
                                            title: "Khách hàng",
                                            dataIndex: "user_id"
                                        },
                                        {
                                            title: "Hài lòng",
                                            dataIndex: "result_rating",
                                            render: (item) => {
                                                return item === 1 ? <i className="far fa-check"></i> : null
                                            }
                                        },
                                        {
                                            title: "Không Hài lòng",
                                            dataIndex: "result_rating",
                                            render: (item) => {
                                                return item === 2 ? <i className="far fa-check"></i> : null
                                            }
                                        },
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total_1 = 0;
                                        let total_2 = 0;

                                        pageData.forEach(({ result_rating }) => {
                                            total_1 += result_rating === 1 ? 1 : 0;
                                            total_2 += result_rating === 2 ? 1 : 0;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_1}</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total_2}</b></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_tag">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_tag"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_tag",
                                            dom: document.querySelector('#statistic_tag')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_rating"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataContactTag}
                                    columns={[
                                        {
                                            title: "Nhóm nhãn",
                                            dataIndex: "tag",
                                            render: (item) => {
                                                return item[0]
                                            }
                                        },
                                        {
                                            title: "Nhãn",
                                            dataIndex: "tag",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item[1]
                                            }
                                        },
                                        {
                                            title: "Khách hàng",
                                            render: (item) => {
                                                return <a href={item.link} target="_blank">{item.user_id}</a>
                                            }
                                        },
                                        {
                                            title: "Ngày tương tác cuối",
                                            dataIndex: "last_time",
                                            render: (item) => {
                                                return moment(item).format("HH:mm DD-MM-YYYY")
                                            }
                                        },
                                        {
                                            title: "Nhân viên",
                                            dataIndex: "sale",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item ? item.fullname : ""
                                            }
                                        },
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total = 0;

                                        pageData.forEach(({ result_rating }) => {
                                            total += 1;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_khambenh">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_khambenh"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_khambenh",
                                            dom: document.querySelector('#statistic_khambenh')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_khambenh"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataKhamBenh}
                                    columns={[
                                        {
                                            title: "Mã lịch hẹn / his",
                                            responsive: ["md"],
                                            dataIndex: "booking_code"
                                        },
                                        {
                                            title: "Họ và tên",
                                            dataIndex: "ho_ten"
                                        },
                                        {
                                            title: "Nhân viên / CTV",
                                            dataIndex: "from",
                                            render: (item) => {
                                                return item && `${item.code} - ${item.fullname}`
                                            }
                                        },
                                        {
                                            title: "Năm sinh",
                                            dataIndex: "ngay_sinh",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item
                                            }
                                        },
                                        {
                                            title: "Tỉnh/ TP",
                                            dataIndex: "tinh",
                                            render: (item, record, index) => {
                                                let address = [];
                                                if(record.dia_chi) address.push(record.dia_chi);
                                                if(record.xa) address.push(record.xa.name);
                                                if(record.huyen) address.push(record.huyen.name);
                                                if(record.tinh) address.push(record.tinh.name); 

                                                return <React.Fragment>
                                                    {address.join(", ")}
                                                </React.Fragment>
                                            }
                                        },
                                        {
                                            title: "Bác sỹ",
                                            dataIndex: "bac_sy",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item && item.name
                                            }
                                        },
                                        {
                                            title: "Dịch vụ",
                                            dataIndex: "dich_vu",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item && item.name
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                    summary={pageData => {
                                        let total = 0;

                                        pageData.forEach(({ result_rating }) => {
                                            total += 1;
                                        });

                                        return pageData.length > 0 && (
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="text-danger"><b>{total}</b></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        );
                                    }}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_dichte">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_dichte"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_dichte",
                                            dom: document.querySelector('#statistic_dichte')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_dichte"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataDichTe}
                                    columns={[
                                        {
                                            title: "Mã lịch hẹn",
                                            responsive: ["md"],
                                            dataIndex: "booking_code"
                                        },
                                        {
                                            title: "Họ và tên",
                                            dataIndex: "ho_ten"
                                        },
                                        {
                                            title: "SDT",
                                            dataIndex: "so_dien_thoai",
                                        },
                                        {
                                            title: "Các triệu chứng",
                                            render: (item) => {
                                                let trieuchung = list_trieuchung.filter((item__) => item[item__.name]);
                                                return trieuchung.map((item) => item.title).join("; ");
                                            }
                                        },
                                        {
                                            title: "Tiêm vaccine",
                                            responsive: ["md"],
                                            dataIndex: "tiem_vaccince_thoigian",
                                            render: (item) => {
                                                return item ? item.join("; ") : null
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_care_booking">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_care_booking"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_care_booking",
                                            dom: document.querySelector('#statistic_care_booking')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_care_booking"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataCareBooking}
                                    columns={[
                                        {
                                            title: "STT",
                                            responsive: ["md"],
                                            render: (item, value, index) => index + 1
                                        },
                                        {
                                            title: "SDT",
                                            responsive: ["md"],
                                            dataIndex: "phone"
                                        },
                                        {
                                            title: "Số nhân viên",
                                            responsive: ["md"],
                                            dataIndex: "extnum"
                                        },
                                        {
                                            title: "Mã lịch hẹn",
                                            dataIndex: "booking",
                                            render: (item) => item.booking_code
                                        },
                                        {
                                            title: "Khách hàng",
                                            dataIndex: "booking",
                                            responsive: ["md"],
                                            render: (item) => item.fullname
                                        },
                                        {
                                            title: "Cuộc gọi",
                                            dataIndex: "recordfile",
                                            render: (item) => {
                                                return <audio controls="controls" name="media" className="audio" src={item}><source src={item} type="audio/*" /></audio>
                                            }
                                        },
                                        {
                                            title: "Thời gian",
                                            dataIndex: "timestamp",
                                            render: (item) => {
                                                return moment(item).format("HH:mm DD-MM-YYYY")
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_feedback">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_feedback"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_feedback",
                                            dom: document.querySelector('#statistic_feedback')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_feedback"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.listDataFeedback}
                                    columns={[
                                        {
                                            title: "STT",
                                            responsive: ["md"],
                                            render: (item, value, index) => index + 1
                                        },
                                        {
                                            title: "SDT",
                                            dataIndex: "phone"
                                        },
                                        {
                                            title: "Họ tên",
                                            dataIndex: "fullname"
                                        },
                                        {
                                            title: "Kết quả",
                                            dataIndex: "contentment",
                                            render: (item) => {
                                                return item == 1 ? "Hài lòng" : item == 2 ? "Không hài lòng" : ""
                                            }
                                        },
                                        {
                                            title: "Chi tiết",
                                            render: (item) => {
                                                return <div>
                                                    {
                                                        item.rating_text.map((item) => {
                                                            return <p>{item}</p>
                                                        })
                                                    }
                                                    {
                                                        item.feedback_other && <p>{item.feedback_other}</p>
                                                    }
                                                </div>
                                            }
                                        }
                                    ]}
                                    pagination={false}
                                />
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="statistic_autosms">
                            <div className="table-responsive">
                                <div className="text-right">
                                {/* <Button type="primary"
                                        style={{ borderRadius: "5px" }} 
                                        disabled={this.state.loading}
                                        className="mr-2"
                                        onClick={() => {
                                            this.exportToCSV({
                                                statistic_type: "statistic_autosms"
                                            })
                                        }}
                                    >Export Link File</Button> */}
                                    <Button type="primary" onClick={() => {
                                        this.exportToCSV({
                                            statistic_type: "statistic_autosms",
                                            dom: document.querySelector('#statistic_autosms')
                                        })
                                    }} className="mb-2" style={{ borderRadius: "5px" }}>
                                        Export file <i className="far fa-download ml-2"></i>
                                    </Button>
                                </div>

                                <Table
                                    id="statistic_autosms"
                                    bordered
                                    loading={this.state.loading}
                                    dataSource={this.state.dataSms.data}
                                    columns={[
                                        {
                                            title: "STT",
                                            responsive: ["md"],
                                            render: (item, value, index) => {
                                                return (this.pagination.page * this.pagination.limit - this.pagination.limit) + index + 1
                                            }
                                        },
                                        {
                                            title: "Loại chiến dịch",
                                            dataIndex: "reply",
                                            render: (item) => {
                                                item.campaign_code = item.campaign_code || "";
                                                return item.campaign_code.indexOf("SMSMKT") > -1 ? "SMS Marketing" :
                                                    item.campaign_code.indexOf("SMSREMAKT") > -1 ? "SMS Remarketing" :
                                                        item.campaign_code.indexOf("SMSTARGET") > -1 ? "SMS Retargeting" :
                                                            item.campaign_code.indexOf("SMSSTAFF") > -1 ? "SMS StaffSMS" : ""
                                            }
                                        },
                                        {
                                            title: "SDT",
                                            dataIndex: "user_id"
                                        },
                                        {
                                            title: "Nội dung",
                                            dataIndex: "reply",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return <Input.TextArea value={item.content} />
                                            }
                                        },
                                        {
                                            title: "Trạng thái",
                                            dataIndex: "status",
                                            responsive: ["md"],
                                            render: (item) => {
                                                return item == -1 ? "Tạm dừng" : item == 0 ? "Đang xử lý" : item == 2 ? "Thành công" : item == 3 ? "Thất bại" : ""
                                            }
                                        },
                                        {
                                            title: "Ngày gửi",
                                            dataIndex: "time_success",
                                            render: (item) => {
                                                return item && moment(item).format("HH:mm DD-MM-YYYY")
                                            }
                                        }
                                    ]}
                                    pagination={{
                                        total: this.state.dataSms.total,
                                        pageSize: this.pagination.limit,
                                        onChange: (current, size) => {
                                            this.pagination = {
                                                page: current,
                                                limit: size
                                            }
                                            this.getListData();
                                        },
                                        current: this.pagination.page,
                                        showSizeChanger: false
                                    }}
                                />
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
