import React, { Component } from 'react';
import config from '../lib/config';
import moment from 'moment';
import {Tooltip, Switch} from 'antd';
import {Link} from 'react-router-dom';

export default class ContactWidget extends Component{
  constructor(props){
    super(props);

    this.state = {
        
    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){

  }
  componentDidUpdate(prevProps, prevState){
    
  }
  
  render(){
    if(!this.props.Contact) return null
      // console.log(this.props);
    let time_contact = moment(this.props.Contact.last_time);
    let last_time_contact = '';
    
    if(moment(this.props.Contact.last_time).startOf('day').unix() == moment(new Date()).startOf('day').unix()){
      last_time_contact = `${time_contact.fromNow()}`;
    } else{
      if(moment(this.props.Contact.last_time).startOf('year').unix() == moment(new Date()).startOf('year').unix()){
        last_time_contact = `${time_contact.fromNow('Do MMMM')}`;
      } else{
        last_time_contact = `${time_contact.format('MMMM YYYY')}`;
      }
    }
    let channel = this.props.Contact.channel;
    let avatar = this.props.Contact.picture;
    // if(channel.acc_type == 5) {
    //   // avatar = avatar == '/user.jpg' ? `https://graph.facebook.com/${this.props.Contact.user_id}/picture?height=80&width=80&access_token=${channel.token}` : avatar;
    // }
    let source_icon = <span>KXD</span>;
    if(channel && config.type_account_social[channel.acc_type]){
      source_icon = config.type_account_social[channel.acc_type]['icon'] ? <img src={config.type_account_social[channel.acc_type]['icon'].default} /> :
      <span>{config.type_account_social[channel.acc_type]['name']}</span>
    }

    if(this.props.Contact.type == "callcenter"){
      source_icon = <span><i className="fa fa-phone" style={{ padding: "0 2px"}}></i></span>
    } else if(this.props.Contact.type == "www"){
      source_icon = <span>web</span>
    }

    return(
        <li className={this.props.contactInfoSelected && this.props.contactInfoSelected._id == this.props.Contact._id ? 'selected' : ''} >
            <Link to={`/chat/${this.props.Contact.contact_id}`}>
              <div className="avatar-box">
                  <img className="avatar" alt="avatar" 
                        src={avatar} 
                        onLoad={(e)=>{
                          e.target.className = "avatar-ready avatar";
                        }}
                        onError={(e) => {
                            e.target.src = "/user.jpg"
                        }}
                    />
                  {/* {
                      this.User.online ? <span className="online"></span> : ''
                  } */}
                  <Tooltip placement="bottom" title={channel ? channel.username : ""} 
                    mouseEnterDelay={this.props.mouseEnterDelayTooltip} 
                    mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}
                  >
                    <div className="info-source">
                      <span className={this.props.Contact.type == "direct" ? "icon-type icon-direct" : "icon-type icon-cmt"}>
                        {/* <i className="material-icons">{this.props.Contact.type == "direct" ? "near_me" : "mode_comment"}</i> */}
                        {
                          this.props.Contact.type  == "direct" ? 
                          // <i className="fa fa-paper-plane" style={{color: "#2196f3", fontSize: "15px"}}></i>
                          <svg fill="#2196f3" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 334.5 334.5" width="15" height="15">
                            <path d="M332.797,13.699c-1.489-1.306-3.608-1.609-5.404-0.776L2.893,163.695c-1.747,0.812-2.872,2.555-2.893,4.481  s1.067,3.693,2.797,4.542l91.833,45.068c1.684,0.827,3.692,0.64,5.196-0.484l89.287-66.734l-70.094,72.1  c-1,1.029-1.51,2.438-1.4,3.868l6.979,90.889c0.155,2.014,1.505,3.736,3.424,4.367c0.513,0.168,1.04,0.25,1.561,0.25  c1.429,0,2.819-0.613,3.786-1.733l48.742-56.482l60.255,28.79c1.308,0.625,2.822,0.651,4.151,0.073  c1.329-0.579,2.341-1.705,2.775-3.087L334.27,18.956C334.864,17.066,334.285,15.005,332.797,13.699z"/>
                          </svg>
                          : this.props.Contact.type == "comment" ?
                          <i className="fa fa-comment-alt" style={{color: "#2196f3", fontSize: "15px"}}></i> : null
                          // this.props.Contact.type == "callcenter" ?
                          // <i className="fa fa-phone" style={{color: "#2196f3", fontSize: "15px"}}></i> : null
                        }
                      </span>
                      <div className="source-icon">
                        {source_icon}
                      </div>
                    </div>
                  </Tooltip>
              </div>
              <div className={!this.props.Contact.read_state == 1 ? "read-contact text-box" : "text-box"}>
                  <div className="d-flex justify-content-between">
                      <span className="contact-title">{this.props.Contact.thread_title || 'Không xác định'} {
                        this.props.Contact.read_state == 1 ?
                        <span className="unread">1</span>
                        : null
                      }</span>
                      <span className="channel-alias">{this.props.Contact.channel ? this.props.Contact.channel.alias : ""}</span>
                  </div>
                  <div className="sidepanel-item-note">
                      <div className="note-content">{this.props.Contact.snippet}</div>
                      <div className="note-time">{last_time_contact}</div>
                  </div>
                  {
                    this.props.Contact.sale && this.props.Contact.sale.status == 0 ? 
                    <div className="contact-confirm"><span className="icon">new</span></div> : this.props.Contact.tags && this.props.Contact.tags.length > 0 ?
                    <div className="list-tags">
                      {this.props.Contact.tags.map((item) => {
                        return <Tooltip key={item.tag_id} placement="top" title={item.tag_name} 
                        mouseEnterDelay={this.props.mouseEnterDelayTooltip} 
                        mouseLeaveDelay={this.props.mouseLeaveDelayTooltip}
                      >
                        <span
                          className="item-tag-2" 
                          style={{background: item.tag_color}}
                        ></span>
                      </Tooltip>
                      })}
                    </div> : <div className="list-tags" style={{marginBottom: "5px"}}></div>
                  }
              </div>
            </Link>
        </li>
    )
  }
}
