import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";

import { formatPrice } from "../../lib/helpers";
import { useMemo } from "react";
import PreviewFile from "./preview-file";

const TraCuu = (props) => {
    const [ data, setData ] = useState(null);
    const [ visiblePreview, setvisiblePreview ] = useState({
        visible: false,
        file: null
    });

    useEffect(() => {
        console.log("CHANGE data", props.data);
        if(props.data){
            setData({
                company: props.data.company,
                list_data: props.data.data,
                item: props.data.data[0],
                booking_index: 0,
                item_index: 0
            })
        } else{
            setData(null);
        }
    }, [props.data])

    const OptionTable = {
        showHeader: false,
        size: "small",
        bordered: false,
        pagination: false
    }

    const expandedRowRender = (record, index, indent, expanded) => {

        return <Table
            columns={[
                // {
                //     title: "STT",
                //     width: 30,
                //     render: (value, record, index) => `${index + 1}.`
                // },
                {
                    title: "Tên dv",
                    render: (value, record, index) => {
                        return <React.Fragment>
                            {index + 1}. {record.ten} ({record.ma_dv}) = {record.so_luong} {record.don_vi.toLowerCase()}
                        </React.Fragment>
                    }
                }
            ]}
            dataSource={record.dich_vu}
            pagination={false}
            showHeader={false}

        />;
    };

    const groupBy = (arr, group_keys, key) => {
        let result = arr.reduce((a, b) => {
            let id_keys = group_keys.map((key) => b[key]).join("-");

            a[id_keys] = a[id_keys] || [];

            a[id_keys].push(b);

            return a;
        }, {});

        let data = Object.keys(result).map((item) => {
            let result2 = {};
            group_keys.map((group_key) => result2[group_key] = result[item][0][group_key]);
            return {
                ...result2,
                [key]: result[item]
            }
        })

        return data
    }

    const changeBooking = (index) => {
        setData(JSON.parse(JSON.stringify({
            ...data,
            booking_index: index,
        })))
    }

    const changeUser = (index) => {
        try{
            if (index >= data.list_data.length) index = 0;

            setData(JSON.parse(JSON.stringify({
                ...data,
                item_index: index,
                item: data.list_data[index],
                booking_index: 0
            })))
        } catch(err){

        }
    }

    let data_1 = [], data_2 = [], data_3 = [], data_4 = [], data_5 = [], data_6 = [], data_7 = [], data_8 = [];

    if (data) {
        data_1 = groupBy([].concat.apply([], data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Dichvu_chidinh").map((item) => {
            return item.dich_vu;
        })), ["bac_sy", "phong"], "dich_vu")
        data_2 = data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Dichvu_thuphi" || item.loai == "Dichvu_tamung");

        if (data.item.items[data.booking_index].extra_data) {
            if (data.item.items[data.booking_index].extra_data.kq_xet_nghiem_lamsang.length) data_3.push({
                name: "DV cận lâm sàng",
                value: <React.Fragment>
                    <div>
                        {data.item.items[data.booking_index].extra_data.kq_xet_nghiem_lamsang.map((item, i) => {
                            item = `${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/${item}`;
                            return <a href={"#"}
                                onClick={() => setvisiblePreview({ visible: true, file: item})}
                            >File {i + 1}</a>
                        })}
                    </div>
                </React.Fragment>
            })
            if (data.item.items[data.booking_index].extra_data.kq_xet_nghiem.length) data_3.push({
                name: "DV xét nghiệm",
                value: <React.Fragment>
                    <div>
                        {data.item.items[data.booking_index].extra_data.kq_xet_nghiem.map((item, i) => {
                            item = `${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/${item}`;
                            return <a href={"#"}
                                onClick={() => setvisiblePreview({ visible: true, file: item})}
                            >File {i + 1}</a>
                        })}
                    </div>
                </React.Fragment>
            })
        }

        data_4 = groupBy([].concat.apply([], data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Thuoc_kedon").map((item) => {
            return item.dich_vu;
        })), ["bac_sy", "phong"], "dich_vu");
        data_5 = data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Thuoc_thuphi" || item.loai == "Thuoc_tamung");

        data_6 = data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Kinh_thuphi" || item.loai == "Kinh_tamung");

        data_7 = data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Dichvu_Chihuy");
        data_8 = data.item.items[data.booking_index].thu_phis.filter((item) => item.loai == "Thuoc_Chihuy");
    }

    const data_9 = useMemo(() => {
        return !data ? [] : data.item.items[data.booking_index].thu_phis.filter((item) => 
            item.loai.indexOf("Goimo") > -1 |
            item.loai.indexOf("Noitru") > -1 |
            item.loai.indexOf("Datcoc") > -1
        );
    }, [data])

    const data_10 = useMemo(() => {
        let result = [];
        if(data && data.item.items[data.booking_index].extra_data){
            if(data.item.items[data.booking_index].extra_data.kq_xet_nghiem_lamsang){
                result = result.concat(data.item.items[data.booking_index].extra_data.kq_xet_nghiem_lamsang)
            }
            if(data.item.items[data.booking_index].extra_data.kq_xet_nghiem){
                result = result.concat(data.item.items[data.booking_index].extra_data.kq_xet_nghiem)
            }
        }

        return result;
    }, [data])

    return <React.Fragment>
        <PreviewFile {...visiblePreview}
            hide={() => setvisiblePreview({ visible: false })}
        />
        {
            data && <React.Fragment>
                <div className="col-12 search-medical-content" >
                    {
                        props.type != 2 && <React.Fragment>
                            <div className="mt-4 mb-2">
                                <h5>THÔNG TIN HÀNH CHÍNH</h5>
                            </div>
                            <div>
                                <Table
                                    dataSource={[
                                        {
                                            name: "Họ và tên",
                                            value: data.item._id.ho_ten
                                        },
                                        {
                                            name: "SĐT",
                                            value: <React.Fragment>
                                                <span className="mr-2">{data.item._id.so_dien_thoai}</span> {
                                                    data.list_data.length > 1 && <div className="text-right d-inline-block">
                                                        <a href="javascript:void(0)" onClick={() => {
                                                            changeUser(data.item_index + 1)
                                                        }}>Xem bệnh nhân khác cùng SĐT</a>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        },
                                        {
                                            name: "Ngày sinh",
                                            value: data.item._id.ngay_sinh
                                        },
                                        {
                                            name: "Địa chỉ",
                                            value: data.item._id.dia_chi && data.item._id.dia_chi.replace(/^,/gi, '')
                                        }
                                    ]}
                                    columns={[
                                        {
                                            dataIndex: "name",
                                            width: 120
                                        },
                                        {
                                            dataIndex: "value"
                                        }
                                    ]}
                                    showHeader={false}
                                    size="small"
                                    bordered={false}
                                    pagination={false}
                                />
                            </div>
                        </React.Fragment>
                    }
                    {
                        props.type != 2 && <React.Fragment>
                            <div className="mt-4 mb-2">
                                <h5>THÔNG TIN BỆNH NHÂN</h5>
                            </div>
                            <div>
                                <Table
                                    dataSource={[
                                        {
                                            name: "Mã bệnh nhân",
                                            value: data.item._id.ma_benh_nhan
                                        },
                                        {
                                            name: "Cơ sở KCB",
                                            value: data.company.company_name
                                        },
                                        {
                                            name: "Địa chỉ",
                                            value: data.company.address
                                        }
                                    ]}
                                    columns={[
                                        {
                                            dataIndex: "name",
                                            width: 120
                                        },
                                        {
                                            dataIndex: "value"
                                        }
                                    ]}
                                    showHeader={false}
                                    size="small"
                                    bordered={false}
                                    pagination={false}
                                />
                            </div>
                        </React.Fragment>
                    }
                    <div className="mt-4 mb-2">
                        <h5>LỊCH SỬ KHÁM CHỮA BỆNH</h5>
                    </div>
                    <div>
                        <React.Fragment>
                            <div className="d-flex align-items-center mb-3 justify-content-start mt-3" style={{ paddingLeft: "8px" }}>
                                <div><label className="">Mã khám bệnh:</label> </div>
                                <Select className="ml-3" value={data.item.items[data.booking_index].booking_code}
                                    onChange={(e) => changeBooking(e)}
                                >
                                    {
                                        data.item.items.map((item, i) => {
                                            return <Select.Option value={i}>{item.booking_code}</Select.Option>
                                        })
                                    }
                                </Select>
                            </div>
                        </React.Fragment>
                        <Table
                            dataSource={[
                                {
                                    name: "Ngày khám",
                                    value: moment(data.item.items[data.booking_index].created_time).format("HH:mm DD/MM/YYYY")
                                },
                                {
                                    name: "Kết luận khám",
                                    value: data.item.items[data.booking_index].ket_qua
                                },
                                {
                                    name: "Tổng chi phí",
                                    value: <strong className="text-danger">{formatPrice(data.item.items[data.booking_index].total_amount)} VNĐ</strong>
                                }
                            ]}
                            columns={[
                                {
                                    dataIndex: "name",
                                    width: 120
                                },
                                {
                                    dataIndex: "value",
                                }
                            ]}
                            {...OptionTable}
                        />
                        {
                            data_1.length > 0 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Dịch vụ chỉ định</label>
                                    <Table
                                        dataSource={data_1}
                                        columns={[
                                            {
                                                title: "Bác sỹ",
                                                render: (value, record) => {
                                                    return <React.Fragment>
                                                        <strong>{record.bac_sy} ({record.phong})</strong>
                                                    </React.Fragment>
                                                }
                                            },
                                            // {
                                            //     title: "Phòng khám",
                                            //     render: (value, record) => {
                                            //         return <React.Fragment>
                                            //             {record.phong}
                                            //         </React.Fragment>
                                            //     }
                                            // }
                                        ]}
                                        {...OptionTable}
                                        expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        {
                            data_2.length > 0 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Dịch vụ thu phí</label>
                                    <Table
                                        dataSource={data_2}
                                        columns={[
                                            {
                                                title: "Số biên lai",
                                                className: "pl-0",
                                                render: (value, record) => {
                                                    return <React.Fragment>
                                                        <strong>Biên lai thu phí số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                    </React.Fragment>
                                                }
                                            }
                                        ]}
                                        {...OptionTable}
                                        expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        {
                            data_7.length > 0 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Dịch vụ hủy thu phí</label>
                                    <Table
                                        dataSource={data_7}
                                        columns={[
                                            {
                                                title: "Số biên lai",
                                                className: "pl-0",
                                                render: (value, record) => {
                                                    return <React.Fragment>
                                                        <strong>Biên lai số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                    </React.Fragment>
                                                }
                                            }
                                        ]}
                                        {...OptionTable}
                                        expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        {
                            data_9.length > 0 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Gói phẫu thuật: </label>
                                    <Table
                                        dataSource={data_9}
                                        columns={[
                                            {
                                                title: "Số biên lai",
                                                className: "pl-0",
                                                render: (value, record) => {
                                                    return <React.Fragment>
                                                        <strong>Biên lai số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                    </React.Fragment>
                                                }
                                            }
                                        ]}
                                        {...OptionTable}
                                        expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        {
                            (data_4.length > 0 || data_5.length > 0 || data_8.length > 0) ? <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Thuốc</label>
                                    {
                                        data_4.length > 0 && <React.Fragment>
                                            <label className="d-block label-title-2">Các thuốc được kê đơn: </label>
                                            <Table
                                                dataSource={data_4}
                                                columns={[
                                                    {
                                                        title: "Bác sỹ",
                                                        render: (value, record) => {
                                                            return <React.Fragment>
                                                                <strong>{record.bac_sy} ({record.phong})</strong>
                                                            </React.Fragment>
                                                        }
                                                    },
                                                    // {
                                                    //     title: "Phòng khám",
                                                    //     render: (value, record) => {
                                                    //         return <React.Fragment>
                                                    //             {record.phong}
                                                    //         </React.Fragment>
                                                    //     }
                                                    // }
                                                ]}
                                                {...OptionTable}
                                                expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                            />
                                        </React.Fragment>
                                    }
                                    {
                                        data_5.length > 0 && <React.Fragment>
                                            <label className="d-block mt-2 label-title-2">Các thuốc đã mua: </label>
                                            <Table
                                                dataSource={data_5}
                                                columns={[
                                                    {
                                                        title: "Số biên lai",
                                                        className: "pl-0",
                                                        render: (value, record) => {
                                                            return <React.Fragment>
                                                                <strong>Biên lai số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                            </React.Fragment>
                                                        }
                                                    }
                                                ]}
                                                {...OptionTable}
                                                expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                            />
                                        </React.Fragment>
                                    }
                                    {
                                        data_8.length > 0 && <React.Fragment>
                                            <label className="d-block mt-2 label-title-2">Các thuốc đã hủy: </label>
                                            <Table
                                                dataSource={data_8}
                                                columns={[
                                                    {
                                                        title: "Số biên lai",
                                                        className: "pl-0",
                                                        render: (value, record) => {
                                                            return <React.Fragment>
                                                                <strong>Biên lai số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                            </React.Fragment>
                                                        }
                                                    }
                                                ]}
                                                {...OptionTable}
                                                expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                            />
                                        </React.Fragment>
                                    }
                                </div>
                            </React.Fragment> : null
                        }

                        {
                            data_6.length > 0 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Kính: </label>
                                    <Table
                                        dataSource={data_6}
                                        columns={[
                                            {
                                                title: "Số biên lai",
                                                className: "pl-0",
                                                render: (value, record) => {
                                                    return <React.Fragment>
                                                        <strong>Biên lai số {record.so_hoa_don} = <span className="text-danger">{formatPrice(record.tong)}</span></strong>
                                                    </React.Fragment>
                                                }
                                            }
                                        ]}
                                        {...OptionTable}
                                        expandable={{ expandedRowRender, defaultExpandAllRows: true, showExpandColumn: false }}
                                    />
                                </div>
                            </React.Fragment>
                        }

                        {/* {
                            data_3.length > 0 && props.type != 2 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Kết quả khám bệnh</label>
                                    <Table
                                        dataSource={data_3}
                                        columns={[
                                            {
                                                dataIndex: "name",
                                                width: 160
                                            },
                                            {
                                                dataIndex: "value"
                                            }
                                        ]}
                                        {...OptionTable}
                                    />
                                </div>
                            </React.Fragment>
                        } */}

                        {
                            data_10.length > 0 && props.type != 2 && <React.Fragment>
                                <div className="mt-3">
                                    <label className="label-title">Kết quả khám bệnh</label>
                                    {
                                        data_10.map((item) => {
                                            return <iframe 
                                                src={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/${item}`}
                                                style={{
                                                    width: "100%",
                                                    minHeight: "800px"
                                                }}
                                                className="mt-2"
                                            ></iframe>
                                        })
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
                
            </React.Fragment>
        }
    </React.Fragment>
};

export default TraCuu;
