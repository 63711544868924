import React, { Component, useCallback } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Result } from 'antd';

import { fetchData } from '../../lib/apis';

import '../../css/dashboard.css';
import '../../css/font-awesome/css/all.min.css';

import { LoadingComponent, Header, WidgetFotterApp, WidgetSwipe } from '../../widgets';

import ItemMenu from './component/item-menu';
import StatitiscalChannel from './channel/statitiscal';
import Proxy from './proxy';
import Order from './order';
import Role from './role';
import Staff from './staff';
import CreateCompany from './company/create';
import JoinCompany from './company/join';
import Channel from './channel';
import Broadcast from './broadcast';
import StaffWork from './work';
import SettingCompany from './company/setting';
import LogBroadcastView from './broadcast/log';

import SystemsUserView from './users';
import MeView from './me';
import BookingView from './booking';
import AutoReplyView from './autoreply';
import CompanyListView from './company/list';
import ProductView from './product';
import ProductCateView from './product/cate';
import StatisticView from './statistic';
import AutoPostView from './autopost';
import AutoPostLogView from './autopost/log';
import RatingView from './rating';

import HistoryStaffChangeView from './staff/history';
import OtherView from './other';
import ConvertUidView from './convertuid';

import AutoCallView from './callcenter/autocall';

import AutoTargetView from './autotarget';

import SmsBrandnameView from './brandname';

import KhamSangLocView from './dichvuyte/kham-sang-loc';
import ThuPhiTestCovidView from './dichvuyte/testcovid/thu-phi';
import LayMauCovidView from './dichvuyte/testcovid/lay-mau';
import KqTestCovidView from './dichvuyte/testcovid/ket-qua';

import ReportView from './statistic/index2';
import RegisterEmailView from './resgiter-email';
import AutoSmsView from './brandname/autosms';
import LogAutoSmsView from './brandname/log_autosms';

import FeedbackSettingView from './feedback/setting';
import SmsOtpSettingView from './brandname/otp';
import NoteSupportView from './note-support';

import SearchMedicalView from './search-medical';

import CareHisView from './care-his';

import PCNView from './pcn';
import KBYTFaceView from './khaibaoyte';

import cx from "classnames";
import FaceDataView from './face-data';
import FaceData2View from './face-data/index-2';
import FaceDataTestView from './face-data/test';

export default class MainAdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDataMenu: []
    };
  }
  componentDidMount = () => {
    // this.getMenu();
  }
  getMenu = async () => {
    try {
      let response = await fetchData({
        url: 'api/v1/menu/list',
      });

      this.setState({
        listDataMenu: response.data
      }, () => {
        setTimeout(() => {
          document.querySelectorAll('.sidebar-menu li').forEach((item) => {
            item.addEventListener("click", function () {
              document.querySelector('.sidebar').classList.remove("show");
            })
          })
        }, 200)
      })

    } catch (err) {
      this.props.setCookie('c_t', '', 0);
      this.props.message({ content: 'Xin lỗi hệ thống tạm thời bị gián đoạn vui lòng thử lại sau!', key: 'message', type: 'warning', duration: 180 });
    }
  }
  handleViewError = (code) => {
    let status = 500;
    let title = 500;
    let des = 'Sorry, the server is wrong.';
    if (code == 401 || code == 403) {
      des = 'Sorry, you are not authorized to access this page.';
      status = 403;
      title = code;
    }
    this.setState({
      viewError: <Result
        status={status}
        title={title}
        subTitle={des}
      />,
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.pathname != window.location.pathname) {
      if (this.state.viewError) this.setState({ viewError: null })
    }
  }
  render() {
    if (!this.props.listDataMenu) return <LoadingComponent />;
    let menuAdmin = [
      <li className="head-menu" key={'menu_admin_1'}>
        <div>Thiết lập hệ thống</div>
      </li>,
      <Link to="/admin/proxy" className={this.props.location.pathname == '/admin/proxy' ? 'active' : ''} key={'menu_proxy'}>
        <li>
          <i className="far fa-cog"></i>
          <span className="text">Proxy</span>
        </li>
      </Link>,
      <Link to="/admin/statitiscal/channel" className={this.props.location.pathname == '/admin/statitiscal/channel' ? 'active' : ''} key={'menu_admin_statitiscal_channel'}>
        <li>
          <i className="fal fa-chart-bar"></i>
          <span className="text">Thống kê kênh</span>
        </li>
      </Link>
    ];
    let list_menus = [];
    this.props.listDataMenu.forEach((menu, i) => {
      list_menus.push(<ItemMenu item={menu} key={"menu_" + menu.menu_id} active={this.props.location.pathname == menu.link} isAppNative={this.props.isAppNative} />);
      if (menu.menu_child) {
        menu.menu_child.forEach((item, i) => {
          list_menus.push(<ItemMenu companyId={this.props.companySelected.company_id} item={item} key={"menu_child_" + item.menu_id} active={this.props.location.pathname == item.link} isAppNative={this.props.isAppNative} />);
        })
      }
    })
    var props_m = {
      handleViewError: this.handleViewError,
      ...this.props
    }
    return (
      <div id="app-container">
        {/* <WidgetFotterApp visible={this.props.isAppNative} numMessage={this.props.totalNoti} /> */}
        {
          this.props.history.location.pathname != "/me" && <WidgetSwipe id="box-touch-right" event="swipeleft" action={() => {
            this.props.history.push("/chat");
          }} visible={this.props.deviceType == "iOS" && this.props.isAppNative} zIndex={100} />
        }
        <div id="dashboard">
          {/* <div className="top-bar-app" style={{ paddingTop: `${this.props.statusBarHeight}px` }}></div> */}
          <Header {...this.props} UserLogin={this.props.UserLogin} Company={this.props.companySelected} ListCompany={this.props.companys} />
          <div className="dashboard-root">
            <div className={cx("sidebar", this.props.isAppNative && (this.props.location.pathname == "/" || this.props.location.pathname == "/home") ? "show" : "")} style={{ top: `${this.props.statusBarHeight + 51}px` }}>
              <ul className="sidebar-menu">
                {list_menus}
                {/* {
                  this.props.UserLogin.level == 99 ?
                    menuAdmin : null
                } */}
                <li className="head-menu">
                  <div>Hệ thống</div>
                </li>
                {
                  this.props.UserLogin.is_root || this.props.UserLogin.is_root_it ?
                    <ItemMenu item={{
                      menu_name: 'Quản lý người dùng',
                      parent_id: 16,
                      link: '/admin/users',
                      alias: 'admin_users',
                      icon_fa: '',
                      icon: 'menu/icon/usergroups.png'
                    }} active={this.props.location.pathname == '/admin/users'} /> : null
                }
                {/* <Link to="/company/list" className={this.props.location.pathname == '/company/list' ? 'active' : ''} key={'company_list'}>
                  <li>
                    <i className="fal fa-store-alt"></i>
                    <span className="text">Chi nhánh tham gia</span>
                  </li>
                </Link> */}
                <ItemMenu item={{
                  menu_name: 'Tham gia chi nhánh',
                  parent_id: 16,
                  link: '/company/join',
                  alias: 'company_join',
                  icon_fa: '',
                  icon: 'menu/icon/companyjoin.png'
                }} active={this.props.location.pathname == '/company/join'} />

                <ItemMenu item={{
                  menu_name: 'Cá nhân',
                  parent_id: 16,
                  link: '/me',
                  alias: 'me',
                  icon_fa: '',
                  icon: 'menu/icon/user.png'
                }} active={this.props.location.pathname == '/me'} />

                <ItemMenu item={{
                  menu_name: 'Đăng xuất',
                  parent_id: 16,
                  link: '',
                  alias: '',
                  icon_fa: '',
                  icon: 'menu/icon/logout.png',
                  action: 'logout'
                }} {...this.props} />

              </ul>
            </div>
            <div className="dashboard-container">
              {
                this.state.viewError ? <div className="list-connect content-m view-error">
                  {this.state.viewError}
                </div> : <Switch>
                  <Route
                    exact
                    path='/admin/statitiscal/channel'
                    render={props => <StatitiscalChannel {...props_m} {...props} />}
                  />
                  <Route
                    exact
                    path='/admin/proxy'
                    render={props => <Proxy {...props_m} {...props} />}
                  />
                  <Route
                    exact
                    path='/order'
                    render={props => <Order {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/role/chat'
                    render={props => <Role {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/staff'
                    render={props => <Staff {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/company/create'
                    render={props => <CreateCompany {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/company/join'
                    render={props => <JoinCompany {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/fanpage'
                    render={props => <Channel {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/broadcast'
                    render={props => <Broadcast {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/broadcast/:id'
                    render={props => <LogBroadcastView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/staff/role'
                    render={props => <StaffWork {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/company/setting'
                    render={props => <SettingCompany {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/admin/users'
                    render={props => <SystemsUserView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/me'
                    render={props => <MeView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/booking'
                    render={props => <BookingView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/autoreply'
                    render={props => <AutoReplyView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/company/list'
                    render={props => <CompanyListView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/product'
                    render={props => <ProductView {...props_m} {...props} />}
                  />
                  <Route
                    exact
                    path='/product/cate'
                    render={props => <ProductCateView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/statistic/:type?'
                    render={props => <StatisticView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/thong-ke/:type'
                    render={props => <ReportView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/post/auto'
                    render={props => <AutoPostView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/post/auto/:id'
                    render={props => <AutoPostLogView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/rating'
                    render={props => <RatingView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/staff/history'
                    render={props => <HistoryStaffChangeView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/zalo_oa'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/callcenter'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/website'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/channel/company'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    path='/channel/smsbrandname'
                    render={props => <SmsBrandnameView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/comment'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/seeding'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/livestream'
                    render={props => <OtherView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/convertuid'
                    render={props => <ConvertUidView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/autocall'
                    render={props => <AutoCallView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/autotarget'
                    render={props => <AutoTargetView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/kham-sang-loc'
                    render={props => <KhamSangLocView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/test-nhanh/thu-phi'
                    render={props => <ThuPhiTestCovidView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/test-nhanh/lay-mau'
                    render={props => <LayMauCovidView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/test-nhanh/ket-qua'
                    render={props => <KqTestCovidView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/dangky-email'
                    render={props => <RegisterEmailView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/auto_sms'
                    render={props => <AutoSmsView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/tools/auto_sms/:id'
                    render={props => <LogAutoSmsView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/feedback/setting'
                    render={props => <FeedbackSettingView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/sms/otp'
                    render={props => <SmsOtpSettingView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/note_support'
                    render={props => <NoteSupportView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/search-medical'
                    render={props => <SearchMedicalView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/care-his'
                    render={props => <CareHisView {...props_m} {...props} />}
                  />
                  <Route
                    exact
                    path='/phong-chuc-nang'
                    render={props => <PCNView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/khai-bao-yte'
                    render={props => <KBYTFaceView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/face-data'
                    render={props => <FaceDataView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/face-data/test'
                    render={props => <FaceDataTestView {...props_m} {...props} />}
                  />

                  <Route
                    exact
                    path='/cam-giam-sat'
                    render={props => <FaceData2View {...props_m} {...props} />}
                  />

                  <Route
                    path="*"
                    render={props => <div className="empty-content">
                      <img src={require('../../img/background.png')} className="listdata-img" alt="img" />
                    </div>}
                  />
                </Switch>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
