import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Result, Button, Form, Input, InputNumber, Progress, Select } from 'antd';

import qs from 'qs';

export default class ConvertUidView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listDataCompany: []
        }
    }
    componentWillMount() {
        this.getListCompany();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    convertUid = async (values) => {
        try {
            this.setState({ loading: true, statusRun: { success: 0, error: 0, empty: 0} });
            this.isRunConvert = true;
            if (!window.extConnect.isConnected) throw ({ message: `Vui lòng kết nối tiện ích phiên bản >= ${window.extConnect.ver_ext_connect}` });
            let info_fb = null;
            let thread = values['thread'] || 1;
            let count_run = 0;
            let timeout = false;

            while (this.isRunConvert) {
                if(count_run == 0){
                    try {
                        if(timeout) await this.props.sleep(values['timeout'] * 1000 || 10000);
                        timeout = true;
                        
                        if (!info_fb || info_fb['expire_dtsg'] < Date.now() - (60 * 1000)) {
                            info_fb = await window.extConnect.request({ method: 'get_info_fb', data: {} });
                            info_fb = info_fb.data;
                        }

                        let response = await this.props.fetchData({
                            url: `api/v1/contact/convert2?company_ids=${values['company_ids'].join(',')}&limit=${thread}`,
                            method: 'get'
                        })
                        response = response.data;

                        count_run = response.length;
    
                        for (let i = 0; i < response.length; i++) {
                            let data = response[i];
                            new Promise(async (resolve, reject) => {
                                try {
                                    let convertsations = await window.extConnect.request({
                                        method: 'get_conversations', data: {
                                            before: data.last_time + 1000,
                                            page_id: data.user_owner_id,
                                            userInfo: info_fb
                                        }
                                    });
                                    let comm_source_id = data.thread_id.replace('t_', '');
                                    let conversation = convertsations.data.find((item) => item.page_comm_item.comm_source_id == comm_source_id);
                                    console.log('conversation', conversation);
                                    if (conversation) {
                                        await this.props.fetchData({
                                            url: 'api/v1/contact/convert',
                                            method: 'post',
                                            data: {
                                                user_id2: conversation.thread_key.other_user_id,
                                                is_convert: 1,
                                                page_id: data.user_owner_id,
                                                user_id: data.user_id
                                            }
                                        })
                                        return resolve(true);
                                    } else {
                                        return resolve(false);
                                    }
                                } catch (err) {
                                    reject(err);
                                }
                            }).then((result) => {
                                count_run -= 1;
                                if(result === true){
                                    this.state.statusRun.success += 1;
                                } else if(result === false){
                                    this.state.statusRun.empty += 1;
                                }
                                this.setState({ statusRun: this.state.statusRun })
                            }).catch((err) => {
                                count_run -= 1;
                                console.log(err);
                                this.state.statusRun.error += 1;
                                this.setState({ statusRun: this.state.statusRun })
                            })
                        }
                    } catch (err) {
    
                    }  
                } else{
                    await this.props.sleep(3000);
                }
            }
        } catch (err) {
            console.log(err);
            this.isRunConvert = false;
            this.setState({ loading: false })
            this.props.notification({
                title: "Convert uid",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }

    stopConvert = () => {
        this.isRunConvert = false;
        this.setState({
            loading: false
        })
    }

    getListCompany = async (name) => {
        try {
            this.setState({ loadingCompany: true });
            let params = {
                limit: 20,
                name: name
            }
            let res = await this.props.fetchData({
                url: `api/v1/company/list?${qs.stringify(params)}`
            });
            this.setState({
                listDataCompany: res.data,
                loadingCompany: false
            })
        } catch (err) {
            this.setState({ loadingCompany: false });
            this.props.notification({
                title: "Danh sách chi nhánh",
                content: err.message || "Đã có lỗi xảy ra!",
                type: "error"
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <div>
                        <h3>Convert uid</h3>
                        <Form
                            name="basic"
                            ref={(e) => this.formConvertUid = e}
                            initialValues={{
                                thread: 1,
                                timeout: 10
                            }}
                            layout="vertical"
                        >
                            {
                                this.state.loading ?
                                    <Progress
                                        strokeColor={{
                                            from: '#108ee9',
                                            to: '#87d068',
                                        }}
                                        percent={99.9}
                                        status="active"
                                        showInfo={false}
                                        style={{ marginBottom: "15px" }}
                                    /> : null
                            }
                            <Form.Item name="company_ids" 
                                label="Chọn chi nhánh" style={{minWidth: "50%"}}
                                
                            >
                                <Select
                                    showSearch
                                    placeholder="Chọn chi nhánh"
                                    optionFilterProp="children"
                                    filterOption={false}
                                    onSearch={(e) => {
                                        if (this.timeout_search) clearTimeout(this.timeout_search);
                                        this.timeout_search = setTimeout(() => {
                                            this.getListCompany(e)
                                        }, 300);
                                    }}
                                    allowClear
                                    mode="multiple"
                                >
                                    {this.state.listDataCompany.map((item, i) => {
                                        return <Select.Option key={i} value={item.company_id}>{item.company_name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name="thread" label="Luồng chạy">
                                <InputNumber min={1} />
                            </Form.Item>
                            <div>Ghi chú: convert n uid 1 lúc.</div>
                            <Form.Item name="timeout" label="Timeout (s)">
                                <InputNumber min={1} />
                            </Form.Item>
                            <div style={{ marginTop: "20px" }}>
                                <p className="text-success">Thành công: {this.state.statusRun ? this.state.statusRun.success : 0}</p>
                                <p className="text-danger">Lỗi: {this.state.statusRun ? this.state.statusRun.error : 0}</p>
                                <p>Không tìm thấy: {this.state.statusRun ? this.state.statusRun.empty : 0}</p>
                            </div>
                            <div className="d-flex align-items-center" style={{ marginTop: "20px" }}>
                                {
                                    this.state.loading ?
                                        <Button htmlType="button" type="danger" onClick={this.stopConvert}>Dừng lại</Button>
                                        : <Button htmlType="button" type="primary" onClick={() => {
                                            let values = this.formConvertUid.getFieldsValue();
                                            this.convertUid(values);
                                        }}>Thực hiện</Button>
                                }
                                <a href="https://drive.google.com/drive/folders/1l-XVk4wOztySNDNCS90ePKVNlz_jNBAT?usp=sharing" target="_blank" style={{ marginLeft: "15px" }}>Tải tiện ích</a>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}
