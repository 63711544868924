import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const FeedbackComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_feedback')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_feedback"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "STT",
                    responsive: ["md"],
                    render: (item, value, index) => index + 1
                },
                {
                    title: "SDT",
                    dataIndex: "phone"
                },
                {
                    title: "Họ tên",
                    dataIndex: "fullname"
                },
                {
                    title: "Kết quả",
                    dataIndex: "contentment",
                    render: (item) => {
                        return item == 1 ? "Hài lòng" : item == 2 ? "Không hài lòng" : ""
                    }
                },
                {
                    title: "Chi tiết",
                    render: (item) => {
                        return <div>
                            {
                                item.rating_text.map((item) => {
                                    return <p>{item}</p>
                                })
                            }
                            {
                                item.feedback_other && <p>{item.feedback_other}</p>
                            }
                        </div>
                    }
                }
            ]}
            pagination={false}
        />
    </div>
}

export default FeedbackComponent;