import React, { useEffect, useRef, useState } from "react";

import { Switch, message, Modal, Button, Form, Input, InputNumber, Tooltip, Select, Tag, Tabs, Upload, Table, Popover } from 'antd';

import moment from "moment";

import qs from "qs";

const ComponentView = (props) => {
    const { tabActive } = props;

    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20
    });
    const [dataDetail, setDataDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    const formFilter = useRef();
    const timeout = useRef();

    useEffect(() => {
        getListData();
    }, [pagination, tabActive]);

    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                page: pagination.page,
                limit: pagination.limit
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            let response = await props.fetchData({
                url: `api/v1/khambenh/sangloc/laymau?${qs.stringify(params)}`
            })

            setData({
                total: response.total,
                listData: response.data
            })

            setLoading(false);
        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const update = async (id, values = {}) => {
        try {
            message.loading({
                content: "Đang cập nhập dữ liệu",
                key: "update"
            });

            let response = await props.fetchData({
                url: `api/v1/khambenh/sangloc/laymau/${id}`,
                method: "put",
                data: values
            })

            getListData();

            message.success({ content: 'Cập nhập dữ liệu thành công', key: "update", duration: 2 });
        } catch (err) {
            message.error({ content: 'Cập nhập dữ liệu thất bại', key: "update", duration: 2 });
        }
    }

    return <div className="list-connect content-m d-flex">
        <Modal
            title={'Chi tiết'}
            visible={dataDetail}
            footer={null}
            onCancel={() => setDataDetail(null)}
            centered
        >
            {
                dataDetail ? 
                <div>
                    <p>Họ và tên: {dataDetail.ho_ten}</p>
                    <p>Test nhanh: {dataDetail.loai}</p>
                </div> : null
            }
            <div className="d-flex align-items-center justify-content-end">
                <Button htmlType="button" onClick={() => setDataDetail(false)} className="mr-2">Đóng</Button>
            </div>
        </Modal>
        <div className="list-c">
            <div style={{ marginBottom: "15px" }} className="table-header">
                <h3>Lấy mẫu test covid: {data.total}</h3>
                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>

                </div>
                <Form
                    initialValues={{
                        status: ""
                    }}
                    onValuesChange={(e) => {
                        if (timeout.current) clearTimeout(timeout.current);
                        let delay = 0;

                        if (e.ho_ten) delay = 300;

                        timeout.current = setTimeout(() => {
                            getListData();
                        }, delay);
                    }}
                    ref={formFilter}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="ho_ten">
                                <Input placeholder="Họ và tên" />
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="danger" htmlType="reset">Xóa lọc</Button>
                        </div>
                        <div className="col-auto">
                            <Tooltip title={'Làm mới'}>
                                <Button type="primary" onClick={getListData}><i className="far fa-sync"></i></Button>
                            </Tooltip>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="table-responsive">
                <Table
                    dataSource={data.listData}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            responsive: ["md"],
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Họ tên",
                            dataIndex: "ho_ten"
                        },
                        {
                            title: "Năm sinh",
                            dataIndex: "ngay_sinh",
                            responsive: ["md"],
                        },
                        {
                            title: "Test nhanh",
                            dataIndex: "loai",
                            responsive: ["md"],
                        },
                        {
                            title: "Trạng thái",
                            render: (item) => {
                                return <Select disabled={item.ketqua} key={Date.now()} defaultValue={item.status || 0} style={{ width: "100%" }} onChange={(e) => {
                                    update(item._id, {
                                        status: e
                                    })
                                }}>
                                    <Select.Option value={0}>Chưa thực hiện</Select.Option>
                                    <Select.Option value={1}>Đã thực hiện</Select.Option>
                                </Select>
                            }
                        },
                        {
                            title: "",
                            className: "text-right",
                            responsive: ["xs"],
                            render: (item) => {
                                return <div>
                                    <Tooltip title="Chi tiết">
                                        <Button size="small" type="primary" onClick={() => {
                                            setDataDetail(item)
                                        }}><i className="fa fa-eye"></i></Button>
                                    </Tooltip>
                                </div>
                            }
                        }
                    ]}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination(pagination);
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    loading={loading}
                />
            </div>
        </div>
    </div>

}

export default ComponentView;