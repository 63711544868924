import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert} from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import DetailRun from './list-run';
import qs from 'qs';

const { Step } = Steps;
const { Option } = Select;

export default class Broadcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            script: null
        }
        this.limit = 20;
        this.page = 1;
        this.auto_id = props.match.params.id;
    }
    componentWillMount = () => {
        this.getListData();
    }
    componentDidMount = () => {

    }
    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/broadcast/run/${this.auto_id}?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total, script: res.script });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }
    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Chi tiết chiến dịch: {this.state.total}</h3>
                        <p>Tên: {this.state.script ? this.state.script.name : ""}</p>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Quay lại'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => {
                                    this.props.history.push('/broadcast');
                                }}><i className="far fa-arrow-left"></i></a>
                            </Tooltip>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={1}>Thành công</Select.Option>
                                                    <Select.Option value={2}>Thất bại</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Page</th>
                                        <th>User</th>
                                        <th>Log</th>
                                        <th>Trạng thái</th>
                                        <th>Thời gian</th>
                                        <th className="text-right">Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>
                                                        <a href={`https://fb.com/${item.channel_id}`} target="_blank">{item.channel_id}</a>
                                                    </td>
                                                    <td>{item.user_id}</td>
                                                    <td>
                                                        <textarea className="form-control">{item.log}</textarea>
                                                    </td>
                                                    <td>
                                                        {item.status == 0 ? <span>Đang chờ</span>  : item.status == 2 ? <span className="text-success">Thành công</span> :
                                                            <span className="text-danger">Thất bại</span>}
                                                    </td>
                                                    <td>{item.time_success ? moment(item.time_success).format('HH:mm:ss DD-MM-YYYY') : null}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Xem cuộc hội thoại">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                window.open(`/chat/${item.contact_id ? item.contact_id : ''}`);
                                                            }}><i className="far fa-eye"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
