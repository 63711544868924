const { postMessageApp } = require('../lib/helpers');

const settings = {}

export var dimens = {
    deviceWidth: window.innerWidth,
    hostDomain: domainSaveCookie(),
    name_auth: 'auth',
    name_company: 'company'
}

export const AccountMetaData = {
    token: (getCookie(dimens.name_auth)) ? getToken(getCookie(dimens.name_auth)) : '',
    logOut : (url) => {
        setCookie(dimens.name_auth, '', 0);
        setCookie(dimens.name_company, '', 0);
        window.location.href = `/login${url ? `?redirect_url=${url}` : ""}`;
        return true;
    },
    logIn: (url) => {
        window.location.href = `/login${url ? `?redirect_url=${url}` : ""}`;
    },
    getToken: () => {
        try {
            return atob(getCookie(dimens.name_auth));
        } catch {
            return '';
        }
    },
    getCompany: () => {
        try {
            let company = getCookie(dimens.name_company);
            return company ? JSON.parse(company) : '';
        } catch {
            return '';
        }
    },
    getCompanyId: () => {
        try {
            let company = getCookie(dimens.name_company);
            company = company ? JSON.parse(company) : {};
            return company.company_id || "";
        } catch {
            return '';
        }
    },
    setCookie: (value, days) => {
        var name = dimens.name_auth;
        var expires = "";
        if (days) {
        var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.hostDomain+"; path=/";
    },
    getCookie: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },
    checkIssetLogin: () => {
        if( !getCookie(dimens.name_auth)  ) {
            return false;
        }
        try{
          dimens['df_t'] = atob(getCookie(dimens.name_auth));
        } catch(err){
            return false
        }
        return true;
    }
}

export const formatNumber = (nStr) => {
    nStr+='';let x=nStr.split(',');let x1=x[0];let x2=x.length>1?','+x[1]:'';let rgx=/(\d+)(\d{3})/;while(rgx.test(x1)){x1=x1.replace(rgx,'$1'+'.' + '$2');}return x1+x2;
}

export function setCookie(name, value, days) {
    var expires = "", timestamp = Date.now();
    if (days) {
      var date = new Date();
        date.setMonth(date.getMonth() + 12);
        expires = "; expires=" + date.toUTCString();
        timestamp = date.getTime();
    }
    // document.cookie = name + "=" + (value || "") + expires + ";domain=.muabanhay.com; path=/";
    document.cookie = name + "=" + (value || "") + expires + ";domain="+dimens.hostDomain+"; path=/";
    postMessageApp({
        type: 'setCookie',
        cookie: [
            {
                name: name,
                value: value,
                domain: dimens.hostDomain,
                path: '/',
                expires: timestamp
            }
        ]
    })
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export function checkIssetLogin(){
  if( !getCookie(dimens.name_auth)  ) {
      return false;
  }
  try{
    dimens['df_t'] = atob(getCookie(dimens.name_auth));
  } catch(err){
      return false
  }
  return true;
}
export function getToken(cookie){
    try {
        return atob(cookie);
    } catch {
        return '';
    }
}

export function domainSaveCookie(){
    try{
        let domain = "";
        let hostname = window.location.hostname;
        if(hostname.indexOf('.') > -1 && window.location.hostname.split('.')[0].replace(/\d/g, '') != "" ){
            domain = hostname.slice(hostname.indexOf('.'), hostname.length);
        } else {
            domain =  window.location.hostname;
        }
        console.log(domain);
        return domain;
    } catch(err){
        return "";
    }
}

export default settings