import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, Button, Input, notification } from "antd";
import WidgetInputTimeBooking from "../../../../widgets/booking/timeBooking";
import moment from "moment";
import { NotificationMessage } from "../../../../lib/helpers";
import { fetchData } from "../../../../lib/apis";

const FormEdit = (props) => {
    const { visible, hide } = props;
    const [ item, setItem ] = useState();
    const form = useRef();


    const submitForm = async (values) => {
        try{
            await fetchData({
                url: `api/v1/booking/${values['_id']}`,
                method: "put",
                data: values
            })
            hide();
            NotificationMessage({
                type: "success",
                description: "Thao tác thành công"
            })
            if(props.onFinish) props.onFinish();
        } catch(err){
            NotificationMessage({
                type: "error",
                response: err
            })
        }
    }

    useEffect(() => {
        if(visible){
            if(props.item){
                setItem(props.item);
                let booking_time = moment(props.item.booking_timestamp);
                form.current.setFieldsValue({
                    ...props.item,
                    date_booking: booking_time,
                    time_booking: booking_time
                });
            }
        } else{
            if(form.current) form.current.resetFields();
        }
    }, [visible])


    return <Modal
        title={'Cập nhập lịch hẹn'}
        visible={visible}
        footer={null}
        onCancel={hide}
    >
        <Form 
            onFinish={submitForm}
            layout="vertical"
            ref={form}
        >
            {
                item && <React.Fragment>
                    <div className="mb-2">Mã lịch hẹn: <strong>{item.booking_code}</strong></div>
                    <div className="mb-2">Họ tên: <strong>{item.fullname}</strong></div>
                </React.Fragment>
            }
            <Form.Item name="_id" noStyle>
                <Input type={"hidden"} />
            </Form.Item>
            <WidgetInputTimeBooking form={form.current} hoursBooking={props.companySelected.thoi_gian_kham_benh} />
            <Form.Item className="text-right mb-0">
                <Button type="primary" htmlType="submit">Cập nhập</Button>
            </Form.Item>
        </Form>
    </Modal>
}

export default FormEdit;