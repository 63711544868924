import { Modal, Button } from 'antd';
import React, { Component, useState, useEffect} from 'react';
import moment from "moment";

import { withRouter } from "react-router-dom";

const ModelErrorFanpage = (props) => {
    const { company } = props;

    const [ visible, setvisible ] = useState({
        visible: false,
        log: ""
    });

    useEffect(() => {
        if(company && company.log_fanpage){
            setvisible({
                visible: true,
                log: company.log_fanpage
            });
        }
    }, [ company ])

    const hide = () => setvisible({
        visible: false,
        log: ""
    });

    return <Modal
        title={'Cảnh báo'}
        visible={visible.visible}
        footer={null}
        onCancel={(hide)}
        centered
    >
        <div>
            <div style={{ whiteSpace: "break-spaces"}} dangerouslySetInnerHTML={{__html: visible.log }} />
                <div className='d-flex justify-content-end mt-2'>
                    <Button type="default" onClick={hide}>Bỏ qua</Button>
                    <Button className='ml-3' type="primary" onClick={() => {
                        props.history.push("/channel/fanpage");
                        hide();
                    }}>Kiểm tra ngay</Button>
                </div>
        </div>
    </Modal>
}

export default withRouter(ModelErrorFanpage);
