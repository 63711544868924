import React, { useEffect, useRef, useState } from "react";

import { Switch, message, Modal, Button, Form, Input, InputNumber, Tooltip, Select, Tag, Tabs, Upload, Table, Popover } from 'antd';

import moment from "moment";

import qs from "qs";

import { list_year, list_trieuchung } from "../../khaibaoyte/data";

const Vong1 = (props) => {
    const { tabActive } = props;

    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [pagination, setPagination] = useState({
        total: 0,
        page: 1,
        limit: 20
    });
    const [visibleForm, setVisibleForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);

    const [testCovid, setTestCovid] = useState({
        loading: false,
        data: []
    });

    const formFilter = useRef();
    const timeout = useRef();
    const formUpdate = useRef();

    useEffect(() => {
        getListData();
    }, [pagination, tabActive]);

    useEffect(() => {
        getListTestCovid();
    }, [])

    const getListData = async () => {
        try {
            setLoading(true);

            let params = {
                page: pagination.page,
                limit: pagination.limit
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            let response = await props.fetchData({
                url: `api/v1/khambenh/sangloc/sangloc?${qs.stringify(params)}`
            })

            setData({
                total: response.total,
                listData: response.data
            })

            setLoading(false);
        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const getListTestCovid = async () => {
        try {
            setTestCovid({
                ...testCovid,
                loading: true
            });

            let params = {
                page: 1,
                limit: 100,
                type: "LIST_TEST_COVID"
            }

            let response = await props.fetchData({
                url: `api/v1/company/service/list?${qs.stringify(params)}`
            })

            setTestCovid({
                ...testCovid,
                loading: false,
                data: response.data
            });
        } catch (err) {
            setTestCovid({
                ...testCovid,
                loading: false
            });
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    const update = async (id, type, values = {}) => {
        try {
            setLoadingForm(true);
            message.loading({
                content: "Đang cập nhập dữ liệu",
                key: "update"
            });

            let response = await props.fetchData({
                url: `api/v1/khambenh/sangloc/${type}/${id}`,
                method: "put",
                data: values
            })

            getListData();

            message.success({ content: 'Cập nhập dữ liệu thành công', key: "update", duration: 2 });
            setLoadingForm(false);
        } catch (err) {
            message.error({ content: 'Cập nhập dữ liệu thất bại', key: "update", duration: 2 });
            setLoadingForm(false);
        }
    }

    return <div className="list-connect content-m d-flex">
        <div className="list-c">
            <Modal
                title={'Cập nhập dữ liệu'}
                visible={visibleForm}
                footer={null}
                onCancel={() => setVisibleForm(false)}
                centered
            >
                <Form
                    name="basic"
                    ref={formUpdate}
                    onFinish={(values) => {
                        values['than_nhiet'] =  parseFloat(values['than_nhiet']);
                        update(values._id, 'update', values);
                    }}
                    layout="vertical"
                >
                    <Form.Item noStyle name="_id">
                        <Input type="hidden"/>
                    </Form.Item>
                    <Form.Item label="Thân nhiệt" name="than_nhiet">
                        <InputNumber 
                            formatter={value => value > 100 ? value/10 : value}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                    <Form.Item label="Chỉ định test nhanh" name="loai_test_covid">
                        <Select style={{ width: "100%" }} >
                            <Select.Option value={-1}>Không test</Select.Option>
                            {
                                testCovid.data.map((item) => {
                                    return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div className="d-flex align-items-center justify-content-end">
                            <Button htmlType="button" onClick={() => setVisibleForm(false)} className="mr-2">Đóng</Button>
                            <Button htmlType="submit" type="primary" loading={loadingForm}>Lưu</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
            <div style={{ marginBottom: "15px" }} className="table-header">
                <h3>Khám sàng lọc: {data.total}</h3>
                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>

                </div>
                <Form
                    initialValues={{
                        status: ""
                    }}
                    onValuesChange={(e) => {
                        if (timeout.current) clearTimeout(timeout.current);
                        let delay = 0;

                        if (e.ho_ten) delay = 300;

                        timeout.current = setTimeout(() => {
                            getListData();
                        }, delay);
                    }}
                    ref={formFilter}
                >
                    <div className="row">
                        <div className="col-md-2">
                            <Form.Item name="ho_ten">
                                <Input placeholder="Họ và tên" />
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="danger" htmlType="reset">Xóa lọc</Button>
                        </div>
                        <div className="col-auto">
                            <Tooltip title={'Làm mới'}>
                                <Button type="primary" onClick={getListData}><i className="far fa-sync"></i></Button>
                            </Tooltip>
                        </div>
                    </div>
                </Form>
            </div>
            <div className="table-responsive">
                <Table
                    dataSource={data.listData}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            responsive: ["md"],
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Họ tên",
                            dataIndex: "ho_ten"
                        },
                        {
                            title: "Năm sinh",
                            dataIndex: "ngay_sinh",
                            responsive: ["md"],
                        },
                        {
                            title: "Dấu hiệu",
                            dataIndex: "dich_te",
                            render: (item) => {
                                if(!item) return "Không TT dịch tễ";
                                let trieuchung = list_trieuchung.filter((item__) => item[item__.name]);
                                return trieuchung.length > 0 ? <Popover
                                    title=""
                                    trigger="click"
                                    content={
                                        <div style={{ whiteSpace: "break-spaces" }}>{trieuchung.map((item) => item.title).join("\n")}</div>
                                    }
                                >
                                    <a href="javascript:;">Có</a>
                                </Popover> : <span>Không</span>;
                            }
                        },
                        {
                            title: "Thân nhiệt",
                            responsive: ["md"],
                            render: (item) => {
                                return <InputNumber disabled={item.status_test_covid >= 1 || item.status == 1 || !item.dich_te} 
                                    defaultValue={item.than_nhiet || null} onBlur={(e) => {
                                        update(item._id, "update", {
                                            than_nhiet: parseFloat(e.target.value)
                                        })
                                    }} 
                                    key={Date.now()}
                                    formatter={value => value > 100 ? value/10 : value}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            }
                        },
                        {
                            title: "Test nhanh",
                            responsive: ["md"],
                            render: (item) => {
                                return <Select disabled={item.status_test_covid >= 1 || item.status == 1 || !item.dich_te} key={Date.now()} defaultValue={item.loai_test_covid || ""} style={{ width: "100%" }} onChange={(e) => {
                                    update(item._id, "update", {
                                        loai_test_covid: e
                                    })
                                }}>
                                    <Select.Option value={-1}>Không test</Select.Option>
                                    {
                                        testCovid.data.map((item) => {
                                            return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            }
                        },
                        {
                            title: "Ngày thêm",
                            dataIndex: "created_time",
                            responsive: ["md"],
                            render: (item) => {
                                return moment(item).format("HH:mm:ss DD-MM-YYYY")
                            }
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                let trieuchung = list_trieuchung.filter((item__) => item[item__.name]);
                                return <div>
                                    <Tooltip title="Chỉnh sửa">
                                        <Button disabled={item.status_test_covid >= 1} className="d-md-none mb-2" size="small" type="primary" onClick={() => {
                                            setVisibleForm(true)
                                            setTimeout(() => {
                                                formUpdate.current.setFieldsValue(item)
                                            })
                                        }}><i className="fa fa-edit"></i></Button>
                                    </Tooltip>
                                    {
                                        trieuchung.length == 0 && item.loai_test_covid == -1?
                                        <Tooltip title="Chuyển khám bệnh">
                                            <Button disabled={item.status >= 1 || !item.than_nhiet} size="small" type="primary" onClick={() => {
                                                update(item._id, "update", {
                                                    status: 1
                                                })
                                            }}><i className="fa fa-arrow-right"></i></Button>
                                        </Tooltip>
                                        : <Tooltip title="Chuyển thu phí">
                                            <Button disabled={item.status_test_covid >= 1 || !item.loai_test_covid} size="small" type="primary" onClick={() => {
                                                update(item._id, "sangloc", {
                                                    status: 1
                                                })
                                            }}><i className="fa fa-usd-circle"></i></Button>
                                        </Tooltip>
                                    }
                                </div>
                            }
                        }
                    ]}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination({
                                ...pagination
                            });
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    // rowSelection={{
                    //     type: "checkbox",
                    //     selectedRowKeys: dataSelected,
                    //     onChange: (values) => {
                    //         setDataSelected(values);
                    //     }
                    // }}
                    loading={loading}
                />
            </div>
        </div>
    </div>
}

export default Vong1;
