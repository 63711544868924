import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Pagination, Tooltip, Alert, Upload, Table, InputNumber, Tag, Checkbox } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import { AccountMetaData } from '../../../config/settings';

import { WidgetSelectSpinText, WidgetPolicyTagFb } from '../../../widgets';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPages: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: [],
            listTypes: [],
            typeActive: ""
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListPages();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/auto_target/list?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total, listTypes: res.list_type || [] });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getListPages = async (keyword = '') => {
        try {
            let res = await fetchData({
                url: `api/v1/channel/list?acc_type=5&limit=10&keyword=${keyword}`
            });
            this.setState({ listPages: res.data });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Tìm fanpage',
                type: 'error'
            })
        }
    }

    remove = async (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/auto_target/${id}`,
                            method: 'delete'
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });
            if (values['attachments']) {
                let attachments = [];
                if (values['attachments']['fileList']) {
                    values['attachments']['fileList'].forEach((item) => {
                        if (item.status == "done") {
                            attachments.push(item.response ? {
                                type: item.response.attachment.type,
                                url: item.response.attachment.url
                            } : { type: item.type, url: item.url })
                        }
                    })
                } else {
                    values['attachments'].forEach((item) => {
                        attachments.push({ type: item.type, url: item.url });
                    })
                }
                values['attachments'] = attachments;
            }

            if (values.method != "update_status") {
                values['array_last_time_event'] = [values['send_d'] || 0, values['send_h'] || 0, values['send_m'] || 0, values['send_s'] || 0];
                values['last_time_event'] = (values['array_last_time_event'][3] * 1000) + (values['array_last_time_event'][2] * 1000 * 60) + (values['array_last_time_event'][1] * 1000 * 60 * 60) + (values['array_last_time_event'][0] * 1000 * 60 * 60 * 24)
            }

            let url = `api/v1/auto_target/create`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/auto_target/${values['_id']}`;
                method = 'put';
            }

            values['setting'] = {};

            Object.keys(values).forEach((key) => {
                if (key.indexOf('setting_') == 0) {
                    values['setting'][key.replace('setting_', '')] = values[key];
                }
            })

            await fetchData({
                url: url,
                data: values,
                method: method
            });
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: values['_id'] ? this.state.visibleForm : false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập kịch bản',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập kịch bản',
                type: 'error'
            })
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <WidgetPolicyTagFb visible={this.state.visiblePolicyTagFb}
                    hide={() => this.setState({ visiblePolicyTagFb: false })}
                />
                <div className="list-c">
                    <Modal
                        title={'Cập nhập kịch bản'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false })}
                        width={900}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            initialValues={{
                                content: '',
                                is_direct: true,
                                type: 'handle_tag'
                            }}
                            onFinish={this.save}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name="name" label="Tên chiến dịch"
                                        rules={[{ required: true, message: 'Không được để trống' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="type" label="Loại sự kiện" rules={[{ required: true, message: 'Không được để trống' }]}>
                                        <Select onChange={(e) => this.setState({ typeActive: e })}>
                                            {
                                                this.state.listTypes.map((item) => {
                                                    return <Select.Option value={item.type}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    {
                                        this.state.typeActive == "feedback" || this.state.typeActive == "feedback_2" ?
                                            <React.Fragment>
                                                <Form.Item label="Kết quả" name="setting_contentment">
                                                    <Select >
                                                        <Select.Option value={1}>Hài lòng</Select.Option>
                                                        <Select.Option value={2}>Không hài lòng</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <Form.Item
                                                    name="channels"
                                                    label="Chọn fanpage"
                                                // rules={[{ required: true, message: 'Chọn ít nhất 1 fanpage' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Chọn fanpage"
                                                        optionFilterProp="children"
                                                        filterOption={false}
                                                        onSearch={(e) => {
                                                            if (this.timeout_fetchpages) clearTimeout(this.timeout_fetchpages);
                                                            this.timeout_fetchpages = setTimeout(() => {
                                                                this.getListPages(e)
                                                            }, 300);
                                                        }}
                                                        // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                                        allowClear
                                                        mode="multiple"
                                                        disabled={this.state.typeActive == "feedback"}
                                                    >
                                                        {this.state.listPages.map((item, i) => {
                                                            return <Option key={i} value={item.user_id}>{item.username || item.full_name}</Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item label="Nguồn khác" name="contact_type">
                                                    <Select mode="multiple" disabled={this.state.typeActive == "feedback"}>
                                                        <Select.Option value="callcenter">Callcenter</Select.Option>
                                                        <Select.Option value="www">Web</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    name="message_tag"
                                                    label={<span className="d-flex align-items-center"><InfoCircleFilled style={{ color: "#1a90ff", marginRight: "5px" }} onClick={() => this.setState({ visiblePolicyTagFb: true })} /> Chọn message tag</span>}
                                                >
                                                    <Select
                                                        placeholder="Chọn tag"
                                                        optionFilterProp="children"
                                                        className="text-left"
                                                        disabled={this.state.typeActive == "feedback"}
                                                    >
                                                        <Option value="CONFIRMED_EVENT_UPDATE">CONFIRMED_EVENT_UPDATE</Option>
                                                        <Option value="POST_PURCHASE_UPDATE">POST_PURCHASE_UPDATE</Option>
                                                        <Option value="ACCOUNT_UPDATE">ACCOUNT_UPDATE</Option>
                                                    </Select>
                                                </Form.Item>
                                            </React.Fragment>
                                    }

                                    <Form.Item label="Thời gian gửi trước/sau sự kiện">
                                        <div className="d-flex align-items-center">
                                            <Form.Item name="send_d" noStyle>
                                                <InputNumber min={0} />
                                            </Form.Item>
                                            <div className="ml-2 mr-2">Ngày</div>
                                            <Form.Item name="send_h" noStyle>
                                                <InputNumber min={0} />
                                            </Form.Item>
                                            <div className="ml-2 mr-2">Giờ</div>
                                            <Form.Item name="send_m" noStyle>
                                                <InputNumber min={0} />
                                            </Form.Item>
                                            <div className="ml-2 mr-2">Phút</div>
                                            <Form.Item name="send_s" noStyle>
                                                <InputNumber min={0} />
                                            </Form.Item>
                                            <div className="ml-2">giây</div>
                                        </div>
                                    </Form.Item>

                                    <Form.Item label="Điều kiện gửi (phút) - áp dụng khi khách quay lại tương tác" name="time_condition">
                                        <InputNumber min={1} style={{ width: "100%" }} disabled={this.state.typeActive == "feedback" || this.state.typeActive == "feedback_2"}/>
                                    </Form.Item>

                                    {
                                        this.state.typeActive == "feedback_2" && <Form.Item label="Danh sách người quản lý" name="setting_to_phone">
                                            <Input.TextArea placeholder="0362684xxx | Tên người quản lý" style={{ minHeight: "120px" }} />
                                        </Form.Item>
                                    }

                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="content"
                                        label="Nội dung"
                                        rules={[{ required: true, message: 'Nội dung không được để trống!' }]}
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <WidgetSelectSpinText
                                        data={[
                                            {
                                                value: "#{FULL_NAME}",
                                                text: "Tên khách hàng"
                                            },
                                            {
                                                value: "#{PHONE}",
                                                text: "SDT khách hàng"
                                            },
                                            {
                                                value: "#{PAGE_NAME}",
                                                text: "Tên fanpage"
                                            },
                                            {
                                                value: "#{COMPANY_NAME}",
                                                text: "Tên chi nhánh"
                                            },
                                            {
                                                value: "#{FULL_NAME_MEMBER}",
                                                text: "Tên nhân viên"
                                            },
                                            {
                                                value: "#{BOOKING_CODE}",
                                                text: "Mã lịch hẹn"
                                            },
                                            {
                                                value: "#{BOOKING_DATE}",
                                                text: "Thời gian lịch hẹn"
                                            },
                                            {
                                                value: "#{BOOKING_SERVICE}",
                                                text: "Dịch vụ lịch hẹn"
                                            },
                                            {
                                                value: "#{TAG_NAME}",
                                                text: "Nhãn"
                                            },
                                            {
                                                value: "#{PARENT_TAG_NAME}",
                                                text: "Nhóm Nhãn"
                                            },
                                            {
                                                value: "#{COMPANY_ID}",
                                                text: "Mã chi nhánh"
                                            },
                                            {
                                                value: "#{DATE}",
                                                text: "Ngày tháng năm"
                                            },
                                            {
                                                value: "#{Spin_1|Spin_2}",
                                                text: "Chọn từ khóa ngẫu nhiên"
                                            }
                                        ]}
                                        visible={this.state.visibleSpinText}
                                        handleVisible={() => this.setState({ visibleSpinText: !this.state.visibleSpinText })}
                                        setValues={(value) => {
                                            this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + value })
                                        }}
                                        numShow={2}
                                    />
                                    <Form.Item name="content_type" valuePropName="checked">
                                        <Checkbox value={1}>Cho phép gửi tiếng Việt có dấu (sms brandname)</Checkbox>
                                    </Form.Item>
                                    {
                                        this.state.typeActive == "feedback" || this.state.typeActive == "feedback_2" ?
                                            null
                                            : <Form.Item
                                                label="Tệp đính kèm"
                                                name="attachments"
                                            >
                                                <Upload
                                                    action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                                    listType="picture-card"
                                                    fileList={[...this.state.fileList]}
                                                    className="upload-list-inline"
                                                    withCredentials={true}
                                                    headers={{
                                                        Authorization: AccountMetaData.getToken()
                                                    }}
                                                    ref={(evt) => this.uploadFile = evt}
                                                    onRemove={(e) => {
                                                        let index = this.state.fileList.findIndex((item) => item.uid == e.uid);
                                                        if (index > -1) {
                                                            let fileList = this.state.fileList;
                                                            fileList.splice(index, 1);
                                                            this.setState({ fileList: fileList })
                                                        }
                                                    }}
                                                    onChange={({ fileList }) => this.setState({ fileList })}
                                                    onPreview={(file) => {
                                                        let url = file.url;
                                                        if (file.response && file.response.attachment) url = file.response.attachment.url;
                                                        if (url) window.open(url)
                                                    }}
                                                    maxCount={1}
                                                    disabled={this.state.typeActive == "feedback"}
                                                >
                                                    {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
                                                    <div>
                                                        <PlusOutlined />
                                                        <div style={{ marginTop: 8 }}>Upload</div>
                                                    </div>

                                                </Upload>
                                            </Form.Item>
                                    }
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Auto target: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm kịch bản'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true, fileList: [], typeActive: "handle_tag" }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                type: "",
                                active: ""
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row">
                                <div className="col-md-2">
                                    <Form.Item name="channel_id">
                                        <Input placeholder="ID Fanpge" />
                                    </Form.Item>
                                </div>
                                <div className="col-md-2">
                                    <Form.Item name="active">
                                        <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                            <Select.Option value="">Trạng thái</Select.Option>
                                            <Select.Option value={1}>kích hoạt</Select.Option>
                                            <Select.Option value={2}>Tắt</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Table
                            columns={[
                                {
                                    title: "#",
                                    index: "#",
                                    render: (item, value, index) => {
                                        return (this.page * this.limit - this.limit) + index + 1
                                    }
                                },
                                {
                                    title: "Tên",
                                    dataIndex: "name"
                                },
                                {
                                    title: "Loại",
                                    dataIndex: "type",
                                    render: (item) => {
                                        let find = this.state.listTypes.find((type) => type.type == item);
                                        return find ? <Tag style={{ fontSize: "13px" }}>{find.name}</Tag> : null
                                    }
                                },
                                {
                                    title: "Nội dung",
                                    dataIndex: "content",
                                    render: (item) => {
                                        return <textarea disabled className="form-control" value={item}></textarea>
                                    }
                                },
                                {
                                    title: "TT kích hoạt",
                                    render: (item) => {
                                        return <Switch checked={item.active == 1 ? true : false} onChange={(e) => this.save({ _id: item._id, active: e ? 1 : 2, method: "update_status" })} />
                                    }
                                },
                                {
                                    title: "Ngày tạo",
                                    dataIndex: "created_time",
                                    render: (item) => moment(item).format("HH:MM DD-MM-YYYY")
                                },
                                {
                                    title: "Hành động",
                                    className: "text-right",
                                    render: (item) => {
                                        return <div>
                                            <Tooltip title="Xóa">
                                                <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                    this.remove(item._id)
                                                }}><i className="far fa-trash"></i></Button>
                                            </Tooltip>
                                            <Tooltip title="chi tiết">
                                                <Button size="small" type="primary" onClick={() => {
                                                    this.setState({
                                                        visibleForm: true,
                                                        typeActive: item.type
                                                    }, () => {
                                                        setTimeout(() => {
                                                            try {
                                                                this.formH.setFieldsValue({
                                                                    ...item,
                                                                    send_d: item.array_last_time_event[0] || 0,
                                                                    send_h: item.array_last_time_event[1] || 0,
                                                                    send_m: item.array_last_time_event[2] || 0,
                                                                    send_s: item.array_last_time_event[3] || 0
                                                                });
                                                                if (item.setting) {
                                                                    Object.keys(item.setting).forEach((key) => {
                                                                        this.formH.setFieldsValue({
                                                                            ['setting_' + key]: item.setting[key]
                                                                        })
                                                                    })
                                                                }
                                                                let fileList = item.attachments.map((item, i) => {
                                                                    return {
                                                                        uid: `${i}`,
                                                                        status: 'done',
                                                                        url: item.url,
                                                                        type: item.type
                                                                    }
                                                                })
                                                                this.setState({ fileList: fileList })
                                                            } catch (err) {
                                                                console.log(err);
                                                            }
                                                        }, 0)
                                                    })
                                                }}><i className="far fa-eye"></i></Button>
                                            </Tooltip>
                                        </div>
                                    }
                                }
                            ]}
                            dataSource={this.state.listData}
                            rowKey="_id"
                            loading={this.state.loading}
                            pagination={{
                                total: this.state.total,
                                pageSize: this.limit,
                                onChange: (current) => {
                                    this.page = current;
                                    this.getListData();
                                },
                                current: this.page,
                                showSizeChanger: false
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
