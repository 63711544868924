import { Form, Select, Input } from "antd";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { fetchData } from "../../lib/apis";

const WidgetSelectAddress = (props) => {
    const [loadingCity, setLoadingCity] = useState(false);
    const [data, setData] = useState({
        commune: [],
        district: [],
        province: []
    })
    const [value, setValue] = useState({
        commune: null,
        district: null,
        province: null
    })

    useEffect(() => {
        getCity();
    }, [])

    const getCity = async (type, parent_id = "") => {
        try {
            setLoadingCity(type);

            let response = await fetchData({
                url: `api/v1/geo/list?parent_id=${parent_id}`
            })

            if (type == "commune") {
                setData({
                    ...data,
                    commune: response.data
                })
            } else if (type == "district") {
                setData({
                    ...data,
                    district: response.data
                })
            } else {
                setData({
                    ...data,
                    province: response.data
                })
            }

            setLoadingCity(false);

        } catch (err) {
            setLoadingCity(false);
        }
    }

    const changeProvince = (e) => {
        let find = data.province.find((item) => item.city_id == e);

        setValue({
            ...value,
            province: find && { id: find.city_id, name: find.name },
            district: null,
            commune: null
        })
        setData({
            ...data,
            district: [],
            commune: []
        })
    }

    const changeDistrict = (e) => {
        let find = data.district.find((item) => item.city_id == e);

        setValue({
            ...value,
            district: find && { id: find.city_id, name: find.name },
            commune: null
        })
        setData({
            ...data,
            commune: []
        })
    }

    const changeCommune = (e) => {
        let find = data.commune.find((item) => item.city_id == e);
        setValue({
            ...value,
            commune: find && { id: find.city_id, name: find.name }
        })
    }

    useEffect(() => {
        if (value.province) {
            getCity("district", value.province.id);
        }
    }, [value.province])

    useEffect(() => {
        if (value.district) {
            getCity("commune", value.district.id);
        }
    }, [value.district])

    useEffect(() => {
        if (props.refForm) {
            props.refForm.current.setFieldsValue(value);
            if(value.province){
                props.refForm.current.setFieldsValue({
                    address_input_boolean: true
                });
            } else{
                props.refForm.current.setFieldsValue({
                    address_input_boolean: ""
                });
            }
        }
    }, [value])

    return <React.Fragment>
        <Form.Item label="Địa chỉ"
            rules={[
                {
                    required: true,
                    message: "Địa chỉ không được để trống"
                }
            ]}
            name="address_input_boolean"
        >
            <Input type="hidden" />
            <div>
                <Form.Item name="province" noStyle >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="district" noStyle >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="commune" noStyle >
                    <Input type="hidden" />
                </Form.Item>
                <div className="row gx-5">
                    <div className="col-md-4">
                        <Form.Item valuePropName={"selected"} className="mb-0"
                            // validateStatus={!value.province && "error"}
                        >
                            <Select loading={loadingCity === "province"}
                                onChange={changeProvince}
                                showSearch={!props.isAppNative}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Chọn Tỉnh/ TP"
                                value={value.province && value.province.id}
                            >
                                {
                                    data.province.map((item) => {
                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item valuePropName={"selected"} className="mb-0"
                            // validateStatus={!value.district && "error"}
                        >
                            <Select loading={loadingCity == "district"}
                                onChange={changeDistrict}
                                showSearch={!props.isAppNative}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Chọn Quận/ Huyện"
                                value={value.district && value.district.id}
                            >
                                {
                                    data.district.map((item) => {
                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-md-4">
                        <Form.Item valuePropName={"selected"} className="mb-0"
                            // validateStatus={!value.commune && "error"}
                        >
                            <Select loading={loadingCity == "commune"}
                                showSearch={!props.isAppNative}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Chọn Xã/ Phường"
                                value={value.commune && value.commune.id}
                                onChange={changeCommune}
                            >
                                {
                                    data.commune.map((item) => {
                                        return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form.Item>
        <Form.Item name={"address"} label="Địa chỉ cụ thể">
            <Input />
        </Form.Item>
    </React.Fragment>
}

export default WidgetSelectAddress;