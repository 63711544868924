import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, Pagination, Tooltip, Select, Tag, Tabs, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';

export default class HistoryStaffChangeView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loading: false,
            total: 0
        }
        this.page = 1;
        this.limit = 20;
    }
    componentWillMount() {
        this.getListData(1);
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    getListData = async (key) => {
        try {
            this.setState({ loading: true });
            let params = {
                page: this.page,
                limit: this.limit,
                type: key == 1 ? "change_status_chat" : "change_staff_chat"
            }
            let response = await this.props.fetchData({
                url: `api/v1/action/log?${qs.stringify(params)}`
            })
            this.setState({
                listData: response.data,
                total: response.total,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    render() {
        return (
            <div className="list-connect content-m d-flex">
                <div className="list-c">
                    <Tabs type="card" onChange={(e) => {
                        this.page = 1;
                        this.limit = 20;
                        this.getListData(e);
                    }}>
                        <Tabs.TabPane tab="Lịch sử on/off" key="1">
                            <Spin spinning={this.state.loading}>
                                <Table
                                    columns={[
                                        {
                                            title: "Mã NV",
                                            key: "from",
                                            dataIndex: "from",
                                            render: (from) => from ? from.code : ''
                                        },
                                        {
                                            title: "Tên NV",
                                            key: "from",
                                            dataIndex: "from",
                                            render: (from) => from ? from.fullname : ''
                                        },
                                        {
                                            title: "Trạng thái",
                                            key: "data",
                                            dataIndex: "data",
                                            className: "text-center",
                                            render: (data) => {
                                                return <span className={`${data.status == 1 ? 'text-success' : 'text-danger'}`}>{data.log}</span>
                                            }
                                        },
                                        {
                                            title: "Thời gian",
                                            key: "created_time",
                                            dataIndex: "created_time",
                                            className: "text-center",
                                            render: (created_time) => moment(created_time).format('HH:mm DD-MM-YYYY')
                                        }
                                    ]}
                                    dataSource={this.state.listData}
                                    bordered={true}
                                    pagination={{
                                        total: this.state.total,
                                        current: this.page,
                                        pageSize: this.limit,
                                        onChange: (current) => {
                                            this.page = current;
                                            this.getListData();
                                        },
                                        onShowSizeChange: (current, size) => {
                                            this.page = current;
                                            this.limit = size;
                                            this.getListData();
                                        },
                                        position: ["topRight"]
                                    }}
                                />
                            </Spin>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Phân công lịch trực" key="2">
                            <Spin spinning={this.state.loading}>
                                <Table
                                    columns={[
                                        {
                                            title: "Mã NV",
                                            key: "from",
                                            dataIndex: "from",
                                            render: (from) => from ? from.code : ''
                                        },
                                        {
                                            title: "Tên NV",
                                            key: "from",
                                            dataIndex: "from",
                                            render: (from) => from ? from.fullname : ''
                                        },
                                        {
                                            title: "Log",
                                            key: "data",
                                            dataIndex: "data",
                                            className: "text-center",
                                            render: (data) => {
                                                return data.log
                                            }
                                        },
                                        {
                                            title: "Nhân viên",
                                            key: "data",
                                            dataIndex: "data",
                                            className: "text-center",
                                            render: (data) => {
                                                return data.sale_code ? data.sale_code.join(', ') : ''
                                            }
                                        },
                                        {
                                            title: "Thời gian",
                                            key: "created_time",
                                            dataIndex: "created_time",
                                            className: "text-center",
                                            render: (created_time) => moment(created_time).format('HH:mm DD-MM-YYYY')
                                        }
                                    ]}
                                    dataSource={this.state.listData}
                                    bordered={true}
                                    pagination={{
                                        total: this.state.total,
                                        current: this.page,
                                        pageSize: this.limit,
                                        onChange: (current) => {
                                            this.page = current;
                                            this.getListData();
                                        },
                                        onShowSizeChange: (current, size) => {
                                            this.page = current;
                                            this.limit = size;
                                            this.getListData();
                                        },
                                        position: ["topRight"]
                                    }}
                                />
                            </Spin>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
