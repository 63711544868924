import React, { useEffect, useRef, useState } from "react";

import { Modal, Button, Form, Input, Select, InputNumber, DatePicker, Checkbox, Alert, TreeSelect, message } from "antd";

import moment from "moment";
import qs from 'qs';
import { fetchData } from "../../../../lib/apis";
import { spinText, isTiengViet } from "../../../../lib/helpers";

import { FormReplyFast } from "../../../../widgets";

const FormSmsBrandName = (props) => {
    const { visible, hide, contact, hideContentExample, type } = props;
    const [stateSave, setStateSave] = useState({
        loading: false,
        error: null
    });
    const [content, setContent] = useState("");
    const [visibleContentSample, setVisibleContentSample] = useState();

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setStateSave({ loading: true });

            values['time_start'] = values['time_start'] ? values['time_start'].unix() * 1000 : Date.now();

            let url = "";

            if(type == "khambenh"){
                url = `api/v1/khambenh/${contact.contact_id}/sms`
            } else {
                url = `api/v1/contact/${contact.contact_id}/sms`
            }

            let res = await fetchData({
                url: url,
                method: 'post',
                data: values
            });

            setStateSave({ loading: false });

            formRef.current.resetFields();

            props.hide();

            message.success("Hệ thống đã tiếp nhận tin nhắn gửi đến khách hàng!")

        } catch (err) {
            setStateSave({
                loading: false,
                error: err.message
            })
        }
    }

    return <React.Fragment>
        {
            !hideContentExample && <FormReplyFast {...props} 
                visible={visibleContentSample}
                hide={() => setVisibleContentSample(false)} 
                selectReply={(e) => {
                    setVisibleContentSample(false);
                    formRef.current.setFieldsValue({
                        content: spinText(e.content, props.contactInfoSelected.from)
                    })
                }}
                typeReplySample={"quick_reply_sms"}
            />
        }

        <Modal
            title={'SMS Marketing'}
            visible={visible}
            footer={null}
            onCancel={hide}
            width={600}
            centered
        >
            <Form
                name="basic"
                initialValues={{
                    content: ""
                }}
                onFinish={onFinish}
                ref={formRef}
                layout="vertical"
            >
                {
                    stateSave.error ? <Alert message={stateSave.error} type="error" /> : null
                }
                <Form.Item
                    name="content"
                    label="Nội dung"
                    rules={[
                        { required: true, message: 'Nội dung không được để trống!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if(isTiengViet(value) && getFieldValue("content_type") != 1) return Promise.reject(new Error('Vui lòng nhập tiếng Việt không dấu'));
                              return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Input.TextArea style={{ minHeight: "120px" }} onChange={(e) => setContent(e.target.value)} />
                </Form.Item>
                {
                    !hideContentExample && <div className="text-right">
                        <Button size="small" onClick={() => setVisibleContentSample(true)}>Nội dung mẫu</Button>
                    </div>
                }

                <div className="mb-2">Ký tự đã nhập: {content.length}</div>

                <Form.Item name="content_type" valuePropName="checked">
                    <Checkbox value={1}>Cho phép gửi tiếng Việt có dấu</Checkbox>
                </Form.Item>

                <Form.Item label="Thời gian gửi" name="time_start">
                    <DatePicker showTime
                        disabledDate={(date) => date < moment().add("day", -1)}
                        format="YYYY-MM-DD HH:mm"
                    />
                </Form.Item>

                <div className="d-flex justify-content-end mt-4">
                    <Button htmlType="button" style={{ margin: '0 8px' }} onClick={hide}>
                        Đóng
                    </Button>
                    <Button htmlType="submit" type="primary" loading={stateSave.loading}>
                        Tiếp tục
                    </Button>
                </div>
            </Form>
        </Modal>
    </React.Fragment>
}

export default FormSmsBrandName;