import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, Pagination, Tooltip, Select, Tag, Tabs } from 'antd';
import { fetchData } from '../../../lib/apis';
import { dimens, setCookie } from '../../../config/settings';
import moment from 'moment';

export default class MeView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    update = async (values) => {
        try {
            this.setState({ loading: true });
            await fetchData({
                url: `api/v1/user/update`,
                method: 'put',
                data: values
            })
            this.setState({
                loading: false
            })
            this.props.notification({
                title: "Cập nhập thông tin cá nhân",
                content: 'Thao tác thành công!'
            })

            this.props.getUserInfo();
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                title: "Cập nhập thông tin cá nhân",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    changePassword = async (values) => {
        try {
            this.setState({ loadingPassword: true });
            let response = await fetchData({
                url: `api/v1/user/change_pw`,
                method: 'post',
                data: values
            })
            setCookie(dimens.name_auth, btoa(response.token), 7);

            this.setState({
                loadingPassword: false,
                visibleFormChangePassword: false
            })

            this.props.notification({
                title: "Cập nhập mật khẩu",
                content: 'Thao tác thành công!',
                type: "success"
            })

            this.formPassword.resetFields();

        } catch (err) {
            this.setState({ loadingPassword: false });
            this.props.notification({
                title: "Cập nhập mật khẩu",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    uploadImage = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.files.length > 0) {
            var file = e.target.files[0];
            let formData = new FormData();
            formData.append('attachment', file);
            console.log('start---------');
            try {
                this.setState({loadingUpload: true, loading: true});
                let response = await fetchData({
                    url: `api/v1/upload/image`,
                    data: formData,
                    method: 'post',
                    type_post: 'upload_image'
                });
                let avatar = `${process.env.REACT_APP_API_CHOTSALE_URL}/${response.data.url}`;
                this.formEdit.setFieldsValue({
                    avatar: avatar
                })
                console.log(avatar);
                document.querySelector('#previewAvatar').setAttribute("src", avatar);

                this.props.notification({
                    title: "Upload image",
                    content: 'Thao tác thành công!',
                    type: "success"
                })
                this.setState({loadingUpload: false, loading: false });
            } catch (err) {
                this.setState({loadingUpload: false, loading: false});
                this.props.notification({
                    title: "Upload image",
                    content: err.message || "Thao tác thất bại",
                    type: "error"
                })
            }
        }

    }
    render() {
        return (
            <div className="list-connect content-m">
                <Modal
                    title={'Cập nhập'}
                    visible={this.state.visibleFormChangePassword}
                    onCancel={() => this.setState({ visibleFormChangePassword: null })}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formPassword = evt}
                        onFinish={this.changePassword}
                        layout="vertical"
                    >
                        <Form.Item
                            name="password_old"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu cũ!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu cũ" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Bạn phải nhập mật khẩu mới!' }]}
                        >
                            <Input.Password placeholder="Mật khẩu mới" />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Không trùng khớp');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder="Nhập lại mật khẩu" />
                        </Form.Item>
                        <div style={{ textAlign: "right" }} className="mt-4">
                            <Button onClick={() => this.setState({ visibleFormChangePassword: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingPassword}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <h3>Thông tin cá nhân</h3>
                    <div>
                        <Form
                            initialValues={{
                                fullname: this.props.UserLogin.fullname,
                                email: this.props.UserLogin.email,
                                phone: this.props.UserLogin.phone,
                                first_name: this.props.UserLogin.first_name,
                                last_name: this.props.UserLogin.last_name
                            }}
                            className="pl-sm-5 pb-sm-3 pt-sm-2 pr-sm-5"
                            onFinish={this.update}
                            ref={(evt) => this.formEdit = evt}
                            style={{background: "#fafafa"}}
                            layout="vertical"
                        >
                            <div style={{marginBottom: "10px"}}>
                                <p className="mb-0"><b>Mã:</b> {this.props.UserLogin.code}</p>
                                <p className="mb-0"><b>Ngày tham gia:</b> {moment(this.props.UserLogin.created_time).format('HH:mm DD/MM/YYYY')}</p>
                            </div>
                            <Form.Item name="avatar" noStyle
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item
                                label="Ảnh đại diện"
                            >
                                <div>
                                    <img src={this.props.UserLogin.avatar} className="img-avatar"
                                        onError={(e) => e.target.src = "/user.jpg"} id="previewAvatar"
                                    />
                                    <Input type="file" style={{ maxWidth: "300px", marginTop: "5px" }} 
                                        onChange={this.uploadImage} disabled={this.state.loadingUpload || this.state.loading}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item label="Họ và tên">
                                <div className="d-flex">
                                    <Form.Item name="first_name"
                                        rules={[{ required: true, message: 'Họ không được để trống' }]}
                                        style={{marginRight: "10px"}}
                                    >
                                        <Input placeholder="Họ"/>
                                    </Form.Item>
                                    <Form.Item name="last_name"
                                        rules={[{ required: true, message: 'Họ không được để trống' }]}
                                    >
                                        <Input placeholder="Tên"/>
                                    </Form.Item>
                                </div>
                            </Form.Item>
                            <Form.Item name="email" label="Email">
                                <Input />
                            </Form.Item>
                            <Form.Item name="phone" label="Số điện thoại">
                                <Input />
                            </Form.Item>
                            <Form.Item style={{ marginTop: "20px" }}>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>Cập nhập</Button>
                                <Button style={{ marginLeft: "5px" }} type="link" onClick={() => this.setState({ visibleFormChangePassword: true })}>Thay đổi mật khẩu</Button>
                            </Form.Item>
                            {
                                this.props.UserLogin.role_action.indexOf("create_booking") > -1 && <React.Fragment>
                                    <div className="border-top mt-5"></div>
                                     <div className="mt-3">
                                        <div>
                                            <a style={{textDecoration: "underline", fontSize: "15px"}} href={this.props.getLinkBooking(this.props.UserLogin.id_callback)} target="_blank">
                                                Link đặt lịch hẹn tạo doanh thu cho bạn
                                            </a>
                                            <Tooltip title="Copy link để gửi cho bạn bè, chia sẻ hội nhóm mạng xã hội nhằm tạo doanh thu tự động.">
                                                <Button htmlType="button" className="ml-3" onClick={() => {
                                                    this.props.copyText(this.props.getLinkBooking(this.props.UserLogin.id_callback))
                                                }} >Copy</Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Form>
                        
                    </div>
                </div>
            </div>
        )
    }
}
