import React, { useRef, useState } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert, Image, Select } from 'antd';
import { apis, fetchData } from '../../../lib/apis';
import moment from 'moment';

import locale from 'antd/es/date-picker/locale/vi_VN';
import { useEffect } from 'react';
import { ConvertImgBase64, NotificationMessage } from '../../../lib/helpers';
import config from '../../../lib/config';
import WidgetFaceDataDetail from './widget/detail';


const FaceDataView = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: []
    })
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 20
    })
    const [loading, setloading] = useState(false);
    const [visibleDetail, setvisibleDetail] = useState({
        visible: false,
        faceId: null
    });

    const formFilter = useRef();
    const searchFilter = useRef();

    const getListData = async () => {
        try {
            setloading(true);

            let params = {
                ...pagination
            }

            if (formFilter.current) {
                let values = formFilter.current.getFieldsValue();
                if (values.date) {
                    values['start_time'] = values.date[0].format("YYYY-MM-DD HH:mm");
                    values['end_time'] = values.date[1].format("YYYY-MM-DD HH:mm");
                    delete values.date;
                }
                Object.keys(values).map((key) => {
                    if (values[key]) {
                        params[key] = values[key]
                    }
                })
            }

            let response = await fetchData({
                url: apis.app.face_id.log,
                params
            });

            setData({
                total: response.total,
                data: response.data
            })

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const insertFaceData = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.insert,
                method: "post",
                data: values
            });

            NotificationMessage({ type: "success", description: "Thao tác thành công!" });

            getListData();

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const removeFaceData = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.remove,
                method: "post",
                data: {
                    ids: values
                }
            });

            NotificationMessage({ type: "success", description: "Thao tác thành công!" });

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    useEffect(() => {
        getListData();
    }, [pagination])

    return (
        <div className="list-connect content-m">
            <WidgetFaceDataDetail
                {...visibleDetail}
                onCancel={() => setvisibleDetail({ visible: false })}
            />

            <div className="list-c">
                <div style={{ marginBottom: "15px" }} className="table-header">
                    <h3>Lịch sử face: {data.total}</h3>
                    <Form
                        ref={formFilter}
                        initialValues={{
                            date: [moment().startOf("day"), moment().endOf("day")]
                        }}
                        onValuesChange={(e) => {
                            let timeout = 0;
                            if (e.face_id) {
                                timeout = 300;
                            }
                            if (searchFilter.current) clearTimeout(searchFilter.current)
                            searchFilter.current = setTimeout(() => {
                                getListData()
                            }, timeout)
                        }}
                    >
                        <div className="row">
                            <Form.Item className='col-md-2' name="id">
                                <Input placeholder='Face Id | Guest Id' />
                            </Form.Item>
                            <Form.Item className='col-md-2' name="type">
                                <Select allowClear placeholder="Chọn phòng ban">
                                    {
                                        Object.keys(config.face_data.type).map((key) => {
                                            return <Select.Option value={key}>{config.face_data.type[key].label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item className='col-md-2' name="is_maskedon">
                                <Select allowClear placeholder="Lọc khẩu trang">
                                    <Select.Option value={1}>Có khẩu trang</Select.Option>
                                    <Select.Option value={-1}>Không khẩu trang</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item className="col-md-3" name="date">
                                <DatePicker.RangePicker
                                    locale={locale}
                                    format={'DD-MM-YYYY HH:mm'}
                                    ranges={{
                                        'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                        'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                        // 'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                        // 'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                        // 'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                        // 'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                    }}
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    showTime
                                    onOpenChange={(e) => {
                                        if (!e) getListData()
                                    }}
                                    onChange={(values) => {
                                        formFilter.current.setFieldsValue({
                                            start_time: values[0] ? values[0].format("YYYY-MM-DD HH:mm") : "",
                                            end_time: values[1] ? values[1].format("YYYY-MM-DD HH:mm") : ""
                                        })
                                    }}
                                    disabledDate={(date) => date > moment().add(0, "day")}
                                />
                            </Form.Item>
                            <div className="col-auto">
                                <Button type="primary" onClick={() => {
                                    formFilter.current.resetFields();
                                    getListData();
                                }}>Xóa lọc</Button>
                            </div>
                            <div className="col-auto" >
                                <Tooltip title={'Làm mới'}>
                                    <a className="btn btn-primary text-white mr-2" onClick={getListData}><i className="far fa-sync"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                    </Form>
                </div>
                <Table
                    dataSource={data.data}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Face Id",
                            dataIndex: "face_data",
                            render: (value) => value && value.face_id
                        },
                        {
                            title: "Guest Id",
                            dataIndex: "face_data",
                            render: (value) => value && value.guest_id
                        },
                        {
                            title: "Guest Id old",
                            dataIndex: "wrong_guest_ids",
                            render: (value) => {
                                return value && value.join("/n")
                            }
                        },
                        {
                            title: "Phòng ban",
                            dataIndex: "type",
                            render: (value) => config.face_data.type[value] ? config.face_data.type[value].label : value
                        },
                        {
                            title: "Ảnh",
                            dataIndex: "face_data",
                            render: (item) => {
                                return <React.Fragment>
                                    <Image src={ConvertImgBase64(item.images[item.images.length - 1])} width={100} preview={false} />
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Thời gian",
                            dataIndex: "face_data",
                            render: (item) => moment(item.timestamp).format('HH:mm:ss DD-MM-YYYY')
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                return <div>
                                    <Button size="small" type="primary"
                                        onClick={() => {
                                            setvisibleDetail({
                                                visible: true,
                                                faceId: item.face_data.face_id,
                                                faceData: item.face_data
                                            })
                                        }}
                                        disabled={item.face_data.face_id == -1 && item.face_data.guest_id == -1}
                                    >
                                        Hoạt động
                                    </Button>
                                    {
                                        item.face_data.face_id == -1 && item.face_data.is_satisfying_face && <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => {
                                                insertFaceData({
                                                    _id: item._id,
                                                    face_data: item.face_data
                                                })
                                            }}
                                            className="ml-2"
                                        >
                                            Thêm mới
                                        </Button>
                                    }

                                    {
                                        item.face_data.face_id != -1 && <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => {
                                                removeFaceData([item.face_data.face_id])
                                            }}
                                            className="ml-2"
                                            danger
                                        >
                                            Xóa Face
                                        </Button>
                                    }
                                </div>
                            }
                        }
                    ]}
                    rowKey="_id"
                    loading={loading}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, pageSize) => {
                            setpagination({
                                page: current,
                                limit: pageSize
                            })
                        },
                        current: pagination.page,
                        showSizeChanger: true,
                        position: ["topRight"],
                        className: "mt-0"
                    }}
                    scroll={{
                        x: true
                    }}
                />
            </div>
        </div>
    )
}

export default FaceDataView;
