import React, { Component } from 'react';
import { fetchData } from '../lib/apis';
import { Modal, Tabs, Spin, Pagination, Form, Input, Select } from 'antd';
import qs from 'qs';

export default class FormReplyProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      listDataProduct: [],
      total: 0,
      listCategoryProduct: [],
      tab: "product"
    }
    this.limit = 8;
    this.page = 1;
  }
  componentWillMount() {

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.visible && nextProps.visible != this.props.visible) {
      this.getListData(this.state.tab);
      this.getListCategoryProduct();
    }
  }
  getListData = async (e) => {
    try {
      this.setState({ loading: true });

      let params = {
        limit: this.limit,
        page: this.page
      }
      let url = 'api/v1/product/list';

      if (e == 'product') {
        if (this.formFilterProduct) params = { ...params, ...this.formFilterProduct.getFieldsValue() };
      }

      let res = await fetchData({
        url: url + `?${qs.stringify(params)}`
      });

      this.setState({
        loading: false,
        listDataProduct: res.data,
        total: res.total
      });

    } catch (err) {
      this.setState({ loading: false });
      this.props.notification({
        title: "Lấy dữ liệu",
        content: err.message || "Đã có lỗi xảy ra!",
        type: "success"
      })
    }
  }
  getListCategoryProduct = async (keyword = "") => {
    try {
      let res = await fetchData({
        url: `api/v1/product/cate/list?limit=8&name=${keyword}`
      });

      this.setState({ listCategoryProduct: res.data })
    } catch (err) {

    }
  }
  handleFilterData = ({ type, input }) => {
    this.page = 1;
    if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
      if (this.timeSearch) clearTimeout(this.timeSearch);
      this.timeSearch = setTimeout(() => {
        this.getListData('product');
      }, 300);
    } else {
      this.getListData('product');
    }
  }
  render() {
    return (
      <Modal
        title="Gửi sản phẩm"
        visible={this.props.visible}
        onCancel={this.props.hide}
        footer={null}
        width={650}
        className="modal-product"
      >
        <Spin spinning={this.state.loading}>
          <div className="row justify-content-between" style={{ marginBottom: "10px" }}>
            <div className="col-auto">
              <Form
                ref={(evt) => this.formFilterProduct = evt}
                initialValues={{
                }}
                onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
              >
                <div className="row">
                  <div className="col-6">
                    <Form.Item name="title" >
                      <Input placeholder="Tên sản phẩm.." />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item name="category_id" >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={false}
                        onSearch={(e) => {
                          if (this.timeout_fetchcate) clearTimeout(this.timeout_fetchcate);
                          this.timeout_fetchcate = setTimeout(() => {
                            this.getListCategoryProduct(e);
                          }, 300);
                        }}
                        onChange={(e) => this.handleFilterData({ type: 'select', input: e })}
                        style={{ width: "100%" }}
                        placeholder="Danh mục"
                        allowClear
                      >
                        {
                          this.state.listCategoryProduct.map((item) => {
                            return <Select.Option value={item._id} key={item._id}>{item.name}</Select.Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
            <div className="col-auto">
              <Pagination
                total={this.state.total}
                pageSize={this.limit}
                current={this.page}
                onChange={(current) => {
                  this.page = current;
                  this.getListData('product');
                }}
                simple
                size={"small"}
              />
            </div>
          </div>
          {
            this.state.listDataProduct.length > 0 ?
              <div className="row product" style={{ paddingBottom: "5px" }}>
                {
                  this.state.listDataProduct.map((item, i) => {
                    let picture = item.picture;
                    let attachment_images = item.attachments.filter((attachment) => attachment.type.indexOf('image') > -1);
                    if (!picture && attachment_images.length > 0) {
                      picture = attachment_images[Math.floor(Math.random() * attachment_images.length)]['url']
                    }
                    return <div className="col-md-3 col-6" key={i}>
                      <div className="item" onClick={() => {
                        this.props.selectReply({
                          content: item.description,
                          attachments: item.attachments
                        })
                      }}>
                        <div className="img">
                          <img src={picture ? picture : '/'} onError={(e) => e.target.src = require('../img/no_images.png')} />
                          {item.category ? <div className="category">{item.category.name}</div> : null}
                        </div>
                        <div className="content">
                          <div className="title">{item.title}</div>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div> : null
          }
        </Spin>
      </Modal>
    )
  }
}
