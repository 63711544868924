import React, { Component } from 'react';
import {Form, Input, Button, message} from 'antd'
import {fetchData} from '../../../lib/apis';
import {AccountMetaData, setCookie, dimens} from '../../../config/settings';
import { Link } from 'react-router-dom';

export default class CreateCompany extends Component{
  constructor(props){
    super(props);
    this.state = {
        confirmLoading: false
    }
    this.formH = null;
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
   
  }
  componentDidMount(){
    if(this.formH){
        this.formH.setFieldsValue({
            email: this.props.UserLogin.email,
            phone: this.props.UserLogin.phone,
            address: this.props.UserLogin.address
        })
    }
  }
  handleSubmit = async (values) => {
    try {
      this.setState({
        confirmLoading: true,
      }, () => {
        fetchData({
            url: 'api/v1/company/create',
            method: 'post',
            data: values
        }).then((res) => {
            setCookie(dimens.name_company, JSON.stringify(res.data), 7);
            message.success({content: 'Thao tác thành công', duration: 3})
            this.setState({
                confirmLoading: false
            }, () => {
                if (this.formH) this.formH.resetFields();
                window.location.href = "/";
            });
        }).catch((err) => {
            this.setState({
                confirmLoading: false,
            }, () => {
                let content = err.message ? err.message : 'Đã có lỗi xảy ra vui lòng thử lại sau!';
                message.warning({ content: content, key: 'error_api', duration: 2 });
            })
        })
      });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  onFinishFailed = async (error) => {
    console.log(error);
  }
  render(){
    return (
        <div className="content-m">
            <div className="list-c">
                <div className="form-company">
                    <Form
                        name="basic"
                        onFinish={this.handleSubmit}
                        onFinishFailed={this.onFinishFailed}
                        ref={(evt) => this.formH = evt}
                    >
                        <div className="logo">
                            <img src={require('../../../img/background.png')}/>
                            <h2>Tạo chi nhánh của bạn</h2>
                        </div>
                        <Form.Item
                            name="company_name"
                            rules={[{ required: true, message: 'Tên chi nhánh không được để trống' }]}
                        >
                            <Input placeholder="Tên chi nhánh *" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            name="phone"
                        >
                            <Input placeholder="Số điện thoại" />
                        </Form.Item>
                        <Form.Item
                            name="address"
                        >
                            <Input placeholder="Địa chỉ" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" loading={this.state.confirmLoading} htmlType="submit">
                            Hoàn tất
                            </Button>
                            <Link to="/company/join" style={{marginLeft: "10px", fontWeight: "500"}}>Tham gia chi nhánh khác</Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
  }
}
