import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Form, Input, Tooltip, Select, message } from "antd";

import qs from "qs";

import moment from "moment";
import cx from "classnames";

const listStatus = {
    "-1": "Tạm dừng",
    "0": "Đang xử lý",
    "2": "Thành công",
    "3": "Thất bại"
}

const LogAutoSms = (props) => {
    const [data, setData] = useState({
        total: 0,
        listData: []
    });
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    });

    const formFilter = useRef();

    useEffect(() => {
        getListData();
    }, [pagination])

    // request get mẫu
    const getListData = async () => {
        try {
            let id = props.match.params.id;

            setLoading(true);

            let params = {
                limit: pagination.limit,
                page: pagination.page
            }

            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() }

            let res = await props.fetchData({
                url: `api/v1/auto_sms/${id}/log?${qs.stringify(params)}`
            });

            setLoading(false);

            setData({
                total: res.data.total,
                listData: res.data.data
            });

        } catch (err) {
            setLoading(false);
            props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    return <React.Fragment>
        <div className="list-connect content-m">
            <div className="list-c">
                <div>
                    <h3>Tiến trình Sms: {data.total}</h3>
                </div>
                <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                    <Tooltip title={'Quay lại'}>
                        <a className="btn btn-primary text-white mr-2" onClick={() => props.history.goBack()}><i className="far fa-arrow-left"></i></a>
                    </Tooltip>
                    <Tooltip title={'Làm mới'}>
                        <a className="btn btn-primary text-white mr-2" onClick={() => getListData()}><i className="far fa-sync"></i></a>
                    </Tooltip>
                </div>
                <Form
                    initialValues={{

                    }}
                    ref={formFilter}
                    onValuesChange={() => {
                        getListData();
                    }}
                >
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Item name="keyword">
                                <Input placeholder="Nhập số khách hàng.." />
                            </Form.Item>
                        </div>
                        <div className="col-md-2">
                            <Form.Item name="status">
                                <Select placeholder="Trạng thái">
                                    {
                                        Object.keys(listStatus).map((key) => {
                                            return <Select.Option value={key}>{listStatus[key]}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="col-auto">
                            <Button type="primary" onClick={() => {
                                formFilter.current.resetFields();
                                getListData();
                            }}>Xóa lọc</Button>
                        </div>
                    </div>
                </Form>
                <Table
                    columns={[
                        {
                            title: "#",
                            render: (value, item, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        {
                            title: "Khách hàng",
                            dataIndex: "user_id"
                        },
                        {
                            title: "Nội dung",
                            dataIndex: "reply",
                            render: (item) => {
                                return <Input.TextArea value={item.content} />
                            }
                        },
                        {
                            title: "Trạng thái",
                            dataIndex: "status",
                            render: (item) => {
                                return <span className={item == 2 ? "text-success" : item == 3 ? "text-danger" : ""}>{listStatus[item]}</span>
                            }
                        },
                        {
                            title: "Ngày tạo",
                            dataIndex: "created_time",
                            render: (item) => moment(item).format("HH:mm DD-MM-YYYY")
                        },
                        {
                            title: "Ngày gửi",
                            dataIndex: "time_success",
                            render: (item) => item && moment(item).format("HH:mm DD-MM-YYYY")
                        },
                        {
                            title: "Log",
                            dataIndex: "log",
                            render: (item) => {
                                return <Input.TextArea value={item} />
                            }
                        },
                    ]}
                    dataSource={data.listData}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, size) => {
                            pagination.page = current;
                            pagination.limit = size;

                            setPagination({ ...pagination });
                        },
                        current: pagination.page,
                        showSizeChanger: false
                    }}
                    rowKey="_id"
                    loading={loading}
                />
            </div>
        </div>
    </React.Fragment>
}

export default LogAutoSms;