import React, { useEffect, useState, useMemo, useRef } from "react";
import { Empty, Modal, Spin, message, Steps, Divider, Image, Form, DatePicker, Select, Button } from "antd";
import moment from "moment";
import locale from 'antd/es/date-picker/locale/vi_VN';

import { apis, fetchData } from "../../../../lib/apis";
import config from "../../../../lib/config";
import { ConvertImgBase64 } from "../../../../lib/helpers";

const WidgetFaceDataDetail = (props) => {
    const { visible, onCancel, faceData } = props;

    const [loading, setloading] = useState(false);
    const [data, setData] = useState({
        data: [],
        total: 0
    });

    const refForm = useRef();

    useEffect(() => {
        if (visible) {
            if(refForm.current && faceData){
                refForm.current.setFieldsValue({
                    date: moment(faceData.timestamp)
                })
            }
            getData();
        } else{
            setData({
                data: [],
                total: 0
            })
        }
    }, [visible])

    const getData = async () => {
        try {
            setloading(true);
            let params = {
                face_id: faceData.face_id,
                guest_id: faceData.guest_id,
                limit: 0,
                start_time: moment().startOf("day").format("YYYY-MM-DD HH:mm"),
                end_time: moment().endOf("day").format("YYYY-MM-DD HH:mm")
            }

            if (refForm.current) {
                let values = refForm.current.getFieldsValue();
                if (values.date) {
                    params['start_time'] = values.date.startOf("day").format("YYYY-MM-DD HH:mm");
                    params['end_time'] = values.date.endOf("day").format("YYYY-MM-DD HH:mm");
                }
                params['type'] = values.type || "";
            }

            let response = await fetchData({
                url: apis.app.face_id.log,
                params
            })
            setData(response);
        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const dataLog = useMemo(() => {
        let result = [];

        data.data.sort((a, b) => b.face_data.timestamp - a.face_data.timestamp).forEach((item, i) => {
            if (data.data[i - 1] && data.data[i - 1].type == item.type) {
                result[result.length - 1].data.push(item.face_data)
            } else {
                result.push({
                    title: item.type,
                    data: [item.face_data]
                })
            }
        })

        return result;
    }, [data])

    const faceId = useMemo(() => {
        if(faceData) {
            return faceData.face_id > -1 ? faceData.face_id : `G - ${faceData.guest_id}`
        }
    }, [ faceData ])

    return <Modal
        title={`Lịch sử hoạt động ${faceId}`}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        width={600}
    >
        <Spin spinning={loading}>
            <div style={{ minHeight: "160px" }}>
                <Form
                    initialValues={{
                        date: moment()
                    }}
                    onValuesChange={() => {
                        getData();
                    }}
                    ref={refForm}
                    layout="inline"
                    onReset={() => getData()}
                    
                >
                    <Form.Item name="date">
                        <DatePicker
                            format={"DD-MM-YYYY"}
                            allowClear={false}
                            locale={locale}
                        />
                    </Form.Item>
                    <Form.Item name="type" style={{ minWidth: "160px" }}>
                        <Select allowClear placeholder="Chọn phòng ban">
                            {
                                Object.keys(config.face_data.type).map((key) => {
                                    return <Select.Option value={key}>{config.face_data.type[key].label}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset">Làm mới</Button>
                    </Form.Item>
                </Form>
                <Divider>Tổng lần CheckIn: {data.total}</Divider>
                {
                    dataLog.length ? <>
                        <Steps
                            progressDot
                            current={dataLog.length - 1}
                            direction="vertical"
                            items={dataLog.map((item) => {
                                return {
                                    title: <>
                                        {config.face_data.type[item.title] ? config.face_data.type[item.title].label : item.title} -
                                        {moment(item.data[0].timestamp).format("HH:mm DD/MM")}
                                    </>,
                                    description: <>
                                        <div className="log-face-data">
                                            {
                                                item.data.sort((a, b) => a.timestamp - b.timestamp).map((face_data) => {
                                                    return <div className="log-face-data-item"
                                                        style={faceData && faceData.timestamp == face_data.timestamp ? { border: "4px solid #1890ff" } : {}}
                                                    >
                                                        <Image src={ConvertImgBase64(face_data.images[face_data.images.length - 1])} preview={false} />
                                                        <span className="timestamp">{moment(face_data.timestamp).format("HH:mm:ss")}</span>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </>
                                }
                            })}
                        />
                    </> : <Empty description="Chưa có dữ liệu" />
                }
            </div>
        </Spin>
    </Modal>
}

export default WidgetFaceDataDetail;