import React, { Component, useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Upload, Alert, notification, Tooltip, Spin, Select, TreeSelect, DatePicker } from 'antd';
import { UploadOutlined, InboxOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { fetchData } from "../../lib/apis";

import moment from "moment";

import WidgetFormBooking from "./formBooking";

const Booking = (props) => {
    const { settingSystem, companySelected, UserLogin, visible } = props;

    const [message, handleMessage] = useState();
    const [loading, handleLoading] = useState(false);
    const [reload, setReload] = useState(false);

    const onSubmit = async (values) => {
        try {
            handleLoading(true);

            if (!values['booking_time']) throw ({ message: "Vui lòng chọn thời gian lịch hẹn!" });

            let repsonse = await fetchData({
                url: "api/v1/booking/web",
                data: values,
                method: "post"
            })

            handleLoading(false);

            handleMessage({
                text: "Đặt lịch hẹn thành công",
                type: "success"
            })

            props.onFinish();

        } catch (err) {
            handleLoading(false);
            handleMessage({
                text: err.message || "Đã có lỗi xảy ra",
                type: "error"
            })
        }
    }

    useEffect(() => {
        if(!props.visible) setReload(!reload);
    }, [props.visible]);

    return <Modal
        title={'Đặt lịch hẹn'}
        visible={props.visible}
        onCancel={props.hide}
        footer={null}
        className="ant-modal-20"
        centered
    >
        {
            message ? <Alert message={message.text} type={message.type} className="mb-3" /> : null
        }
        <WidgetFormBooking
            initialValues={{
                address_code: companySelected.company_address ? companySelected.company_address.code : "",
                address_name: companySelected.company_address ? companySelected.company_address.name : "",
                source: "www",
                ref: decodeURIComponent(UserLogin.id_callback),
                date_booking: moment().add("day", 1),
                type: 3
            }}
            onFinish={onSubmit}
            loading={loading}
            reload={reload}
            onClose={props.hide}
            hoursBooking={companySelected.thoi_gian_kham_benh}
            companyId={companySelected.company_id}
        />
    </Modal>
}

export default Booking;
