import React, { Component } from 'react';
import ItemChannel from './item-channel';
import {Button} from 'antd';

export default class ListChannel extends Component{
  constructor(props){
    super(props);
    let role_allow_all_acc_type = [];
    let role_allow_chat_id = [];
    if(this.props.staffSelected){
        role_allow_all_acc_type = this.props.staffSelected.role_allow_all_acc_type;
        role_allow_chat_id = this.props.staffSelected.role_allow_chat_id;
    }
    this.state = {
        role_allow_all_acc_type: role_allow_all_acc_type,
        role_allow_chat_id: role_allow_chat_id
    }
    this.changeRoleAcc = this.changeRoleAcc.bind(this);
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
    if(nextProps.staffSelected){
        console.log(nextProps.staffSelected);
        this.setState({
            role_allow_all_acc_type: nextProps.staffSelected.role_allow_all_acc_type,
            role_allow_chat_id: nextProps.staffSelected.role_allow_chat_id
        })
    }
  }
  componentDidMount(){
    
  }
  changeRoleAcc = (data = {}) => {
    let role_new = data['data'];
    let role_old = this.state.role_allow_all_acc_type;
    if(data['type'] != 'acc_type'){
        role_old = this.state.role_allow_chat_id;
    }
    let data_new = [];
    let change_new = true;
    role_old.forEach((role) => {
        if (data['type'] == 'acc_type' && role.acc_type == role_new.acc_type) {
            data_new.push(role_new);
            change_new = false;
        } else if(data['type'] != 'acc_type' && role.acc_type == role_new.acc_type && role.id_acc_chat == role_new.id_acc_chat){
            data_new.push(role_new);
            change_new = false;
        } else {
            data_new.push(role);
        }
    })
    if (change_new) {
        data_new.push(role_new);
    }
    let data_state = {};
    if(data['type'] == 'acc_type'){
        data_state = {
            role_allow_all_acc_type: data_new
        }
    } else {
        data_state = {
            role_allow_chat_id: data_new
        }
    }
    this.setState(data_state, () => {

    })
  }
  updateRoleStaff = () => {
      if(this.props.updateRoleStaff){
          let staff = {
              ...this.props.staffSelected
          };
          staff['role_allow_all_acc_type'] = this.state.role_allow_all_acc_type;
          staff['role_allow_chat_id'] = this.state.role_allow_chat_id;

          this.props.updateRoleStaff(staff);
      }
  }
  resetRole = () => {
    let staff = {
        ...this.props.staffSelected
    };
    this.setState({
        role_allow_all_acc_type: staff['role_allow_all_acc_type'],
        role_allow_chat_id: staff['role_allow_chat_id']
    })
  }
  render(){
    // console.log(this.props.staffSelected);
    let listChannels = [];
    this.props.listRoleChat.forEach((acc_type, i) => {
        listChannels.push(<ItemChannel key={i} 
            accType={acc_type} type={"acc_type"} 
            changeRoleAcc={this.changeRoleAcc}
            role_allow_all_acc_type={this.state.role_allow_all_acc_type}
            staffSelected={this.props.staffSelected}
        />);
        if(acc_type.data){
            acc_type.data.forEach((channel, ii) => {
                listChannels.push(<ItemChannel key={`child_${ii}_${i}`} 
                accType={acc_type} channel={channel} 
                role_allow_chat_id={this.state.role_allow_chat_id}
                role_allow_all_acc_type={this.state.role_allow_all_acc_type}
                changeRoleAcc={this.changeRoleAcc}
                staffSelected={this.props.staffSelected}
                />);
            })
        }
    })
    return (
        <div className="list-role">
            <table>
                <thead>
                    <tr>
                        <th>Trang</th>
                        <th className="text-center">Xem tất cả</th>
                        <th className="text-center">Tin nhắn</th>
                        <th className="text-center">Bình luận</th>
                    </tr>
                </thead>
                <tbody>
                    {listChannels}
                </tbody>
            </table>
            <div className="button-fixed">
                <Button type="primary" onClick={this.updateRoleStaff}>Lưu thay đổi</Button>
                <Button type="default" style={{marginLeft: "10px"}} onClick={this.resetRole}>Hủy bỏ</Button>
            </div>
        </div>
    )
  }
}
