import React, { Component } from 'react';

export default class ListOwner1 extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
   
  }
  componentDidMount(){
    
  }
  render(){
    return (
        <div className="list-owner-top">
            <div className="owner-avatar">
                <img className="avatar" src="/static/media/user.a04d849c.jpg" />
            </div>
            <div className="owner-avatar">
                <img className="avatar" src="/static/media/user.a04d849c.jpg" />
            </div>
            <div className="owner-avatar">
                <div className="lettertile dark-color1"><div>1</div></div>
            </div>
            <div className="owner-avatar">
                <img className="avatar" src="/static/media/user.a04d849c.jpg" />
            </div>
            
        </div>
    )
  }
}
