import React, { Component } from 'react';
import { Modal, Form, Input, Button} from 'antd';


export default class FormEditInfo extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentWillReceiveProps(nextProps) {
      if(nextProps.visible && !this.props.visible && nextProps.item && this.form){
         this.form.setFieldsValue({
            full_name_other: nextProps.item.from.full_name_other || nextProps.item.from.full_name,
            phone: nextProps.item.from.phone || nextProps.item.from.phone2,
            email: nextProps.item.from.email,
            address: nextProps.item.from.address
         })
      }
  }

  save = async (values) => {
      try{
        this.setState({
            loading: true,
            error: null
        })
        let contact = this.props.item;

        await this.props.fetchData({
            url: `api/v1/contact/user/${contact.contact_id}`,
            method: 'put',
            data: values
        })
        contact['from'] = {
            ...contact['from'],
            ...values
        };

        this.props.updateContactSelected(contact);
        this.props.notification({
            title: "Sửa thông tin khách hàng",
            content: "Thao tác thành công",
            type: "success"
        })
        this.setState({
            loading: false
        })
      } catch(err){
          this.setState({
              loading: false,
              error: err.message || "Thao tác không thành công"
          })
      }
  }

  render(){
    return <Modal
        title={'Sửa thông tin khách hàng'}
        visible={this.props.visible}
        onCancel={this.props.hide}
        footer={null}
    >
        <Form
            name="basic"
            layout="vertical"
            ref={(evt) => this.form = evt}
            initialValues={{
                full_name_other: this.props.item ? this.props.item.from.full_name_other || this.props.item.from.full_name : "",
                phone: this.props.item ? this.props.item.from.phone || this.props.item.from.phone2 : "",
                email: this.props.item ? this.props.item.from.email : "",
                address: this.props.item ? this.props.item.from.address : ""
            }}
            onFinish={this.save}
        >
            {
                this.state.error ?
                <div><p className="alert alert-danger">{this.state.error}</p></div> : null
            }
            <Form.Item name="full_name_other" label="Tên khách hàng">
                <Input />
            </Form.Item>
            {
                this.props.item && this.props.item.type != "callcenter" ?
                <Form.Item name="phone" label="Số điện thoại">
                    <Input />
                </Form.Item> : null   
            }
            <Form.Item name="email" label="Email">
                <Input />
            </Form.Item>
            <Form.Item name="address" label="Địa chỉ">
                <Input />
            </Form.Item>
            <div style={{ textAlign: "right" }} className="mt-4">
                <Button onClick={this.props.hide} style={{ marginRight: "10px" }}>
                    Đóng
                </Button>
                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                    Cập nhập
                </Button>
            </div>
        </Form>
    </Modal>
  }
}
