import React, { useMemo, useRef, useState } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert, Select } from 'antd';
import { fetchData } from '../../../../lib/apis';
import moment from 'moment';

import { useEffect } from 'react';

const DetailView = (props) => {
    const [data, setData] = useState([])
    const [loading, setloading] = useState(false);
    const [ detailResult, setdetailResult ] = useState(null);

    const getListData = async () => {
        try {
            setloading(true);

            let response = await fetchData({
                url: `api/v1/phong-chuc-nang/chi-tiet`,
                params: {
                    ma_kham_benh: props.id
                }
            });

            setData(response.data)

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const postCall = async (id) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: `api/v1/phong-chuc-nang/${id}/goi-benh-nhan`
            });

            getListData();

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const info = useMemo(() => {
        return data.length > 0 ? data[0] : null
    }, [ data ])

    const detailData = useMemo(() => {
        let result = {
            items: []
        };
        if(detailResult){
            result = {
                ...detailResult.data[detailResult.index],
                items: Object.keys(detailResult.data[detailResult.index]['chi_tiet']).map((key) => ({
                    label: key,
                    ...detailResult.data[detailResult.index]['chi_tiet'][key]
                }))
            }
        }
        return result
    }, [ detailResult ])

    useEffect(() => {
        if (props.id && props.visible) {
            setdetailResult(null);
            getListData();
        }
    }, [props.id, props.visible])

    return (
        <Modal
            title={"Chi tiết"}
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
            width={1200}
        >
            <div className='mb-2'>
                <p>Bệnh nhân: <strong>{info && info.thong_tin.ho_ten} {info ? `- ${info.ma_ho_so}` : ""}</strong></p>
                <p>Ngày sinh: <strong>{info && info.thong_tin.ngay_sinh}</strong></p>
            </div>
            <div className="mb-3">
            {
                detailResult ? <>
                    <div className='d-flex justify-content-between bg-light mb-3 align-items-center p-2'>
                        <div>
                            <strong>Kết quả: {detailResult.name} ({detailResult.data.length})</strong>
                        </div>
                        <Button size='small'
                            onClick={() => setdetailResult(null)}
                        ><i className="far fa-arrow-left mr-2"></i> Quay lại</Button>
                    </div>
                    <div className="mb-3">
                        <Select style={{ width: "200px" }} value={detailResult.index} onChange={(e) => setdetailResult({...detailResult, index: e })}>
                            {
                                detailResult.data.map((item, i) => {
                                    return <Select.Option value={i}>
                                        Kết quả {i + 1} ({item.nhan_vien})
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className='mb-3'>
                        <p>Bác sỹ: {detailData.bac_sy}</p>
                        <p>Phòng: {detailData.phong_thuc_hien}</p>
                    </div>
                    <Table 
                        dataSource={detailData.items}
                        columns={[
                            {
                                title: "#",
                                dataIndex: "label"
                            },
                            {
                                title: "Mắt trái",
                                dataIndex: "Mắt trái"
                            },
                            {
                                title: "Mắt phải",
                                dataIndex: "Mắt phải"
                            }
                        ]}
                        pagination={false}
                    />
                </>
                : <>
                    <Table
                        dataSource={data}
                        columns={[
                            {
                                title: "#",
                                index: "#",
                                render: (item, value, index) => {
                                    return index + 1
                                }
                            },
                            {
                                title: "Dịch vụ",
                                dataIndex: "dich_vu",
                                render: (item) => item.ten
                            },
                            {
                                title: "Trạng thái",
                                dataIndex: "status",
                                render: (item) => {
                                    return <div className={`${item == 0 ? "" : "text-success"}`}>
                                        {item == 0 ? "Đang chờ" : "Đã gọi"}
                                    </div>
                                }
                            },
                            {
                                title: "Kết quả",
                                dataIndex: "ket_qua",
                                align: "center",
                                render: (item, record) => {
                                    return <div>
                                        {
                                            !item.length ? "Chưa có dữ liệu" : 
                                            <Button type="link" onClick={() => setdetailResult({
                                                name: record.dich_vu.ten,
                                                data: item,
                                                index: 0
                                            })}>
                                                {item.length} dữ liệu
                                            </Button>
                                        }
                                    </div>
                                }
                            },
                            {
                                title: "Thời gian gọi",
                                render: (item) => item.action_time ? moment(item.action_time).format('HH:mm DD-MM-YYYY') : null
                            },
                            {
                                title: "Thời gian",
                                render: (item) => moment(item.created_time).format('HH:mm DD-MM-YYYY')
                            },
                            {
                                title: "Hành động",
                                className: "text-right",
                                render: (item) => {
                                    return <div>
                                        <Button size="small" type="primary" 
                                            onClick={() => {
                                                postCall(item._id)
                                            }}
                                            disabled={item.created_time < moment().startOf("day").unix() * 1000 || item.status != 0}
                                        >
                                            Gọi bệnh nhân
                                        </Button>
                                    </div>
                                }
                            }
                        ]}
                        rowKey="_id"
                        loading={loading}
                        pagination={false}
                        scroll={{
                            x: true
                        }}
                    />
                </>
            }
            </div>
        </Modal>
    )
}

export default DetailView;
