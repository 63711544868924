import React, { useMemo, useRef, useState, useEffect } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert, Select } from 'antd';
import { fetchData } from '../../../../lib/apis';
import { WidgetSelectSpinText } from "../../../../widgets";

import moment from 'moment';

const FormText = (props) => {
    const [loading, setloading] = useState(false);
    const [ visibleSpinText, setvisibleSpinText ] = useState(false);

    const formRef = useRef();

    const onFinish = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: `api/v1/phong-chuc-nang/text`,
                method: "put",
                data: values
            });

            message.success("Thao tác thành công");

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    useEffect(() => {
        if(props.visible){
            try{
                formRef.current.setFieldsValue({
                    text_pcn: props.company.extra_data.text_pcn
                })
            } catch(err){

            }
        }
    }, [props.visible, props.company])

    return (
        <Modal
            title={"Chi tiết"}
            visible={props.visible}
            onCancel={props.onCancel}
            footer={null}
            width={600}
        >
            <Form
                layout="vertical"
                onFinish={onFinish}
                ref={formRef}
            >
                <Form.Item name="text_pcn">
                    <Input.TextArea rows={5}></Input.TextArea>
                </Form.Item>
                <WidgetSelectSpinText
                    data={[
                        {
                            value: "#{HO_TEN}",
                            text: "Tên bệnh nhân"
                        },
                        {
                            value: "#{DICH_VU}",
                            text: "Dịch vụ"
                        },
                        {
                            value: "#{NGAY_SINH}",
                            text: "Ngày sinh"
                        },
                        {
                            value: "#{MA_KHAM_BENH}",
                            text: "Mã khám bệnh"
                        },
                        {
                            value: "#{Spin_1|Spin_2}",
                            text: "Chọn từ khóa ngẫu nhiên"
                        }
                    ]}
                    visible={visibleSpinText}
                    handleVisible={() => setvisibleSpinText(!visibleSpinText)}
                    setValues={(value) => {
                        formRef.current.setFieldsValue({ text_pcn: formRef.current.getFieldValue('text_pcn') + value })
                    }}
                    numShow={3}
                />
                <Form.Item className='text-right'>
                    <Button htmlType="submit" type='primary' loading={loading} >Cập nhập</Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default FormText;
