import React, { useEffect, useState } from "react";

import { Carousel } from "antd";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { ItemTag
} from '../../../../widgets';

import Slider from "react-slick";

const ListTag = (props) => {
    const { tags } = props;

    const [parent, handleParent] = useState(0);
    const [listTags, hanleListTag] = useState([]);
    
    let is_state = false;

    useEffect(() => {
        console.log('parent', parent);
        let data = tags.filter((item) => item.parent_id === parent);
        if(data.length == 0 && parent != 0){
            handleParent(0);
        } else {
            hanleListTag(data)   
        }
    }, [parent])

    if(!props.contactInfoSelected) return null;

    return <div className="list-tags">
        {
            parent != 0 && listTags.length > 0 ? <div className="btn-tag-prev" onClick={() => handleParent(0)}><i class="far fa-arrow-left"></i></div> : null
        }
        <Slider
            dots={false}
            slidesToShow={listTags.length < 4 ? listTags.length : 4}
            slidesToScroll={listTags.length < 4 ? listTags.length : 4}
            infinite={true}
            speed={200}
            // touchMove={false}
            arrows={true}
            // nextArrow={<div><i class="far fa-arrow-right"></i></div>}
        >
            { listTags.map((item) => {
                return <ItemTag
                    key={item.tag_id}
                    active={props.contactInfoSelected.tags && props.contactInfoSelected.tags.find((tag) => tag.tag_id == item.tag_id) ? true : false}
                    tag={item}
                    contactInfoSelected={props.contactInfoSelected}
                    handleUpdateTag={props.handleUpdateTag}
                    handleParent={(value) => handleParent(value)}
                />
            })}
        </Slider>
    </div>
}

export default ListTag;