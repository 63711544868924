import React, { Component } from 'react';

export default class ItemStaff extends Component{
  constructor(props){
    super(props);
    this.state = {
        staffSelected: this.props.staffSelected
    }
    this.handleSelectedStaff = this.handleSelectedStaff.bind(this);
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
      if(nextProps.staffSelected){
        this.setState({
            staffSelected: nextProps.staffSelected
        })
      }
    
  }
  componentDidMount(){
    
  }
  handleSelectedStaff(){
    if(this.props.handleSelectedStaff){
        this.props.handleSelectedStaff(this.props.staff);
    }
  }
  render(){
    let title = this.props.staff.user_name_vnp_sw || this.props.staff.id_user_vnp_sw;
    return (
        <li className={this.state.staffSelected && this.state.staffSelected.id_user_vnp_sw == this.props.staff.id_user_vnp_sw ? 'active' : ''} 
            onClick={() => this.handleSelectedStaff()}>
              <h3>{this.props.staff.email_vnp_sw || 'Không xác định'}</h3>
              <p>{title}</p>
        </li>
    )
  }
}
