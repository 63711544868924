import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';
import { fetchData } from '../../../lib/apis';
import { ExclamationCircleOutlined, UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined, LockOutlined, ReloadOutlined } from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';
import NumberFormat from 'react-number-format';

import FileSaver from 'file-saver';
import XLSX from 'xlsx';

import { list_year, list_trieuchung } from "../../khaibaoyte/data";

import RevenueComponent from "./components/revenue";
import InteractiveComponent from "./components/interactive";
import InteractiveDetailComponent from "./components/interactive_detail";
import RatingComponent from "./components/rating";
import TagComponent from "./components/tag";
import KhamBenhComponent from "./components/khambenh";
import DichTeComponent from "./components/dichte";
import CareBookingComponent from "./components/care_booking";
import FeedbackComponent from "./components/feedback";

export default class StatisticView extends Component {
    constructor(props) {
        super(props);
        let tabActive = "";
        let title = "Báo cáo"

        switch (props.match.params.type) {
            case "doanh-thu":
                tabActive = "statistic_revenue";
                title = "Báo cáo doanh thu";
                break;
            case "tuong-tac-tong-hop":
                tabActive = "statistic_interactive";
                title = "Báo cáo tương tác tổng hợp";
                break;
            case "tuong-tac-chi-tiet":
                tabActive = "statistic_interactive_detail";
                title = "Báo cáo tương tác chi tiết";
                break;
            case "khao-sat":
                tabActive = "statistic_rating";
                title = "Báo cáo khảo sát";
                break;
            case "nhan":
                tabActive = "statistic_tag";
                title = "Báo cáo nhãn";
                break;
            case "kham-benh":
                tabActive = "statistic_khambenh";
                title = "Báo cáo khám bệnh";
                break;
            case "dich-te":
                tabActive = "statistic_dichte";
                title = "Báo cáo dịch tễ";
                break;
            case "cham-soc-lich-hen":
                tabActive = "statistic_care_booking";
                title = "Báo cáo chăm sóc lịch hẹn";
                break;
            case "khieu-nai":
                tabActive = "statistic_feedback";
                title = "Báo cáo góp ý";
                break;
            default:

        }

        this.state = {
            listData: [],
            loading: false,
            listCompany: [],
            listStaff: [],
            tabActive: tabActive,
            listService: [],
            listTag: [],
            listDoctor: [],
            listProvince: [],
            title: title
        }
    }
    componentWillMount() {
        this.getListCompany();
        this.getListService();
        this.getListProvince();
        this.getListDoctor();
        this.getListTag();
    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {
        this.getListData();
    }
    getListData = async () => {
        try {
            let type = this.state.tabActive;

            this.setState({ loading: true });
            let params = {
                time_start: moment().add(-7, 'day').format('YYYY-MM-DD'),
                time_end: moment().format('YYYY-MM-DD')
            }
            if (this.formFilter) {
                params = {
                    ...params,
                    ...this.formFilter.getFieldsValue()
                };
            }
            let url = "";
            if (type == "statistic_revenue") {
                url = `api/v1/statistic/revenue`;
            } else if (type == "statistic_interactive_detail") {
                url = `api/v1/statistic/interactive`;
            } else if (type == "statistic_interactive") {
                url = `api/v1/statistic/interactive/aggregation`;
            } else if (type == "statistic_rating") {
                url = `api/v1/statistic/rating`;
            } else if (type == "statistic_tag") {
                url = `api/v1/statistic/tag`;
            } else if (type == "statistic_khambenh") {
                url = `api/v1/statistic/khambenh`;
            } else if (type == "statistic_dichte") {
                url = `api/v1/statistic/dichte`;
            } else if (type == "statistic_care_booking") {
                url = `api/v1/statistic/care_booking`;
            } else if (type == "statistic_feedback") {
                url = `api/v1/statistic/feedback`;
            }

            if (params['time']) {
                params['time_start'] = params['time'][0].format('YYYY-MM-DD');
                params['time_end'] = params['time'][1].format('YYYY-MM-DD');
                delete params['time'];
            }

            let res = await fetchData({
                url: url + `?${qs.stringify(params)}`
            });

            this.setState({
                listData: res.data,
                loading: false
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    exportToCSV = (options = {}) => {
        let { dom } = options;

        var fileName = prompt("Nhập tên file", "file-export");
        if (fileName == null || fileName == "") {
            return
        }

        let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let fileExtension = '.xlsx';

        let ws = XLSX.utils.table_to_sheet(dom, {
            raw: true,
            type: 'string'
        });

        let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        let data = new Blob([excelBuffer], { type: fileType });
        setTimeout(() => {
            FileSaver.saveAs(data, fileName + fileExtension);
        }, 1000)

    }


    getListCompany = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/statistic/company`
            });
            this.setState({
                listCompany: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }
    getListService = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/company/service/list`
            });
            this.setState({
                listService: this.parseFolder(res.data)
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListTag = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/company/tag/list`
            });
            this.setState({
                listTag: this.parseFolderTag(res.data)
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    parseFolder = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].service_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.name;
            node['key'] = node.service_id;
            node['value'] = node.service_id;
            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    parseFolderTag = (list) => {
        var map = {}, node, i;
        var roots = []

        for (i = 0; i < list.length; i++) {
            map[list[i].tag_id] = i;
            list[i].children = [];
        }

        for (i = 0; i < list.length; i++) {
            node = list[i];
            node['title'] = node.tag_name;
            node['key'] = node.tag_id;
            node['value'] = node.tag_id;
            if (node.parent_id !== 0) {
                try {
                    list[map[node.parent_id]].children.push(node);
                } catch (err) {
                    // console.log(node);
                }
            } else {
                roots.push(node);
            }
        }
        return roots;
    }

    getListStaff = async (company_id) => {
        try {
            this.setState({
                loadingGetStaff: true
            })
            let params = {
                company_id: company_id
            }

            if (this.state.tabActive == "statistic_care_booking") {
                params['role_action'] = 'care_booking';
            }

            let res = await fetchData({
                url: `api/v1/statistic/staff?${qs.stringify(params)}`
            });
            this.setState({
                listStaff: res.data,
                loadingGetStaff: false
            })
        } catch (err) {
            this.setState({
                loadingGetStaff: false
            })
        }
    }

    getListProvince = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/geo/list`
            });
            this.setState({
                listProvince: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    getListDoctor = async () => {
        try {
            let res = await fetchData({
                url: `api/v1/company/service/list?type=LIST_BS&parent_id=-1`
            });
            this.setState({
                listDoctor: res.data
            })
        } catch (err) {
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }

    render() {
        return (
            <div className="list-connect content-m d-flex">
                <div className="list-c">
                    <div style={{ marginBottom: "10px", marginTop: "15px" }} className="table-header">
                        <h3 className="mb-4">{this.state.title}</h3>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                time: [moment().add(-7, 'day'), moment()],
                                company_id: "",
                                sale_id: "",
                                cus_type: "",
                                is_cus_new: "",
                                birthday: "",
                                province: "",
                                doctor: "",
                                vung_dich: "",
                                tiem_vaccince: ""
                            }}
                            onValuesChange={(e) => {
                                let timout = 0;
                                if (e.fullname || e.phone || e.booking_code) {
                                    timout = 300;
                                }
                                if (this.timeout_getdata) clearTimeout(this.timeout_getdata);

                                this.timeout_getdata = setTimeout(() => {
                                    this.getListData()
                                }, timout)
                            }}
                        >
                            <div className="row">
                                <Form.Item name="company_id" className="col-md-2">
                                    <Select onChange={(e) => {
                                        this.getListStaff(e);
                                    }}
                                        allowClear
                                    >
                                        <Select.Option value="">Chi nhánh</Select.Option>
                                        {
                                            this.state.listCompany.map((item) => {
                                                return <Select.Option key={item.company_id} value={item.company_id}>{item.company_name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {
                                    ["statistic_revenue", "statistic_interactive", "statistic_interactive_detail", "statistic_tag", "statistic_care_booking"].indexOf(this.state.tabActive) > -1 ?
                                        <Form.Item name="sale_id" className="col-md-2">
                                            <Select
                                                loading={this.state.loadingGetStaff}
                                                allowClear
                                            >
                                                <Select.Option value="">Chọn nhân viên</Select.Option>
                                                {
                                                    this.state.listStaff.map((item) => {
                                                        return <Select.Option key={item._id} value={item._id}>{item.fullname}</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item> : null
                                }
                                {
                                    this.state.tabActive == "statistic_revenue" ?
                                        <React.Fragment>
                                            <Form.Item name="service_id" className="col-md-2">
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.listService}
                                                    placeholder="Dịch vụ"
                                                    allowClear
                                                />
                                            </Form.Item>
                                            <Form.Item name="cus_type" className="col-md-2">
                                                <Select>
                                                    <Select.Option value="">Loại khách</Select.Option>
                                                    <Select.Option value={1}>Khách cũ</Select.Option>
                                                    <Select.Option value={2}>Khách mới</Select.Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="booking_code" className="col-md-2">
                                                <Input placeholder="Mã lịch hẹn vd: 030821FB001" />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_tag" ?
                                        <React.Fragment>
                                            <Form.Item name="tag_id" className="col-md-2">
                                                <TreeSelect
                                                    style={{ width: '100%' }}
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    treeData={this.state.listTag}
                                                    placeholder="Nhãn"
                                                    allowClear
                                                />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_interactive_detail" ?
                                        <React.Fragment>
                                            <Form.Item name="is_cus_new" className="col-md-2">
                                                <Select>
                                                    <Select.Option value="">Chọn loại khách</Select.Option>
                                                    <Select.Option value={1}>Khách mới</Select.Option>
                                                    <Select.Option value={2}>Khách cũ</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_khambenh" ?
                                        <React.Fragment>
                                            <Form.Item name="fullname" className="col-md-2">
                                                <Input placeholder="Tìm theo họ tên.." />
                                            </Form.Item>
                                            <Form.Item name="birthday" className="col-md-auto">
                                                <Select>
                                                    <Select.Option value="">Năm sinh</Select.Option>
                                                    {
                                                        list_year.map((item) => {
                                                            return <Select.Option value={item}>{item}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="province" className="col-md-2">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option value="">Tỉnh/ Tp</Select.Option>
                                                    {
                                                        this.state.listProvince.map((item) => {
                                                            return <Select.Option value={item.city_id}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <Form.Item name="doctor" className="col-md-2">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    <Select.Option value="">Bác sỹ</Select.Option>
                                                    {
                                                        this.state.listDoctor.map((item) => {
                                                            return <Select.Option value={item.service_id}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_dichte" ?
                                        <React.Fragment>
                                            <Form.Item name="fullname" className="col-md-2">
                                                <Input placeholder="Tìm theo tên.." />
                                            </Form.Item>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Tìm theo số điện thoại.." />
                                            </Form.Item>
                                            <Form.Item name="booking_code" className="col-md-2">
                                                <Input placeholder="Tìm theo mã lịch hẹn.." />
                                            </Form.Item>

                                            <Form.Item name="trieu_chung" className="col-md-3">
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    placeholder="Triệu chứng"
                                                    allowClear
                                                    mode="multiple"
                                                >
                                                    {
                                                        list_trieuchung.map((item) => {
                                                            return <Select.Option value={item.name}>{item.title}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="vung_dich" className="col-md-2">
                                                <Select
                                                >
                                                    <Select.Option value="">Đi tới vùng dịch</Select.Option>
                                                    <Select.Option value="1">Có</Select.Option>
                                                    <Select.Option value="2">Không</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item name="tiem_vaccince" className="col-md-2">
                                                <Select
                                                >
                                                    <Select.Option value="">Tiêm vaccine</Select.Option>
                                                    <Select.Option value="1">Đã tiêm đủ 2 mũi</Select.Option>
                                                    <Select.Option value="2">Chưa tiêm đủ 2 mũi</Select.Option>
                                                </Select>
                                            </Form.Item>

                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_care_booking" ?
                                        <React.Fragment>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Nhập số điện thoại.." />
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                {
                                    this.state.tabActive == "statistic_feedback" ?
                                        <React.Fragment>
                                            <Form.Item name="phone" className="col-md-2">
                                                <Input placeholder="Nhập số điện thoại.." />
                                            </Form.Item>
                                            <Form.Item name="contentment" className="col-md-2">
                                                <Select placeholder="Kết quả" allowClear>
                                                    <Select.Option value={1}>Hài lòng</Select.Option>
                                                    <Select.Option value={2}>Không hài lòng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </React.Fragment> : null
                                }

                                <Form.Item name="time" className="col-md-3">
                                    <DatePicker.RangePicker
                                        locale={locale}
                                        format={'DD-MM-YYYY'}
                                        ranges={{
                                            'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                            'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                            'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                            'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                        }}
                                        format="DD/MM/YYYY"
                                        allowClear={false}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                                <div className="col-auto">
                                    <Button type="primary" onClick={() => {
                                        this.formFilter.resetFields();
                                        this.getListData();
                                    }}>Xóa lọc</Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div>
                        {
                            this.state.tabActive == "statistic_revenue" ? 
                            <RevenueComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            /> 
                            : this.state.tabActive == "statistic_interactive" ? 
                            <InteractiveComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            />  
                            : this.state.tabActive == "statistic_interactive_detail" ? 
                            <InteractiveDetailComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            />  
                            : this.state.tabActive == "statistic_rating" ? 
                            <RatingComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            />  

                            : this.state.tabActive == "statistic_tag" ? 
                            <TagComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            />  

                            : this.state.tabActive == "statistic_khambenh" ? 
                            <KhamBenhComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            /> 

                            : this.state.tabActive == "statistic_dichte" ? 
                            <DichTeComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                                list_trieuchung={list_trieuchung}
                            /> 

                            : this.state.tabActive == "statistic_care_booking" ? 
                            <CareBookingComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            /> 

                            : this.state.tabActive == "statistic_feedback" ? 
                            <FeedbackComponent 
                                listData={this.state.listData}
                                loading={this.state.loading}
                                exportToCSV={this.exportToCSV}
                            /> 
                            
                            :null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
