import React, { Component } from 'react';
import {AccountMetaData, setCookie} from '../config/settings';
import {Modal, Button, Tooltip, message} from 'antd';
import config from '../lib/config';
import { Link } from 'react-router-dom';

export default class HeaderV2 extends Component{
  constructor(props){
    super(props);
    this.state = {
        visible: false
    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
   
  }
  componentDidMount(){
    
  }
  render(){
    return (
        <div className="header">
            <div className="logo" onClick={() => window.location.reload()}>
                <span>Vienmat</span>
            </div>
            <div className="header-root">
                <div className="header-container header-top">
                    <div className="header-container-left">
                        
                    </div>
                    <div className="header-container-right">
                        {/* <a className="btn-login" href="#" onClick={(e) => {
                            e.preventDefault();
                            AccountMetaData.logIn()
                        }}>Đăng nhập</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
