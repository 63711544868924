import React, { useEffect, useMemo, useRef, useState } from "react";

import { Form, Input, Button, Alert, DatePicker, Result } from "antd";
import moment from "moment";

import { WidgetFormBooking, LoadingComponent } from "../../widgets"

const Booking = (props) => {
    const [loading, handleLoading] = useState(false);
    const [error, handleError] = useState(null);
    const [result, handleResult] = useState(null);
    const [company, setCompany] = useState();
    const [errorMain, setErrorMain] = useState();

    const elementPage = useRef();

    const search = new URLSearchParams(props.history.location.search);

    const booking_result = useMemo(() => {
        try{
            return JSON.parse(search.get("booking_result"))
        } catch(err){

        }
    }, [ props.history.location.search ])

    const onFinish = async (values) => {
        try {
            handleResult(null);
            handleLoading(true);

            values['ref'] = encodeURIComponent(search.get("ref"));

            let response = await props.fetchData({
                url: "api/v1/booking/web",
                data: values,
                method: "post"
            })
            handleError(null);
            handleLoading(false);
            search.set("booking_result", JSON.stringify(response.data));
            props.history.push(props.location.pathname+'?'+search.toString())
        } catch (err) {
            handleLoading(false);
            handleError(err.message || "Đã có lỗi xảy ra");
            elementPage.current.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    useEffect(() => {
        getCompany();
    }, [])

    const getCompany = async () => {
        try {
            let response = await props.fetchData({
                url: `api/v1/public/company/info?ref=${encodeURIComponent(search.get("ref"))}`
            })
            setCompany(response.data);
        } catch (err) {
            setErrorMain(err.message || "Đã có lỗi xảy ra")
        }
    }

    return <div id="app-container" style={{ background: "#fff", overflowY: "auto", paddingTop: `${props.statusBarHeight}px` }} ref={elementPage}>
        <div className="container">
        {
            errorMain ? <React.Fragment>
                <Result
                    status="500"
                    title="500"
                    subTitle={errorMain}
                    extra={<Button type="primary">Tải lại trang</Button>}
                />
            </React.Fragment> : company ? <div
                style={{
                    maxWidth: "600px",
                    margin: "auto"
                }}>
                {
                    company.banner && <div className="container mb-3">
                        <div className="row">
                            <img src={company.banner} className="img-fluid img-thumbnail col-12" alt="Responsive image" />
                        </div>
                    </div>
                }
                {
                    booking_result ?
                    <div className="container">
                        <div className="mb-3 row">
                                <Button onClick={() => {
                                    props.history.goBack();
                                }}><i className="fa fa-arrow-left mr-2"></i>Quay lại</Button>
                            </div>
                            <div className="row justify-content-center alert-success">
                                <span className="alert">
                                    <h4>Lịch hẹn khám bệnh đã tạo thành công</h4>
                                    <hr></hr>
                                    Ngày hẹn khám: {booking_result.booking_time}
                                    <hr></hr>
                                    Mã lịch hẹn
                                    <hr></hr>
                                    <h2>
                                        {booking_result.booking_code}
                                    </h2>
                                </span>
                            </div>
                    </div>
                    : <div className="border p-3" style={{background: "#fafafa"}}>
                        {
                            error ? <Alert message={error} type="error" /> : null
                        }
                        {
                            result ? <Alert message={result} type="success" /> : null
                        }

                        <WidgetFormBooking
                            onFinish={onFinish}
                            initialValues={{
                                date_booking: moment().add("day", 1)
                            }}
                            loading={loading}
                            hoursBooking={company.thoi_gian_kham_benh}
                            companyId={company.company_id}
                            showAddress={true}
                        />
                    </div>
                }
            </div> : <div>
                <LoadingComponent />
            </div>
        }
        </div>
    </div>
}

export default Booking;