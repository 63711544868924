import React, { useRef, useState } from 'react';

import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert, Image, Select, Radio } from 'antd';
import { apis, fetchData } from '../../../lib/apis';
import moment from 'moment';

import locale from 'antd/es/date-picker/locale/vi_VN';
import { useEffect } from 'react';
import { ConvertImgBase64, NotificationMessage } from '../../../lib/helpers';
import config from '../../../lib/config';
import WidgetFaceDataDetail from './widget/detail';
import WidgetFormUpdate from './widget/form';


const FaceData2View = (props) => {
    const [data, setData] = useState({
        total: 0,
        data: []
    })
    const [pagination, setpagination] = useState({
        page: 1,
        limit: 50
    })
    const [loading, setloading] = useState(false);
    const [visibleDetail, setvisibleDetail] = useState({
        visible: false,
        faceId: null
    });
    const [visibleUpdate, setvisibleUpdate] = useState({
        visible: false,
        faceData: null
    });

    const formFilter = useRef();
    const searchFilter = useRef();

    const getListData = async () => {
        try {
            setloading(true);

            let params = {
                ...pagination
            }

            if (formFilter.current) {
                let values = formFilter.current.getFieldsValue();
                if (values.date) {
                    values['date'] = values.date.startOf("day").format("YYYY-MM-DD");
                }
                Object.keys(values).map((key) => {
                    if (values[key]) {
                        params[key] = values[key]
                    }
                })
            }

            let response = await fetchData({
                url: apis.app.face_id.log2,
                params
            });

            setData({
                total: response.total,
                data: response.data
            })

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const insertFaceData = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.insert,
                method: "post",
                data: values
            });

            NotificationMessage({ type: "success", description: "Thao tác thành công!" });

            getListData();

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const removeFaceData = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.remove,
                method: "post",
                data: {
                    ids: values
                }
            });

            NotificationMessage({ type: "success", description: "Thao tác thành công!" });

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }

    const faceDataNote = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.note,
                method: "post",
                data: values
            });

            NotificationMessage({ type: "success", description: "Thao tác thành công!" });

            getListData();

        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    }


    useEffect(() => {
        let params = new URLSearchParams(props.location.search);
        if (formFilter) {
            try {
                let data = {};
                if (params.get("id")) data['id'] = params.get("id");
                if (params.get("date")) data['date'] = moment(params.get("date"));
                formFilter.current.setFieldsValue(data)
            } catch (err) {

            }
        }
    }, [props.location])

    useEffect(() => {
        getListData();
    }, [pagination])

    const submitFaceData = async (values, face_data) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.index,
                method: "put",
                data: {
                    ...values,
                    face_data: face_data,
                    face_id: face_data.face_id,
                },
            });
            message.success("Thao tác thành công");
            getListData();
        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
            setloading(false);
        } finally {
            
        }
    };

    return (
        <div className="list-connect content-m">
            <WidgetFaceDataDetail
                {...visibleDetail}
                onCancel={() => setvisibleDetail({ visible: false })}
            />

            <WidgetFormUpdate
                {...visibleUpdate}
                onCancel={() => setvisibleUpdate({ visible: false })}
                onFinish={() => getListData()}
            />

            <div className="list-c">
                <div style={{ marginBottom: "15px" }} className="table-header">
                    <h3>Lịch sử: {data.total}</h3>
                    <Form
                        ref={formFilter}
                        initialValues={{
                            date: moment()
                        }}
                        onValuesChange={(e) => {
                            let timeout = 0;
                            if (e.face_id) {
                                timeout = 300;
                            }
                            if (searchFilter.current) clearTimeout(searchFilter.current)
                            searchFilter.current = setTimeout(() => {
                                getListData()
                            }, timeout)
                        }}
                    >
                        <div className="row">
                            <Form.Item className='col-md-2' name="id">
                                <Input placeholder='Face Id | Guest Id' />
                            </Form.Item>
                            <Form.Item className='col-md-2' name="is_maskedon">
                                <Select allowClear placeholder="Lọc khẩu trang">
                                    <Select.Option value={1}>Có khẩu trang</Select.Option>
                                    <Select.Option value={-1}>Không khẩu trang</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item className="col-md-2" name="date">
                                <DatePicker
                                    locale={locale}
                                    format={'DD-MM-YYYY'}
                                    ranges={{
                                        'Hôm nay': moment(),
                                        'Hôm qua': moment().add(-1, "day")
                                    }}
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    onOpenChange={(e) => {
                                        if (!e) getListData()
                                    }}
                                    disabledDate={(date) => date > moment().add(0, "day")}
                                />
                            </Form.Item>
                            <div className="col-auto">
                                <Button type="primary" onClick={() => {
                                    formFilter.current.resetFields();
                                    getListData();
                                }}>Xóa lọc</Button>
                            </div>
                            <div className="col-auto" >
                                <Tooltip title={'Làm mới'}>
                                    <a className="btn btn-primary text-white mr-2" onClick={getListData}><i className="far fa-sync"></i></a>
                                </Tooltip>
                            </div>
                        </div>
                    </Form>
                </div>
                <Table
                    dataSource={data.data}
                    columns={[
                        {
                            title: "#",
                            index: "#",
                            render: (item, value, index) => {
                                return (pagination.page * pagination.limit - pagination.limit) + index + 1
                            }
                        },
                        // {
                        //     title: "Phòng ban",
                        //     dataIndex: "type",
                        //     render: (value) => config.face_data.type[value] ? config.face_data.type[value].label : value
                        // },
                        {
                            title: "Ảnh",
                            dataIndex: "face_data",
                            render: (item, record) => {
                                return <React.Fragment>
                                    <Image src={ConvertImgBase64(item.images[0])} width={100} preview={false} />
                                </React.Fragment>
                            }
                        },
                        {
                            title: "Khách hàng",
                            dataIndex: "kbyt",
                            filters: [
                                {
                                    text: "Chưa khai báo",
                                    value: 1
                                },
                                {
                                    text: "Đã khai báo",
                                    value: 2
                                }
                            ],
                            onFilter: (value, record) => {
                                if (value == 1) return !record.kbyt;
                                if (value == 2) return record.kbyt;
                            },
                            render: (value, record) => {
                                return <>
                                    {
                                        !value || !value.booking_code ? <>
                                            <p><b>Chưa khai báo</b>
                                                <a href="#" className="ml-2" onClick={() => setvisibleUpdate({ visible: true, faceData: record.face_data })}><i className="fa fa-edit"></i></a>
                                            </p>
                                            <p className='mb-0'>Nhận diện: <b>{record.is_face_insert ? "Nguời quen" : "Người lạ"}</b></p>
                                        </> :
                                            <>
                                                <p>KB: {value.booking_code}</p>
                                                <p>Tên: {value.ho_ten}</p>
                                                <p className='mb-0'>SDT: {value.so_dien_thoai}</p>
                                            </>
                                    }
                                </>
                            }
                        },
                        {
                            title: "Dịch vụ",
                            dataIndex: "thuphi",
                            width: 300,
                            render: (value) => {
                                return <>
                                    {
                                        !value ? "" :
                                            <>
                                                {
                                                    value.dich_vu.map((item) => {
                                                        return <>
                                                            <p>Tên: {item.ten}</p>
                                                            <p>SL: {item.so_luong}</p>
                                                        </>
                                                    })
                                                }
                                            </>
                                    }
                                </>
                            }
                        },
                        {
                            title: "Ghi chú",
                            dataIndex: "face_note",
                            width: 260,
                            render: (value, record) => {
                                let text = value ? value.note : "";
                                return <Input.TextArea defaultValue={text}
                                    onBlur={(e) => {
                                        if (e.target.value != text) {
                                            faceDataNote({
                                                note: e.target.value,
                                                id: record._id,
                                                date: moment(record.face_data.timestamp).format("YYYY-MM-DD")
                                            })
                                        }
                                    }}
                                    rows={3}
                                />
                            }
                        },
                        // {
                        //     title: "Kiểm tra",
                        //     dataIndex: "face_note",
                        //     align: "right",
                        //     filters: [
                        //         {
                        //             text: "Yes",
                        //             value: 1
                        //         },
                        //         {
                        //             text: "No",
                        //             value: 2
                        //         }
                        //     ],
                        //     onFilter: (value, record) => {
                        //         return record.face_note && record.face_note.is_check == value
                        //     },
                        //     render: (value, record) => {
                        //         return <Radio.Group defaultValue={value ? value.is_check : 0}
                        //             onChange={(e) => {
                        //                 faceDataNote({
                        //                     is_check: e.target.value,
                        //                     id: record._id,
                        //                     date: moment(record.face_data.timestamp).format("YYYY-MM-DD")
                        //                 })
                        //             }}
                        //         >
                        //             <Radio value={1}>Có</Radio>
                        //             <Radio value={2}>Không</Radio>
                        //         </Radio.Group>
                        //     }
                        // },
                        {
                            title: "Thời gian",
                            dataIndex: "face_data",
                            align: "right",
                            render: (item) => moment(item.timestamp).format('HH:mm:ss')
                        },
                        {
                            title: "Hành động",
                            className: "text-right",
                            render: (item) => {
                                return <div>
                                    <Button size="small" type="primary"
                                        onClick={() => {
                                            setvisibleDetail({
                                                visible: true,
                                                faceId: item.face_data.face_id,
                                                faceData: item.face_data
                                            })
                                        }}
                                        disabled={item.face_data.face_id == -1 && item.face_data.guest_id == -1}
                                    >
                                        Hoạt động
                                    </Button>
                                    {/* {
                                        item.face_data.face_id == -1 && <Button
                                            size="small"
                                            type="primary"
                                            onClick={() => {
                                                insertFaceData({
                                                    _id: item._id,
                                                    face_data: item.face_data
                                                })
                                            }}
                                            className="ml-2"
                                        >
                                            Thêm mới
                                        </Button>
                                    } */}

                                    <Tooltip title="Bật/ tắt cảnh báo phòng mổ" placement="topRight">
                                        {
                                            item.face_info && item.face_info.is_warning == false ? <Button
                                                size="small"
                                                type="primary"
                                                onClick={() => {
                                                    submitFaceData({
                                                        is_warning: true
                                                    }, item.face_data)
                                                }}
                                                className="ml-2"
                                            >
                                                Bật <i className='fa fa-bell ml-2'></i>
                                            </Button> :  <Button
                                                size="small"
                                                type="primary"
                                                onClick={() => {
                                                    submitFaceData({
                                                        is_warning: false
                                                    }, item.face_data)
                                                }}
                                                className="ml-2"
                                                danger
                                            >
                                                Tắt <i className='fa fa-bell ml-2'></i>
                                            </Button>
                                        }
                                    </Tooltip>
                                </div>
                            }
                        }
                    ]}
                    rowKey="_id"
                    loading={loading}
                    pagination={{
                        total: data.total,
                        pageSize: pagination.limit,
                        onChange: (current, pageSize) => {
                            setpagination({
                                page: current,
                                limit: pageSize
                            })
                        },
                        current: pagination.page,
                        showSizeChanger: true,
                        position: ["topRight"],
                        className: "mt-0"
                    }}
                    scroll={{
                        x: true
                    }}
                />
            </div>
        </div>
    )
}

export default FaceData2View;
