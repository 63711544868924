import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Popover, Upload, Pagination, Tooltip, Alert } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';

import { WidgetEmoji } from '../../../widgets';

const { Option } = Select;

export default class Broadcast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPages: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListPages();
    }
    componentDidMount = () => {

    }

    create = async (values) => {
        try {
            this.setState({ loadingForm: true, error: null });

            if(values['attachments']){
                let pictures = [];
                values['attachments']['fileList'].forEach((item) => {
                    if(item.status == "done"){
                        if(item.type.indexOf('video') > -1){
                            values['video'] = item.response.attachment.url;
                        } else if(item.type.indexOf('image') > -1){
                            pictures.push(item.response.attachment.url);
                        }
                    }
                })
                values['pictures'] = pictures;
                delete values['attachments'];
            }

            if(values['time_start']) values['time_start'] = values['time_start'].unix() * 1000;

            // values['pictures'] = ['https://bloganchoi.com/wp-content/uploads/2020/07/wayne-rooney-khi-hy-sinh-la-tat-ca.jpg'];
            // values['video'] = 'https://apivienmat.lalasoft.vn/new/api/v1/uploads/6/106405841136182/1621334171163-chat-8425662095240950728.mp4';

            await fetchData({
                url: `api/v1/post/auto`,
                data: values,
                method: 'post'
            });
            this.getListData();
            this.resetField();
            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Thêm bài đăng',
                type: 'success'
            })

        } catch (err) {
            let message = "Đã có lỗi xảy ra!";
            if (typeof err == "object") {
                message = err.message;

                if (err.errorFields && err.errorFields.length > 0) {
                    message = err.errorFields[0]['errors'][0]
                }
            }
            this.setState({ loadingForm: false, error: message });
        }
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/post/auto?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getListPages = async (keyword = '') => {
        try {
            let res = await fetchData({
                url: `api/v1/channel/list?acc_type=5&limit=10&keyword=${keyword}`
            });
            this.setState({ listPages: res.data });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Tìm fanpage',
                type: 'error'
            })
        }
    }

    resetField = () => {
        this.setState({
            loadingForm: false,
            visibleForm: false
        }, () => {
            if(this.formH) this.formH.resetFields();
            this.getListPages();
        });
    }

    update = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/${id}`,
                            method: 'put',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleStatus = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/post/auto/${id}`,
                            method: 'put',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập đăng bài',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập đăng bài',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Đăng bài'}
                        visible={this.state.visibleForm}
                        className={"form-modal"}
                        footer={[]}
                        onCancel={() => this.resetField()}
                        width={900}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            className="form-broadcast"
                            onFinish={this.create}
                            initialValues={{
                                content: ""
                            }}
                            layout="vertical"

                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            {/* {
                                this.props.UserLogin.is_test != 1 ?
                                <Alert message={"Chức năng đang phát triển"} type="warning" /> : null
                            } */}
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: 'Tên không được để trống' }]}
                                        label="Tên bài đăng"
                                    >
                                        <Input placeholder="Nhập tên bài đăng" />
                                    </Form.Item>

                                    <Form.Item
                                        name="channels"
                                        label="Chọn fanpage"
                                        rules={[{ required: true, message: 'Chọn ít nhất 1 fanpage' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Tìm tên fanpage"
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={(e) => {
                                                if (this.timeout_fetchpages) clearTimeout(this.timeout_fetchpages);
                                                this.timeout_fetchpages = setTimeout(() => {
                                                    this.getListPages(e)
                                                }, 300);
                                            }}
                                            // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            allowClear
                                            mode="multiple"
                                        >
                                            {this.state.listPages.map((item, i) => {
                                                return <Option key={i} value={item.user_id}>{item.username || item.full_name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="content"
                                        label="Nội dung"
                                        rules={[{ required: true, message: 'Nội dung không được để trống!' }]}
                                        className="mb-1"
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>

                                    <div className="text-right">
                                        <WidgetEmoji
                                            visible={this.state.visibleEmoji}
                                            onVisibleChange={(e) => {
                                                this.setState({ visibleEmoji: e })
                                            }}
                                            onEmojiClick={(e, value) => {
                                                this.formH.setFieldsValue({
                                                    content: this.formH.getFieldValue("content")+ value.emoji
                                                })
                                            }}
                                        />
                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <Button type="default" size={"small"} style={{ marginRight: "5px" }}
                                            onClick={() => this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + '#{page_name}' })}
                                        >{`#{PAGE_NAME}`}</Button> Chèn tên page
                                            </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <Button type="default" size={"small"} style={{ marginRight: "5px" }}
                                            onClick={() => this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + '#{SPIN_1|SPIN_2}' })}
                                        >{`#{SPIN_1|SPIN_2}`}</Button> Chọn từ khóa ngẫu nhiên (Khuyên dùng)
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="link"
                                        label="Link đính kèm"
                                    >
                                        <Input placeholder="VD: https://24h.com.vn "/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Tệp đính kèm"
                                        name="attachments"
                                    >
                                        <Upload
                                            action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                            withCredentials={true}
                                            listType={'picture-card'}
                                            ref={(evt) => this.uploadFile = evt}
                                            onPreview={(file) => {
                                                let url = file.url;
                                                if (file.response && file.response.attachment) url = file.response.attachment.url;
                                                if (url) window.open(url)
                                            }}
                                            accept={"image/*, video/*"}
                                            fileList={[...this.state.fileList]}
                                            onChange={({ fileList, file}) => {
                                                let maxCountFileUpload = this.state.maxCountFileUpload;
                                                if(file.status == "uploading"){
                                                    if(file.type.indexOf('image') > -1){
                                                        fileList = fileList.filter((item) => item.type.indexOf('image') > -1);
                                                        maxCountFileUpload = 50;
                                                    } else if(file.type.indexOf('video') > -1){
                                                        fileList = fileList.filter((item) => item.type.indexOf('video') > -1);
                                                        maxCountFileUpload = 1;
                                                    }
                                                }
                                                this.setState({ fileList, maxCountFileUpload})
                                            }}
                                            maxCount={this.state.maxCountFileUpload || 50}
                                        >
                                            {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>

                                        </Upload>
                                    </Form.Item>
                                    <Form.Item label="Thời gian chạy" name="time_start"
                                    >
                                        <DatePicker format="YYYY-MM-DD HH:mm" disabledDate={(current) => current && current < moment().startOf('day')} showTime />
                                    </Form.Item>
                                </div>
                            </div>

                            <div style={{ marginTop: "10px", fontWeight: "600" }} className="note">
                                <i>Lưu ý: </i>
                                <p><i>- Không thể đăng cả link và tệp đính kèm cùng lúc.</i></p>
                                <p><i>- Không thể đăng cả ảnh và video cùng lúc.</i></p>
                                <p><i>- Bạn có thể theo dõi, hủy tiến trình đăng bài tại quản lý bài đăng.</i></p>
                                {/* <p><i>- Đọc kỹ <a href="#" onClick={() => this.setState({ visiblePolicyTagFb: true })}>điều khoản</a> của facebook áp dụng cho khách hàng tương tác > 24h.</i></p> */}
                            </div>
                            <div className="list-button">
                                <Button disabled={this.state.loadingForm} htmlType="submit" type="primary" loading={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>

                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý bài đăng: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm bài đăng'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="name">
                                                <Input placeholder="Nhập tên bài đăng" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={0}>Đang xử lý</Select.Option>
                                                    <Select.Option value={1}>Thành công</Select.Option>
                                                    <Select.Option value={2}>Thất bại</Select.Option>
                                                    <Select.Option value={3}>Đang tạm dừng</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Tên</th>
                                        <th>Trạng thái</th>
                                        <th>Mục tiêu</th>
                                        <th className="text-center">Kết quả</th>
                                        <th>Ngày tạo</th>
                                        <th>Ngày chạy</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        {item.status == 0 ? <span>Đang xử lý</span> : item.status == 1 ?
                                                            <span className="text-success">Thành công</span> :
                                                            item.status == 3 ? <span className="text-primary">Đang tạm dừng</span> :
                                                                <span className="text-danger">Thất bại</span>}
                                                    </td>
                                                    <td>{item.num_target}</td>
                                                    <td className="text-center"><span className="text-success">{item.num_success}</span>/<span className="text-danger">{item.num_failed}</span></td>
                                                    <td>{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td>{item.time_start ? moment(item.time_start).format('HH:mm DD-MM-YYYY') : null}</td>
                                                    <td className="text-right">
                                                        {
                                                            item.status == 0 ?
                                                                <Tooltip title="Dừng">
                                                                    <Button size="small" type="danger" className="mr-2" onClick={() => {
                                                                        this.handleStatus(item._id, {
                                                                            status: 3
                                                                        })
                                                                    }}><i className="far fa-pause"></i></Button>
                                                                </Tooltip> :
                                                                <Tooltip title="Chạy">
                                                                    <Button size="small" type="danger" className="mr-2" disabled={item.status == 1}
                                                                        onClick={() => {
                                                                            this.handleStatus(item._id, {
                                                                                status: 0
                                                                            })
                                                                        }}
                                                                    ><i className="far fa-play"></i></Button>
                                                                </Tooltip>
                                                        }
                                                        <Tooltip title="Chi tiết tiến trình">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.props.history.push('/post/auto/' + item._id);
                                                            }}><i className="far fa-file-alt"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
