import React, { Component } from 'react';

export default class WidgetPhoneCall extends Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentWillMount(){
    
  }
  componentDidMount(){
    
  }
  componentDidUpdate(prevProps, prevState){
      
  }
  render(){
    return <div id="phone-call" className={`${this.props.visible ? "active" : ""}`} data-id={`phone_${this.props.item ? this.props.item.phone : ""}`}>
        {
            this.props.item ?
            <div className="desc">
                <p className="title">{this.props.item.title}</p>
                <p>{this.props.item.phone}</p>
            </div> : null
        }
        <div className="phone-call">
            <i className="fal fa-phone fa-2x"></i>
        </div>
    </div>
  }
}
