import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

const FooterApp = (props) => {

    return !props.visible ? null : (
        <footer className="footer-app">
            <section className="tab-footer">
                <Link to="/home" className={props.location.pathname == '/' || props.location.pathname == '/home' ? 'tab-item active' : 'tab-item'}>
                    <i className="fas fa-home"></i>
                    <p>Trang chủ</p>
                </Link>
                <Link to="/chat" className={props.location.pathname.indexOf('/chat') != -1 ? 'tab-item active' : 'tab-item'}>
                    <i className="fas fa-comment">
                        {
                            props.numMessage ? <span className="count">{props.numMessage}</span> : null
                        }
                    </i>
                    <p>Chăm sóc khách</p>
                </Link>
                <Link to="/me" className={props.location.pathname.indexOf('/me') != -1 ? 'tab-item active' : 'tab-item'}>
                    <i className="fas fa-user"></i>
                    <p>Tài khoản</p>
                </Link>
            </section>
        </footer>
    ) 
}

export default withRouter(FooterApp);
