import React, { Component } from 'react';
import { Modal, Form, Input, Button } from 'antd';


export default class ModalDetailUser extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <Modal
            title={this.props.title}
            visible={this.props.visible}
            onCancel={this.props.hide}
            footer={null}
            style={{maxWidth: "300px"}}
            className="ant-modal-20"
        >
            {
                this.props.user ?
                    <div>
                        <div className="block-content block-content-full text-center">
                            <div className="block-content block-content-full" style={{background: "#4f6dac"}}>
                                <img className="img-avatar" src={this.props.user.avatar} alt=""
                                    onError={(e) => e.target.src = "/user.jpg"}
                                />
                            </div>
                            <div className="block-content bg-body-light block-content-full" >
                                <div className="font-w600">{this.props.user.fullname}</div>
                                <div className="font-w500" style={{marginTop: "5px"}}>Mã NV: <span className="font-size-sm text-muted">{this.props.user.code}</span></div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </Modal>
    }
}
