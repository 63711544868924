import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select, Spin } from "antd";
import moment from "moment";

import { LoadingComponent, LoadingComponent2 } from "../../widgets";
import { formatPrice, NotificationMessage, sleep } from "../../lib/helpers";

import locale from 'antd/es/date-picker/locale/vi_VN';

import Widget1 from "./widget/widget1";
import Widget2 from "./widget/widget2";
import Widget3 from "./widget/widget3";
import Widget4 from "./widget/widget4";
import Widget5 from "./widget/widget5";
import Widget6 from "./widget/widget6";
import Widget7 from "./widget/widget7";
import Widget8 from "./widget/widget8";
import Widget9 from "./widget/widget9";

const TraCuu = (props) => {
    const [error, handleError] = useState(null);
    const [company, setCompany] = useState();
    const [errorMain, setErrorMain] = useState();
    const [dataReportCustormer, setdataReportCustormer] = useState([]);
    const [dataReportCustormer2, setdataReportCustormer2] = useState([]);
    const [dataReportTag, setdataReportTag] = useState([]);
    const [dataReportTag2, setdataReportTag2] = useState([]);
    const [dataReportAppointment, setdataReportAppointment] = useState([]);
    const [dataReportAppointment2, setdataReportAppointment2] = useState([]);
    const [dataReportRevenue, setdataReportRevenue] = useState([]);
    const [dataReportDoctorRevenue, setdataReportDoctorRevenue] = useState([]);
    
    const [loading, setloading] = useState(false);

    const company_id = props.match.params.company_id;

    const formFilter = useRef();

    useEffect(() => {
        getCompany();
    }, []);

    useEffect(() => {
        if(company){
            getReport();
        }
    }, [company])

    const getCompany = async () => {
        try {
            let response = await props.fetchData({
                url: `api/v1/public/company/setting?company_id=${company_id}`,
            });
            setCompany(response.data);
        } catch (err) {
            setErrorMain(err.message || "Đã có lỗi xảy ra");
        }
    };

    const getReport = async () => {
        setloading(true);
        try{
            await sleep(1000);
            
            let params = {};
            if (formFilter.current) params = { ...params, ...formFilter.current.getFieldsValue() };

            await Promise.all([
                reportCustormer(params),
                reportTag(params),
                reportCustormer2(params),
                reportTag2(params),
                reportAppointment(params),
                reportRevenue(params),
                reportDoctorRevenue(params)
            ])
        } catch(err){
            handleError(err.message || "Đã có lỗi xảy ra");
        }
        setloading(false);
    }

    const reportCustormer = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/custormer`,
                    params
                });
                setdataReportCustormer(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportTag = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/tag`,
                    params
                });
                setdataReportTag(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportTag2 = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/tag2`,
                    params
                });
                setdataReportTag2(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportCustormer2 = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/custormer2`,
                    params
                });
                setdataReportCustormer2(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportAppointment = async (params = {}) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/appointment`,
                    params
                });
                setdataReportAppointment(response.data);

                response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/appointment`,
                    params: {
                        ...params,
                        type: 2
                    }
                });
                setdataReportAppointment2(response.data);

                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportRevenue = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/revenue`,
                    params
                });
                setdataReportRevenue(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    const reportDoctorRevenue = async (params) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await props.fetchData({
                    url: `api/v1/company/${company_id}/report/revenue_doctor`,
                    params
                });
                setdataReportDoctorRevenue(response.data);
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }


    return (
        <div
            id="app-container"
            style={{
                background: "#fff",
                overflowY: "auto",
                paddingTop: `${props.statusBarHeight}px`,
            }}
        >
            <div className="container">
                {errorMain ? (
                    <React.Fragment>
                        <Result
                            status="500"
                            title="500"
                            subTitle={errorMain}
                            extra={<Button type="primary">Tải lại trang</Button>}
                        />
                    </React.Fragment>
                ) : company ? (
                    <div
                        style={{
                            margin: "auto",
                        }}
                    >
                        {company.banner && (
                            <div className="container mb-3">
                                <div className="row">
                                    <img
                                        src={company.banner}
                                        className="img-fluid img-thumbnail col-12"
                                        alt="Responsive image"
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <div className="container body-content mt-5 mb-5">
                                <div className="row border p-0 pt-4 pb-5">
                                    <div className="col-12">
                                        <h3 className="text-center">
                                            <b>Báo cáo thống kê</b>
                                        </h3>
                                        {error ? <Alert message={error} type="error" className="mb-3" /> : null}
                                    </div>
                                    <LoadingComponent2 show={loading} />
                                    <div className="col-12 mb-4">
                                        <Form
                                            ref={formFilter}
                                            initialValues={{
                                                start_date: moment().format("YYYY-MM-DD"),
                                                end_date: moment().format("YYYY-MM-DD")
                                            }}
                                        >
                                            <Form.Item name="start_date" noStyle>
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <Form.Item name="end_date" noStyle>
                                                <Input type="hidden" />
                                            </Form.Item>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-6 p-4 p-md-0">
                                                    <DatePicker.RangePicker
                                                        locale={locale}
                                                        format={'DD-MM-YYYY'}
                                                        ranges={{
                                                            'Hôm nay': [moment().startOf("day"), moment().endOf("day")],
                                                            'Hôm qua': [moment().startOf("day").add(-1, "day"), moment().endOf("day").add(-1, "day")],
                                                            'Tuần này': [moment().startOf("week"), moment().endOf("week")],
                                                            'Tuần trước': [moment().add(-1, "week").startOf("week"), moment().add(-1, "week").endOf("week")],
                                                            'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                                                            'Tháng trước': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                                                        }}
                                                        allowClear={false}
                                                        style={{ width: "100%" }}
                                                        defaultValue={[moment(), moment()]}
                                                        onChange={(values) => {
                                                            if (values) {
                                                                formFilter.current.setFieldsValue({
                                                                    start_date: values[0].format("YYYY-MM-DD"),
                                                                    end_date: values[1].format("YYYY-MM-DD")
                                                                })
                                                            }
                                                        }}
                                                        onOpenChange={(e) => {
                                                            if(!e){
                                                                getReport();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="col-12">
                                        <h5>1. Nguồn khách</h5>
                                        <div>
                                            <Widget1 data={dataReportCustormer} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>2. Loại khách hàng</h5>
                                        <div>
                                            <Widget2 data={dataReportTag} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>3. Nhân viên</h5>
                                        <div>
                                            <Widget3 data={dataReportCustormer2} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>4. Đối tượng quan tâm</h5>
                                        <div>
                                            <Widget4 data={dataReportTag2} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>5. Lịch hẹn phát sinh trong ngày</h5>
                                        <div>
                                            <Widget5 data={dataReportAppointment} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>6. Lịch khám trong ngày</h5>
                                        <div>
                                            <Widget6 data={dataReportAppointment2} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row border p-0 pt-4 pb-5 mt-5">
                                    <div className="col-12">
                                        <h5>7. Doanh thu trong ngày = <span className="text-danger">{formatPrice(dataReportRevenue.reduce((a, b) => a + b.tong, 0))} VND</span></h5>
                                        <div>
                                            <Widget7 data={dataReportRevenue} />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <h5>8. Tổng bệnh nhân trong ngày = <span className="text-danger">{dataReportRevenue.reduce((a, b) => a + b.so_luong, 0)}</span></h5>
                                        <div>
                                            <Widget8 data={dataReportRevenue} />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <h5>9. Tổng doanh thu theo bác sỹ</h5>
                                        <div>
                                            <Widget9 data={dataReportDoctorRevenue} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <LoadingComponent />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TraCuu;
