import React from "react";

import { Modal } from "antd";

const PolicyTagFb = (props) => {
    return <Modal
        title='Điều khoản của facebook'
        visible={props.visible}
        onCancel={props.hide}
        onOk={props.hide}
        centered
    >
        <div>
            <h4>1. Post-Purchase Update</h4>
            <div>
                <p style={{ marginBottom: "0px" }}>Tin nhắn cập nhật cho khách hàng về giao dịch mua hàng gần đây. Ví dụ:</p>
                <p style={{ marginBottom: "0px" }}>- Hóa đơn hoặc biên lai</p>
                <p style={{ marginBottom: "0px" }}>- Tình trạng đơn hàng, vận chuyển</p>
                <p>- Thay đổi liên quan đến đơn hàng mà người dùng đã đặt</p>
            </div>
            <h4>2. Confirmed Event Update</h4>
            <div>
                <p style={{ marginBottom: "0px" }}>Gửi lời nhắc hoặc cập nhật của người dùng về sự kiện mà họ đã đăng ký. Ví dụ:</p>
                <p style={{ marginBottom: "0px" }}>- Nhắc nhở về một sự kiện hoặc cuộc hẹn</p>
                <p style={{ marginBottom: "0px" }}>- Xác nhận đặt phòng hoặc tham dự</p>
                <p>- Cập nhật lịch trình di chuyển</p>
            </div>
            <h4>3. Account Update</h4>
            <div>
                <p style={{ marginBottom: "0px" }}>Thông báo cho người dùng về sự thay đổi không lặp lại đối với ứng dụng hoặc tài khoản của họ. Ví dụ:</p>
                <p style={{ marginBottom: "0px" }}>- Thay đổi trạng thái công việc</p>
                <p style={{ marginBottom: "0px" }}>- Thay đổi tài khoản thanh toán</p>
                <p>- Cảnh báo gian lận</p>
            </div>
            <div>
                <strong>Xem tài liệu facebook chính thức <a target="_blank" href="https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/#supported_tags"><i>tại đây.</i></a></strong>
            </div>
        </div>
    </Modal>
}

export default PolicyTagFb;