import React, { Component } from 'react';

import { Modal, Button } from 'antd';

export default class WidgetModalSpinText extends Component {
    constructor(props) {
        super(props);
    }
    appendText = (value) => {
        if(this.props.appendText) this.props.appendText(value);
    }
    render() {
        let data = this.props.data || [];

        return <Modal
            title={'Spin text'}
            visible={this.props.visible}
            footer={<Button onClick={this.props.hide}>
                Đóng
                </Button>}
            onCancel={this.props.hide}
            centered
        >
            <div>
                {
                    data.map((item, i) => {
                        return <div key={i} style={{ marginBottom: "10px" }}>
                        <a className="btn btn-default border" style={{ marginRight: "5px", textTransform: "uppercase"}}
                            onClick={() => this.appendText(item.value)}
                        >{item.value}</a> {item.text}
                            </div>
                    })
                }
            </div>
        </Modal>
    }
}
