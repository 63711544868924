const XLSX = require('xlsx');
const { notification } = require("antd");
const lodash = require("lodash");

export function makeImageUrl(photo) {
  let result = null;
  if (typeof photo == 'object' && photo.type && photo.data) {
    result = 'data:image/' + photo.type + ';base64,' + photo.data;
  }
  return result;
}
export function notifyBrowser(options = {}) {
  try {
    if (window.Notification.permission !== 'granted') {
      window.Notification.requestPermission();
    }
    else {
      options['title'] = options['title'] ? options['title'] : 'Vnpsoftware';
      options['icon'] = options['icon'] ? options['icon'] : require('../img/icon-chat.svg');
      options['body'] = options['body'] ? options['body'] : '';
      options['link'] = options['link'] ? options['link'] : window.location.origin;
      var notification = new window.Notification(options['title'], {
        icon: options['icon'],
        body: options['body'],
      });
      if (options['audio']) {
        let audio = new Audio('/notification_tone.mp3');
        audio.play();
      }

      notification.onclick = function () {
        window.open(options['link']);
      };
      // setTimeout(() => {
      //   notification.close();
      // }, 3500)
    }
  } catch (err) {

  }
}

export function change_alias(alias) {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  str = str.replace(/ + /g, " ");
  str = str.trim();
  return str;
}

export function isTiengViet(string) {
  var regexName = /[à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ|è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ì|í|ị|ỉ|ĩ|ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ|ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ỳ|ý|ỵ|ỷ|ỹ|đ]/gi;
  return regexName.test(string)
}

export function formatPrice(price) {
  return new Intl.NumberFormat('vi-VN').format(price).replace(/\./g, ',');
}

export function getFileName(url) {
  return url.replace(/^.*[\\\/]/, '');
}

export function convertContentLink(content) {
  let regex = /(https?:\/\/[^\s]+)/gi;
  let m;
  let result = content;
  while ((m = regex.exec(content)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    result = result.replace(m[0], `<a class="link" href="${m[0].trim('')}" target="_blank">${m[0]}</a>`)
  }
  return result;
}

export function postMessageApp(data_msg) {
  try {
    if (window.ReactNativeWebView) {
      window.postMessage(JSON.stringify(data_msg), "*");
      window.ReactNativeWebView.postMessage(JSON.stringify(data_msg))
    } else {
      window.postMessage(JSON.stringify(data_msg), "*");
    };
  } catch (err) {
    alert("Error post message app");
  }
}

export function sleep(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms)
  })
}

export const readFileXls = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsBinaryString(file, "UTF-8");
    reader.onload = function (evt) {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      resolve(data.split('\n'));
    }
    reader.onerror = function (evt) {
      reject(evt);
    }
  })
}

export const spinText = (content, item) => {
  let regex = /#{(.*?)}/gi;
  let m;
  let result = content;
  while ((m = regex.exec(content)) !== null) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    if (m[0].indexOf('|') == -1) {
      let key_item = m[1].toLocaleLowerCase().replace(/\s/, '');
      result = result.replace(m[0], item[key_item] || m[0])
    } else {
      let arr = m[1].split('|');
      result = result.replace(m[0], arr[Math.floor(Math.random() * arr.length)])
    }
  }
  return result;
}

export const NotificationMessage = ({ type, response, description, title, key}) => {
  try{
      title = title || "Notification";

      if(response){
          if(type == "error" || type == "warning"){
              title = title || "Warning";
              description = response.message;
          }
      }

      notification[type]({
          description: description,
          message: title,
          placement: 'topRight',
          duration: 3,
          rtl: true,
          key: key
      })
  } catch(err){
      console.log(err);
  }
}

export const ConvertImgBase64 = (str) => {
  return `data:image/png;base64, ${str}`
}