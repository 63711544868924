import React, { Component } from 'react';
import MessageViewItem from './message';
import { Tooltip, Button, Modal, Input, Form } from 'antd';

export default class FormDirectNew extends Component {
  constructor(props) {
    super(props);
    this.historyRef = null;
  }
  componentWillMount() {

  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.visible && !this.props.visible){
      if(this.form) this.form.resetFields();
    }
  }
  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        this.sendMesV2(e);
      }
    }
  }

  render() {
    let history_direct = [];
    if (this.props.toUserMes && this.props.toUserMes.contact && this.props.toUserMes.history) {
      let history = this.props.toUserMes.history;
      for (let i = history.length - 1; i >= 0; i--) {
        let item = history[i];

        history_direct.push(<MessageViewItem
          Message={item}
          key={item.item_id}
          contactInfoSelected={this.props.contactInfoSelected}
          channelSelected={this.props.channelSelected}
          left={this.props.channelSelected.user_id != item.user_id}
          handleDetailUser={this.props.handleDetailUser}
        />)
      }
    }

    return (
      <Modal
        visible={this.props.visible}
        title="Nhắn tin"
        footer={null}
        onCancel={this.props.hide}
        width={600}
      >
        {
          history_direct.length > 0 ?
            <ul className="history-direct chat-box" ref={(e) => this.historyRef = e} style={{marginLeft: "0px"}}>
              {history_direct}
            </ul> : null
        }
        {
          this.props.toUserMes ? 
          <Form
            layout="vertical"
            onFinish={this.props.sendDirect}
            initialValues={{
              comment_id: this.props.toUserMes.comment.comment_id,
              contact_id: this.props.toUserMes.comment.contact_id,
              to_user_id: this.props.toUserMes.comment.user_id,
              type_send: this.props.toUserMes.type_send
            }}
            onKeyPress={(e) => {
              if(e.key == "Enter"){
                this.form.submit();
              }
            }}
            ref={(evt) => this.form = evt}
          >
            <Form.Item name="comment_id" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="contact_id" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="to_user_id" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item name="type_send" noStyle>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item label="Nội dung" name="content">
              <Input.TextArea style={{minHeight: "80px", borderRadius: "5px"}}/>
            </Form.Item>
            <div className="text-right">
              <Button htmlType="submit" type="primary" loading={this.props.loadingDirectNew} style={{borderRadius: "5px"}}>Send</Button>
            </div>
          </Form> : null
        }
      </Modal>
    )
  }
}
