import React, { Component } from "react";
import {
  Switch,
  message,
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  notification,
  InputNumber,
  Empty,
} from "antd";
import { fetchData } from "../../../lib/apis";
import { LoadSpinner } from "../../../widgets";

export default class AdminProxy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      listHtml: [],
      arrIdData: [],
      visible: false,
      confirmLoading: false,
      modalText: "Thêm proxy",
      last_time_connect: 0,
      total: 0,
      loading: false
    };
    this.limit = 20;
    this.page = 1;
    this.next_page = true;

    this.formH = null;
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentWillMount() {
    this.getListData();
  }
  componentWillReceiveProps(nextProps) {}
  componentDidMount() {}
  getListData = async (options = {}) => {
    try {
      this.setState({ loading: true });
      let res = await fetchData({
        url: `api/v1/admin/proxy/list?limit=${this.limit}&page=${this.page}`,
      });
      if (this.limit != res.data.length){
        this.next_page = false;
      } else {
        this.next_page = true;
      }
      let listData = options['type'] == 'reset' ? res.data : this.state.listData.concat(res.data);
      listData.sort((a, b) => {
        return b.time_create - a.time_create;
      });
      let array_id = [];
      let data = [];
      listData.forEach((item, i) => {
        if (array_id.indexOf(item._id) == -1) {
          array_id.push(item._id);
          data.push(item);
        }
      });
      this.setState(
        {
          listData: data,
          total: res.total,
          loading: false,
        }
      );
    } catch (err) {
      message.warning({
        content: "Đã có lỗi xảy ra, vui lòng thử lại sau!",
        key: "error_api",
      });
    }
  };
  showModal = () => {
    this.setState({
      visible: true,
      accIdEdit: null,
      modalText: "Thêm proxy",
    });
  };

  handleSubmit = async () => {
    try {
      const values = await this.formH.validateFields();
      this.setState(
        {
          confirmLoading: true,
        },
        () => {
          fetchData({
            url: `api/v1/admin/proxy/create`,
            method: "post",
            data: values,
          })
            .then((res) => {
              message.warning({
                content: "Thao tác thành công",
                duration: 2,
                key: "proxy",
              });
              this.setState(
                {
                  visible: false,
                  confirmLoading: false,
                },
                () => {
                  if (this.formH) this.formH.resetFields();
                  this.page = 1;
                  this.getListData({type: 'reset'});
                }
              );
            })
            .catch((err) => {
              this.setState(
                {
                  confirmLoading: false,
                },
                () => {
                  let content = err.message
                    ? err.message
                    : "Đã có lỗi xảy ra vui lòng thử lại sau!";
                  message.warning({
                    content: content,
                    duration: 2,
                    key: "proxy",
                  });
                }
              );
            });
        }
      );
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState(
      {
        visible: false,
      },
      () => {
        if (this.formH) this.formH.resetFields();
      }
    );
  };
  onFinish = (values) => {
    console.log("Success:", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  onChange = (e) => {
    console.log("checked = ", e.target.checked);
    this.setState(
      {
        checkFormAddCookie: !this.state.checkFormAddCookie,
      },
      () => {
        if (this.formH) this.formH.resetFields();
        console.log(this.state.checkFormAddCookie);
      }
    );
  };
  componentWillUnmount = () => {
    if (this.getDataRealTime) clearTimeout(this.getDataRealTime);
  };
  handleScroll(e) {
    if (
      e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop &&
      this.next_page
    ) {
      this.page += 1;
      this.next_page = false;
      e.target.scrollTop -= 50;
      this.getListData();
    }
  }
  onCheckAllChange = e => {
    let arrIdData = this.state.arrIdData;
    let listData = this.state.listData;
    listData.forEach((item, i ) => {
      if(e.target.checked) {
        if(arrIdData.indexOf(item._id) == -1){
          arrIdData.push(item._id);
        }
      } else {
        arrIdData = [];
      }
    })
    this.setState({
      checkAll: e.target.checked,
      arrIdData: arrIdData
    });
  };
  remove = async (array_id = []) => {
    message.loading({content: 'loading..', duration: 10, key: 'proxy'});
    try {
      await fetchData({ url: 'api/v1/admin/proxy/delete_many', method: 'delete', data: {ids: array_id}});
      message.success({ content: 'Thao tác thành công', key: 'proxy'});
      this.page = 1;
      this.getListData({type: 'reset'});
    } catch (err){
      message.warning({
        content: err.message ? err.message : "Đã có lỗi xảy ra, vui lòng thử lại sau!",
        key: "proxy",
      });
    }
  }
  render() {
    return (
      <div
        className="content-m list-proxy"
        onScroll={(e) => this.handleScroll(e)}
      >
        <LoadSpinner show={this.state.loading} />
        <Modal
          title={this.state.modalText}
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          className={"form-modal"}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Đóng
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={this.state.confirmLoading}
              onClick={this.handleSubmit}
            >
              Hoàn tất
            </Button>,
          ]}
        >
          <Form
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
            ref={(evt) => (this.formH = evt)}
            fields={[
              {
                name: "max_uses",
                value: 5,
              },
            ]}
            layout="vertical"
          >
            <Form.Item
              name="list_proxy"
              rules={[{ required: true, message: "Proxy không được để trống" }]}
            >
              <Input.TextArea
                rows={5}
                placeholder="host | port | username | password"
              />
            </Form.Item>
            <Form.Item name="max_uses" label="Tối đa">
              <InputNumber min={1} style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Modal>
        <div className="list-c">
          {this.state.listData.length > 0 ? (
            <div>
              <button className="btn-action" onClick={this.showModal}>
                Thêm proxy ({this.state.total})
              </button>
              <button
                className="btn-action "
                style={{ marginLeft: "10px", backgroundColor: "#ee4b61" }}
                onClick={() => this.remove(this.state.arrIdData)}
              >
                Xóa
              </button>
            </div>
          ) : null}
          {this.state.listData.length > 0 ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <th className="text-center">
                      <Checkbox
                        // indeterminate={true}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                      />
                    </th>
                    <th>Ip</th>
                    <th>tk:mk</th>
                    <th>Đang sử dụng</th>
                    <th>Tối đa</th>
                  </tr>
                </thead>
                <tbody>{this.state.listData.map((item, i) => {
                  return <tr key={"key_" + item._id}>
                    <td className="text-center">
                      <Checkbox
                        onChange={(e) => {
                          let arrIdData = this.state.arrIdData;
                          console.log(arrIdData, item._id, arrIdData.indexOf(item._id));
                          if(e.target.checked) {
                            arrIdData.push(item._id);
                          } else {
                            if(arrIdData.indexOf(item._id) != -1){
                              arrIdData.splice(arrIdData.indexOf(item._id), 1);
                            }
                          }
                          this.setState({arrIdData: arrIdData, checkAll: false})
                        }}
                        checked={this.state.arrIdData.indexOf(item._id) != -1}
                      />
                    </td>
                    <td>{`${item.host}:${item.port}`}</td>
                    <td>{`${item.username}:${item.password}`}</td>
                    <td>{item.count_uses}</td>
                    <td>{item.max_uses}</td>
                  </tr>
                })}</tbody>
              </table>
            </div>
          ) : (
            <div className="empty-content">
              <Empty
                imageStyle={{
                  height: 150,
                }}
                description={"không có dữ liệu"}
              />
              <button className="btn-action" onClick={this.showModal}>
                Thêm proxy
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
