import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";

const Widget = (props) => {
    return <React.Fragment>
        <Table
                dataSource={props.data}
                columns={[
                    {
                        title: "Nhân viên",
                        dataIndex: "sale",
                        render: (value) => value.fullname
                    },
                    {
                        title: "Khách cũ",
                        dataIndex: "cus_old"
                    },
                    {
                        title: "Khách mới",
                        dataIndex: "cus_new"
                    },
                    {
                        title: "Tổng",
                        dataIndex: "total"
                    }
                ]}
                pagination={false}
                scroll={{ x: true }}
            />
    </React.Fragment>;
};

export default Widget;
