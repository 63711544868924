import React, { Component } from 'react';
import { Link, Switch, Route } from 'react-router-dom';

import { Button, Input, Form, Modal, Select, Pagination, Tooltip, Alert, Upload, Table, InputNumber, Tag } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import { fetchData } from '../../../lib/apis';
import qs from 'qs';

import SettingView from './setting';
import AutoSmsView from './autosms';
import ContentSampleView from './content_sample';
import LogAutoSmsView from "./log_autosms";

import cx from "classnames";

export default class BrandnameView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            loading: false,
        }
    }
    componentWillMount = () => {
        this.getListData();
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            let params = {}

            let res = await fetchData({
                url: `api/v1/sms_brandname?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    render() {
        let props_m = {
            ...this.props,
            listBrandName: this.state.listData,
            getListData: this.getListData
        }
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    {/* <div className="menu-tab">
                        <ul>
                            <li className={cx(window.location.pathname == "/channel/smsbrandname" ? "active" : "", "pl-0")}>
                                <Link to={'/channel/smsbrandname'}>Cấu hình chung</Link>
                            </li>
                            
                        </ul>
                    </div> */}
                    <SettingView {...props_m} />
                </div>
            </div>
        )
    }
}
