import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const CareBookingComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_care_booking')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_care_booking"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "STT",
                    responsive: ["md"],
                    render: (item, value, index) => index + 1
                },
                {
                    title: "SDT",
                    responsive: ["md"],
                    dataIndex: "phone"
                },
                {
                    title: "Số nhân viên",
                    responsive: ["md"],
                    dataIndex: "extnum"
                },
                {
                    title: "Mã lịch hẹn",
                    dataIndex: "booking",
                    render: (item) => item.booking_code
                },
                {
                    title: "Khách hàng",
                    dataIndex: "booking",
                    responsive: ["md"],
                    render: (item) => item.fullname
                },
                {
                    title: "Cuộc gọi",
                    dataIndex: "recordfile",
                    render: (item) => {
                        return <audio controls="controls" name="media" className="audio" src={item}><source src={item} type="audio/*" /></audio>
                    }
                },
                {
                    title: "Thời gian",
                    dataIndex: "timestamp",
                    render: (item) => {
                        return moment(item).format("HH:mm DD-MM-YYYY")
                    }
                }
            ]}
            pagination={false}
        />
    </div>
}

export default CareBookingComponent;