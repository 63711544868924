import React, { useEffect, useState, useMemo, useRef } from "react";
import {
    Empty,
    Modal,
    Spin,
    message,
    Steps,
    Divider,
    Image,
    Form,
    DatePicker,
    Select,
    Button,
    Radio,
} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/vi_VN";

import { apis, fetchData } from "../../../../lib/apis";
import config from "../../../../lib/config";
import { ConvertImgBase64 } from "../../../../lib/helpers";

const WidgetFormUpdate = (props) => {
    const { visible, onCancel, faceData, onFinish, type } = props;

    const [loading, setloading] = useState(false);
    const [data, setdata] = useState({
        loading: false,
        data: [],
    });

    const refForm = useRef();

    useEffect(() => {
        if (refForm.current) {
            refForm.current.resetFields();
        }
        if (faceData) {
            getListData();
        }
    }, [visible, faceData]);

    const submitData = async (values) => {
        try {
            setloading(true);

            let response = await fetchData({
                url: apis.app.face_id.index,
                method: "put",
                data: {
                    ...values,
                    face_data: faceData,
                    face_id: faceData.face_id,
                },
            });
            message.success("Thao tác thành công");
            onCancel();
            onFinish();
        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
        } finally {
            setloading(false);
        }
    };

    const getListData = async () => {
        try {
            setdata({
                data: [],
                loading: true,
            });

            let params = {
                date: moment(faceData.timestamp).format("YYYY-MM-DD"),
            };
            let response = await fetchData({
                url: apis.app.thuphi.list_bn_thuphi_goimo,
                params,
            });

            setdata({
                loading: false,
                data: response.data,
            });
        } catch (err) {
            message.error(err.message || "Đã có lỗi xảy ra");
            setdata({
                ...data,
                loading: false,
            });
        }
    };

    return (
        <Modal
            title={`Chỉ định dữ liệu`}
            visible={visible}
            onCancel={onCancel}
            footer={null}
            width={600}
        >
            {faceData && (
                <Spin spinning={data.loading}>
                    <Form
                        ref={refForm}
                        onFinish={submitData}
                        layout="vertical"
                        initialValues={{
                            is_warning: true,
                        }}
                    >
                        <div className="mb-3 text-left">
                            <Image className="rounder" src={ConvertImgBase64(faceData.images[0])} width={120} />
                        </div>
                        {type == 1 ? (
                            <Form.Item name="is_warning" label="Cảnh báo:">
                                <Radio.Group>
                                    <Radio value={true}>Cảnh báo</Radio>
                                    <Radio value={false}>Không cảnh báo</Radio>
                                </Radio.Group>
                            </Form.Item>
                        ) : (
                            <Form.Item name="booking_code" style={{ minWidth: "160px" }}>
                                <Select
                                    placeholder="Chọn thông tin"
                                    showSearch
                                    filterOption={(input, option) => {
                                        return (option?.label ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }}
                                    options={data.data.map((item) => {
                                        return {
                                            value: item.booking_code,
                                            label: `${item.so_dien_thoai} | ${item.booking_code} | ${item.ho_ten} | ${item.ngay_sinh}`,
                                        };
                                    })}
                                />

                                {/* <Radio.Group >
                                {
                                    data.data.map((item) => {
                                        return <Radio value={item.so_dien_thoai}>{item.so_dien_thoai} | {item.ho_ten} | {item.ngay_sinh}</Radio>
                                    })
                                }
                            </Radio.Group> */}
                            </Form.Item>
                        )}
                        <Form.Item className="text-right mb-0">
                            <Button htmlType="submit" loading={loading} type="primary">
                                Tiếp tục
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            )}
        </Modal>
    );
};

export default WidgetFormUpdate;
