import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const RatingComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_rating')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>
        <Table
            id="statistic_rating"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Chi nhánh (alias)",
                    dataIndex: "alias",
                    responsive: ["md"]
                },
                {
                    title: "Khách hàng",
                    dataIndex: "user_id"
                },
                {
                    title: "Hài lòng",
                    dataIndex: "result_rating",
                    render: (item) => {
                        return item === 1 ? <i className="far fa-check"></i> : null
                    }
                },
                {
                    title: "Không Hài lòng",
                    dataIndex: "result_rating",
                    render: (item) => {
                        return item === 2 ? <i className="far fa-check"></i> : null
                    }
                },
            ]}
            pagination={false}
            summary={pageData => {
                let total_1 = 0;
                let total_2 = 0;

                pageData.forEach(({ result_rating }) => {
                    total_1 += result_rating === 1 ? 1 : 0;
                    total_2 += result_rating === 2 ? 1 : 0;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_1}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_2}</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />
    </div>
}

export default RatingComponent;