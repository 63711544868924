import { Modal, Input, Form, Button } from "antd";
import React, { useMemo } from "react";
import { apis } from "../../../../lib/apis";

const WidgetResultExport = (props) => {
    const { visible, onCancel, file } = props;

    const fileText = useMemo(() => {
        return `${apis.host}${file}`
    }, [ visible, file ])
    
    return <Modal
        title="Copy file này gián vào trình duyệt"
        visible={visible}
        onCancel={onCancel}
        footer={[null, <Button onClick={onCancel}>Đóng</Button>]}
    >
        <Form.Item>
            <Input value={fileText} addonAfter={<Button size="small" type="text" onClick={() => props.copyText(fileText)}>Copy</Button>} />
        </Form.Item>
    </Modal>
}

export default WidgetResultExport;