import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Result, Button} from 'antd';

export default class OtherView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {

    }
    componentDidMount() {

    }
    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c d-flex align-items-center justify-content-center">
                    <Result
                        title="Vui lòng liên hệ quản trị để được mở tính năng này."
                        extra={
                        <Button type="primary" key="console">
                            <Link to="/me">Trang chủ</Link>
                        </Button>
                        }
                    />
                </div>
            </div>
        )
    }
}
