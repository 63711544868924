import React, { Component } from 'react';
import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, Pagination, Tooltip, Select} from 'antd';
import { fetchData } from '../../../lib/apis';
import { ExclamationCircleOutlined, FacebookFilled} from '@ant-design/icons';
import qs from 'qs';
import moment from 'moment';
import { ModelGroupChannel, WidgetModalAliasChannel} from '../../../widgets';

export default class ManageChannel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            channelGroup: [],
            channelAlias: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount() {
        this.getListData();
    }
    componentWillReceiveProps(nextProps) {
        
    }
    componentDidMount() {

    }
    getListData = async (options = {}) => {
        try {
            this.setState({ loading: true });
            let { type } = options;
            if(type == 'reset') this.page = 1; 
            let params = {
                acc_type: 5,
                limit: this.limit,
                page: this.page
            }
            if(this.formFilter) params = {...params, ...this.formFilter.getFieldsValue()};

            let res = await fetchData({
                url: `api/v1/channel/list?${qs.stringify(params)}`
            });

            this.setState({
                listData: res.data,
                total: res.total,
                channelGroup: res.channel_groups,
                channelAlias: res.alias,
                loading: false
            }, () => {
                this.props.handleChecked(null, document.querySelector('[name="checkall"]'));
            })
        } catch (err) {
            this.setState({ loading: false });
            if (err.code && this.props.handleViewError && this.page == 1) this.props.handleViewError(err.code);
        }
    }
    
    loginFb = () => {
        let _this = this;
        window.FB.login(function (response) {
            if (response.status == 'connected') {
                console.log(response);
                var key = 'updatable';
                message.loading({ content: 'Đang xử lý..', key });
                fetchData({
                    url: 'api/v1/channel/fanpagefb/add',
                    method: 'post',
                    data: { token: response.authResponse.accessToken }
                }).then((res) => {
                    message.success({ content: 'Thành công!', key, duration: 2 });
                    _this.page = 1;
                    _this.getListData({ type: 'reset' });
                }).catch((err) => {
                    message.warning({ content: 'Đã có lỗi xảy ra!', key, duration: 2 });
                })
            } else {
                // if(props.showNotiFy) props.showNotiFy('Đăng nhập không thành công!');
            }
        }, {
            // scope: 'public_profile,email,manage_pages,publish_pages,read_page_mailboxes,pages_messaging',
            scope: `public_profile,email,pages_messaging,pages_manage_engagement,pages_read_user_content,pages_read_engagement,pages_manage_metadata,pages_manage_posts`,
            return_scopes: true,
            enable_profile_selector: true,
            auth_type: 'rerequest'
        })
    }
    
    deleteMany = (options = {}) => {
        let { ids } = options;
        if(!ids || ids.length <= 0){
            this.props.notification({
                title: "Xóa Fanpage",
                content: "Chọn ít nhất một đối tượng",
                type: "error"
            })
            return
        }
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await fetchData({
                            url: `api/v1/channel/delete/`,
                            method: 'delete',
                            data: { ids: ids }
                        })
                        document.querySelector('[name="checkall"]').click();
                        _this.props.notification({
                            title: "Xóa Fanpage",
                            content: "Thao tác thành công",
                            type: "success"
                        })
                        _this.getListData();
                        return resolve(true);
                    } catch (err) {
                        _this.props.notification({
                            title: "Xóa Fanpage",
                            content: err.message ? err.message : 'Đã có lỗi xảy ra!',
                            type: "error"
                        })
                        return reject(false);
                    }
                })
            },
            onCancel() { },
        });
    }
    
    componentWillUnmount = () => {

    }

    handleFilterData = ({type, input}) => {
        this.page = 1;
        if(type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text'){
            if(this.timeSearch) clearTimeout(this.timeSearch);
                this.timeSearch = setTimeout(() => {
                    this.getListData({ type: 'reset'});
            }, 300);
        } else{
            this.getListData({ type: 'reset'});
        }
    }
    enableChannel = async (option = {}) => {
        try{
            let { ids, enable, mutiple} = option;
            if(!ids || ids.length <= 0) throw({ message: "Chọn ít nhất 1 đối tượng"});

            await fetchData({
                url: `api/v1/channel/enable`,
                method: 'post',
                data: { enable: enable, ids: ids }
            })

            if(mutiple) this.getListData();

            this.props.notification({
                title: "Kích hoạt",
                content: "Thao tác thành công",
                type: "success",
                key: "active"
            })
        } catch(err){
            this.props.notification({
                title: "Kích hoạt",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    update = async (values, reload = true) => {
        try{
            this.setState({ loadingEdit: true });
            await fetchData({
                url: `api/v1/channel/${values['_id']}`,
                method: 'put',
                data: values
            })
            let index = this.state.listData.findIndex((item) => item._id == values['_id']);
            if(index > -1){
                this.state.listData[index] = {
                    ...this.state.listData[index],
                    ...values
                }
            }
            this.setState({
                loadingEdit: false
            })
            if(reload) this.getListData();
            this.props.notification({
                title: "Cập nhập",
                content: 'Thao tác thành công!'
            })
        } catch(err){
            this.setState({ loadingEdit: false });
            this.props.notification({
                title: "Cập nhập",
                content: err.message || "Thao tác thất bại",
                type: "error"
            })
        }
    }
    createGroupChannel = async  (values) => {
        try{
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'create_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/group/channel`,
                method: 'post',
                data: values
            })
            if(response.data){
                this.setState({
                    channelGroup: [response.data, ...this.state.channelGroup]
                })
            }
            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'create_group_channel'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'create_group_channel'
            })
        }
    }
    deleteGroupChannel = async  (id) => {
        try{
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'edit_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/group/channel/${id}`,
                method: 'delete'
            })
            
            let channelGroup = this.state.channelGroup;
            let findIndex = channelGroup.findIndex((item) => item._id == id);
            if(findIndex > -1){
                channelGroup.splice(findIndex, 1);
                this.setState({ channelGroup })
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'edit_group_channel'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'edit_group_channel'
            })
        }
    }
    showEditGroupChannel = (id) => {
        try{
            let find = this.state.channelGroup.findIndex((item) => item._id == id);
            if(find > -1){
                this.state.channelGroup[find]['edit'] = true;
                this.setState({
                    reload: !this.state.reload
                })
            }
        } catch(err){

        }
    }
    saveGroupChannel = async (e, id) => {
        try{
            let name = e.target.closest('tr').querySelector('[name="name"]').value;
            if(!name) throw({message: 'Tên không hợp lệ!'})
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'edit_group_channel'
            })
            let response = await fetchData({
                url: `api/v1/group/channel/${id}`,
                method: 'put',
                data: {
                    name: name
                }
            })
            if(response.data){
                let channelGroup = this.state.channelGroup;
                let findIndex = channelGroup.findIndex((item) => item._id == id);
                if(findIndex > -1){
                    channelGroup[findIndex]['edit'] = false;
                    channelGroup[findIndex]['name'] = name;
                    this.setState({ channelGroup })
                }
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'edit_group_channel'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'edit_group_channel'
            })
        }
    }
    createAliasChannel = async  (values) => {
        try{
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'create'
            })
            let response = await fetchData({
                url: `api/v1/channel/alias`,
                method: 'post',
                data: values
            })
            if(response.data){
                this.setState({
                    channelAlias: [response.data, ...this.state.channelAlias]
                })
            }
            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'create'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'create'
            })
        }
    }
    deleteAliasChannel = async  (id) => {
        try{
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'alias'
            })
            let response = await fetchData({
                url: `api/v1/channel/alias/${id}`,
                method: 'delete'
            })
            
            let channelAlias = this.state.channelAlias;
            let findIndex = channelAlias.findIndex((item) => item._id == id);
            if(findIndex > -1){
                channelAlias.splice(findIndex, 1);
                this.setState({ channelAlias })
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'alias'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'alias'
            })
        }
    }
    showEditAliasChannel = (id) => {
        try{
            let find = this.state.channelAlias.findIndex((item) => item._id == id);
            if(find > -1){
                this.state.channelAlias[find]['edit'] = true;
                this.setState({
                    reload: !this.state.reload
                })
            }
        } catch(err){

        }
    }
    saveAliasChannel = async (e, id) => {
        try{
            let name = e.target.closest('tr').querySelector('[name="name"]').value;
            if(!name) throw({message: 'Tên không hợp lệ!'})
            this.props.message({
                type: 'loading',
                duration: 20,
                content: 'Đang xử lý..',
                key: 'alias'
            })
            let response = await fetchData({
                url: `api/v1/channel/alias/${id}`,
                method: 'put',
                data: {
                    name: name
                }
            })
            if(response.data){
                let channelAlias = this.state.channelAlias;
                let findIndex = channelAlias.findIndex((item) => item._id == id);
                if(findIndex > -1){
                    channelAlias[findIndex]['edit'] = false;
                    channelAlias[findIndex]['name'] = name;
                    this.setState({ channelAlias })
                }
            }

            this.getListData();

            this.props.message({
                type: 'success',
                duration: 2,
                content: 'Thao tác thành công',
                key: 'alias'
            })
        } catch(err){
            this.props.message({
                type: 'error',
                duration: 2,
                content: err.message || 'Thao tác thất bại',
                key: 'alias'
            })
        }
    }
    syncFanpage = () => {
        if(this.props.UserLogin.is_test == 1){

        } else{
            // this.loginFb();
        }
    }
    render() {
        return (
            <div className="list-connect content-m">
                <ModelGroupChannel visible={this.state.visibleModalGroupChannel} hide={() => this.setState({ visibleModalGroupChannel: false})}
                    data={this.state.channelGroup} create={this.createGroupChannel} delete={this.deleteGroupChannel} showEdit={this.showEditGroupChannel}
                    save={this.saveGroupChannel}
                />
                <WidgetModalAliasChannel visible={this.state.visibleModalAliasChannel} hide={() => this.setState({ visibleModalAliasChannel: false})}
                    data={this.state.channelAlias} create={this.createAliasChannel} delete={this.deleteAliasChannel} showEdit={this.showEditAliasChannel}
                    save={this.saveAliasChannel}
                />
                <Modal
                    title={'Chỉnh sửa'}
                    visible={this.state.visibleFormEdit}
                    onCancel={() => this.setState({ visibleFormEdit: null })}
                    footer={null}
                >
                    <Form
                        name="basic"
                        ref={(evt) => this.formEdit = evt}
                        onFinish={this.update}
                        initialValues={{
                            alias: ""
                        }}
                        layout="vertical"
                    >
                        <Form.Item noStyle name="_id">
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item name="alias" label="Alias">
                            <Select allowClear={true}>
                                <Select.Option value="">Chọn alias</Select.Option>
                                {
                                    this.state.channelAlias.map((item, i) => {
                                        return <Select.Option key={i} value={item._id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item name="groups" label="Nhóm">
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Chọn nhóm"
                            >
                                {
                                    this.state.channelGroup.map((item, i) => {
                                        return <Select.Option key={i} value={item._id}>{item.name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        {
                            this.props.UserLogin.is_test != 1 && <Form.Item label="Cookie (Sử dụng cho tin nhắn sau 24h)" name="cookie.value">
                                <Input.TextArea rows={3}/>
                            </Form.Item>
                        }
                        <div className="d-flex align-items-center">
                            <label style={{fontWeight: "500", display: "inline-block", margin: "5px 10px 0px 0px"}}>Auto ẩn comment:</label>
                            <Form.Item name="hidden_cmt" valuePropName="checked" noStyle>
                                <Checkbox></Checkbox>
                            </Form.Item>
                        </div>
                        <div style={{ textAlign: "right" }} className="mt-4">
                            <Button onClick={() => this.setState({ visibleFormEdit: null })} style={{ marginRight: "10px" }}>
                                Đóng
                            </Button>
                            <Button htmlType="submit" type="primary" loading={this.state.loadingEdit}>
                                Cập nhập
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <div className="list-c">
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Quản lý fanpage: {this.state.total}</h3>     
                        <div className="d-flex overflow-auto" style={{marginBottom: "15px"}}>
                            {
                                this.props.UserLogin.is_test == 1 ? 
                                <a className="btn btn-primary text-white mr-2 d-flex align-items-center" onClick={this.loginFb}><FacebookFilled style={{marginRight: "5px", fontSize: "20px"}}/> Login with Facebook</a>
                                : <Tooltip title={'Cập nhập fanpage'}>
                                <a className="btn btn-primary text-white mr-2 d-flex align-items-center" onClick={this.loginFb}><FacebookFilled style={{marginRight: "5px", fontSize: "20px"}}/> Cập nhập fanpage</a>
                            </Tooltip>
                            }
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Xóa nhiều'}>
                                <a className="btn btn-danger text-white mr-2" onClick={() => {
                                    let ids = []
                                    document.querySelectorAll("[name='check_item']:checked").forEach((item) => {
                                        ids.push(item.value);
                                    })
                                    this.deleteMany({
                                        ids: ids
                                    })
                                }}><i className="far fa-trash"></i></a>
                            </Tooltip>
                            <Tooltip title={'Quản lý nhóm'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleModalGroupChannel: true })}><i className="far fa-users"></i></a>
                            </Tooltip>
                            <Tooltip title={'Quản lý alias'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleModalAliasChannel: true })}><i className="far fa-tags"></i></a>
                            </Tooltip>
                            <Tooltip title={'Kích hoạt'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => {
                                    let ids = []
                                    document.querySelectorAll("[name='check_item']:checked").forEach((item) => {
                                        ids.push(item.value);
                                    })
                                    this.enableChannel({
                                        ids: ids,
                                        enable: 1,
                                        mutiple: true
                                    })
                                }}><i className="far fa-check"></i></a>
                            </Tooltip>
                            <Tooltip title={'Tắt kích hoạt'}>
                                <a className="btn btn-danger text-white" onClick={() => {
                                    let ids = []
                                    document.querySelectorAll("[name='check_item']:checked").forEach((item) => {
                                        ids.push(item.value);
                                    })
                                    this.enableChannel({
                                        ids: ids,
                                        enable: 0,
                                        mutiple: true
                                    })
                                }}><i className="far fa-ban"></i></a>
                            </Tooltip>
                        </div>                       
                        <Form
                            ref={(evt) => this.formFilter = evt }
                            initialValues={{
                                group: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="keyword">
                                                <Input placeholder="Nhập tên fanpage"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="group">
                                                <Select style={{ width: "100%"}} onChange={(e) => this.handleFilterData({type: 'select', value: e})}>
                                                    <Select.Option value="">Chọn nhóm</Select.Option>
                                                    {
                                                        this.state.channelGroup.map((item) => {
                                                            return <Select.Option key={item._id} value={item._id}>{item.name}</Select.Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>
                                            <label className="css-control css-control-primary css-checkbox p-0">
                                                <input name="checkall" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked}/>
                                                <span className="css-control-indicator"></span>
                                            </label>
                                        </th>
                                        <th>Stt</th>
                                        <th>Ảnh</th>
                                        <th>ID</th>
                                        <th>Tên</th>
                                        <th>Alias</th>
                                        <th>Nhóm</th>
                                        <th>Kích hoạt</th>
                                        <th className="text-center">Auto ẩn comment</th>
                                        <th>Thời gian</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id+'_'+Date.now()}>
                                                    <td>
                                                        <label className="css-control css-control-primary css-checkbox p-0">
                                                            <input name="check_item" type="checkbox" className="css-control-input checkbox" onChange={this.props.handleChecked} value={item._id}/>
                                                            <span className="css-control-indicator"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>
                                                        <div style={{width: "35px", height: "35px", position: "relative"}}>
                                                            {
                                                                item.avatar ? <img className="avatar" src={item.avatar} /> : <img className="avatar" src={require('../../../img/user.jpg')} />
                                                            }
                                                            {
                                                                item.status == 1 ? <span style={{position: "absolute", bottom: "0px", right: "0px"}} className={`badge-status badge badge-success`}></span> : <Tooltip title={item.log}>
                                                                    <span style={{position: "absolute", bottom: "0px", right: "0px"}} className={`badge-status badge badge-danger`}></span>
                                                                </Tooltip>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{item.user_id}</td>
                                                    <td>
                                                        {item.full_name}
                                                    </td>
                                                    <td>{item.alias}</td>
                                                    <td>
                                                        {
                                                            item.groups ? item.groups.map((group, i) => {
                                                                return <span key={group._id}>{group.name}{ i < item.groups.length - 1 ? ', ' : '' }</span>
                                                            }) : ""
                                                        }
                                                    </td>
                                                    <td>
                                                        <Switch defaultChecked={item.enable == 1 ? true : false} onChange={(e) => this.enableChannel({ enable: e ? 1 : 0, ids: [item._id]})}/>
                                                    </td>
                                                    <td className="text-center">
                                                        <Checkbox checked={item.hidden_cmt} onChange={(e) => {
                                                            this.update({
                                                                _id: item._id,
                                                                hidden_cmt: !item.hidden_cmt
                                                            }, false)
                                                        }}/>
                                                    </td>
                                                    <td>{moment(item.updated_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Xóa">
                                                            <Button size="small" type="danger" className="mr-2" onClick={() => this.deleteMany({ ids: [item._id]})}><i className="far fa-trash-alt"></i></Button>
                                                        </Tooltip>
                                                        <Tooltip title="Chỉnh sửa">
                                                            <Button size="small" type="primary" onClick={() => {
                                                                this.setState({
                                                                    visibleFormEdit: true
                                                                }, async () => {
                                                                    if(!this.formEdit){
                                                                        await this.props.sleep(100)
                                                                    }
                                                                    let group_ids = item.groups ? item.groups.map((group) => { return group._id }) : [];
                                                                    this.formEdit.setFieldsValue({
                                                                        _id: item._id,
                                                                        groups: group_ids,
                                                                        alias: item.alias,
                                                                        hidden_cmt: item.hidden_cmt,
                                                                        "cookie.value": item.cookie && item.cookie.value
                                                                    })
                                                                })
                                                            }}><i className="far fa-edit"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table> 
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
