import React, { Component } from 'react';
import { Button, Input, Form, Modal, Switch, Select, Radio, DatePicker, TimePicker, Spin, Popover, InputNumber, Dropdown, Menu, Pagination, Tooltip, Alert, Upload, Checkbox } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, ReloadOutlined, PlusOutlined, InfoCircleFilled, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';
import { AccountMetaData } from '../../../config/settings';
import { WidgetModalSpinText } from '../../../widgets';

const { Option } = Select;

export default class AutoReplyView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPages: [],
            listData: [],
            total: 0,
            loading: false,
            fileList: []
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getListPages();
    }
    componentDidMount = () => {

    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/chat/auto_reply?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getListPages = async (keyword = '') => {
        try {
            let res = await fetchData({
                url: `api/v1/channel/list?acc_type=5&limit=10&keyword=${keyword}`
            });
            this.setState({ listPages: res.data });
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Tìm fanpage',
                type: 'error'
            })
        }
    }

    remove = async (id) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn xóa',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            className: "modal-confirm-h",
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/chat/auto_reply/${id}`,
                            method: 'delete'
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Xóa',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Xóa',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleStatus = async (id, data) => {
        let _this = this;
        Modal.confirm({
            title: 'Bạn có chắc muốn cập nhập',
            icon: <ExclamationCircleOutlined />,
            okText: 'Tiếp tục',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        let res = await fetchData({
                            url: `api/v1/broadcast/status/${id}`,
                            method: 'post',
                            data: data
                        });
                        _this.getListData();
                        _this.props.notification({
                            content: 'Thao tác thành công!',
                            title: 'Cập nhập chiến dịch',
                            type: 'success'
                        })
                        resolve();
                    } catch (err) {
                        _this.props.notification({
                            content: err.message || 'Đã có lỗi xảy ra',
                            title: 'Cập nhập chiến dịch',
                            type: 'error'
                        })
                        reject();
                    }
                })
            },
            onCancel() { },
        });
    }
    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true});
            if (values['attachments']) {
                let attachments = [];
                if (values['attachments']['fileList']) {
                    values['attachments']['fileList'].forEach((item) => {
                        if (item.status == "done") {
                            attachments.push(item.response ? {
                                type: item.response.attachment.type,
                                url: item.response.attachment.url
                            } : { type: item.type, url: item.url })
                        }
                    })
                } else {
                    values['attachments'].forEach((item) => {
                        attachments.push({ type: item.type, url: item.url });
                    })
                }
                values['attachments'] = attachments;
            }
            let url = `api/v1/chat/auto_reply/create`;
            let method = 'post';

            if (values['_id']) {
                url = `api/v1/chat/auto_reply/${values['_id']}`;
                method = 'put';
            }

            await fetchData({
                url: url,
                data: values,
                method: method
            });
            this.getListData();
            this.setState({ loadingForm: false, visibleForm: values['_id'] ? this.state.visibleForm : false });

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập kịch bản',
                type: 'success'
            })
        } catch (err) {
            this.setState({ loadingForm: false });
            this.props.notification({
                content: err.message || 'Thao tác thất bại',
                title: 'Cập nhập kịch bản',
                type: 'error'
            })
        }
    }

    render() {
        let spinText = [
            {
                value: "#{full_name}",
                text: "Tên khách hàng"
            },
            {
                value: "#{page_name}",
                text: "Tên fanpage"
            },
            {
                value: "#{first_name_member}",
                text: "Họ nhân viên"
            },
            {
                value: "#{last_name_member}",
                text: "Tên nhân viên"
            },
            {
                value: "#{company_name}",
                text: "Tên chi nhánh"
            },
            {
                value: "#{spin_1|spin_2}",
                text: "Chọn từ khóa ngẫu nhiên"
            }
        ];
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    {/* <WidgetModalSpinText visible={this.state.visibleSpinText} data={[
                        {
                            value: "#{full_name}",
                            text: "Tên khách hàng"
                        },
                        {
                            value: "#{page_name}",
                            text: "Tên fanpage"
                        },
                        {
                            value: "#{first_name_member}",
                            text: "Họ nhân viên"
                        },
                        {
                            value: "#{last_name_member}",
                            text: "Tên nhân viên"
                        },
                        {
                            value: "#{spin_1|spin_2}",
                            text: "Chọn từ khóa ngẫu nhiên"
                        }
                    ]}
                        hide={() => this.setState({ visibleSpinText: false })}
                        appendText={(value) => {
                            this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + value });
                            this.props.notification({
                                title: "Thêm spin text vào nội dung",
                                content: "Thao tác thành công",
                                type: "success",
                                placement: " bottomRight"
                            })
                        }}
                    /> */}
                    <Modal
                        title={'Cập nhập kịch bản'}
                        visible={this.state.visibleForm}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false })}
                        width={800}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            initialValues={{
                                content: '',
                                is_direct: true
                            }}
                            onFinish={this.save}
                            layout="vertical"
                        >
                            {
                                this.state.error ? <Alert message={this.state.error} type="error" /> : null
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item name="_id" noStyle>
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Loại kịch bản"
                                        style={{marginBottom: "0px!important"}}
                                    >
                                        <div className="d-flex">
                                            <Form.Item name="is_direct" style={{ marginRight: "10px"}} valuePropName="checked">
                                                <Checkbox>Tin nhắn</Checkbox>
                                            </Form.Item>
                                            <Form.Item name="is_comment" valuePropName="checked">
                                                <Checkbox>Bình luận</Checkbox>
                                            </Form.Item>
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label="Nhân viên chăm sóc"
                                        style={{marginBottom: "0px!important"}}
                                        name="is_care"
                                    >
                                        <Checkbox.Group options={[
                                            {
                                                label: "Có",
                                                value: 1
                                            },
                                            {
                                                label: "Không",
                                                value: 2
                                            }
                                        ]}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="keywords"
                                        label="Từ khóa"
                                        // rules={[{ required: true, message: 'Từ khóa không được để trống' }]}
                                    >
                                        <Input placeholder={`Mỗi từ khóa cách nhau dấu "|", ví dụ: start | bắt đầu`} />
                                    </Form.Item>
                                    <Form.Item
                                        name="channels"
                                        label="Chọn fanpage"
                                        rules={[{ required: true, message: 'Chọn ít nhất 1 fanpage' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Chọn fanpage"
                                            optionFilterProp="children"
                                            filterOption={false}
                                            onSearch={(e) => {
                                                if (this.timeout_fetchpages) clearTimeout(this.timeout_fetchpages);
                                                this.timeout_fetchpages = setTimeout(() => {
                                                    this.getListPages(e)
                                                }, 300);
                                            }}
                                            // notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                                            allowClear
                                            mode="multiple"
                                        >
                                            {this.state.listPages.map((item, i) => {
                                                return <Option key={i} value={item.user_id}>{item.username || item.full_name}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item name="time_run" label="Thời gian chạy">
                                        <Input placeholder="00-23"/>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="content"
                                        label="Nội dung"
                                        rules={[{ required: true, message: 'Nội dung không được để trống!' }]}
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <div>
                                        <table className="border-0">
                                            <tbody>
                                            {
                                                spinText.slice(0, this.state.visibleSpinText ? spinText.length : 2).map((item, i) => {
                                                    return <tr key={i}>
                                                        <td>
                                                        <a className="btn btn-default border" style={{ marginBottom: "10px", textTransform: "uppercase"}}
                                                            onClick={() => this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') +  item.value})}
                                                        >{item.value}</a> 
                                                        </td>
                                                        <td>{item.text}</td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                        {
                                            this.state.visibleSpinText ? 
                                            <div style={{ marginBottom: "10px" }}>
                                                <a href="#" style={{ textDecoration: "revert" }} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ visibleSpinText: false });
                                                }}>Thu gọn</a>
                                            </div> :
                                            <div style={{ marginBottom: "10px" }}>
                                                <a href="#" style={{ textDecoration: "revert" }} onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ visibleSpinText: true });
                                                }}>Hiển thị thêm</a>
                                            </div>
                                        }
                                    </div>
                                    <Form.Item
                                        label="Tệp đính kèm"
                                        name="attachments"
                                    >
                                        <Upload
                                            action={`${process.env.REACT_APP_API_CHOTSALE_URL}/api/v1/uploads`}
                                            listType="picture-card"
                                            fileList={[...this.state.fileList]}
                                            className="upload-list-inline"
                                            withCredentials={true}
                                            headers={{
                                                Authorization: AccountMetaData.getToken()
                                            }}
                                            ref={(evt) => this.uploadFile = evt}
                                            onRemove={(e) => {
                                                let index = this.state.fileList.findIndex((item) => item.uid == e.uid);
                                                if (index > -1) {
                                                    let fileList = this.state.fileList;
                                                    fileList.splice(index, 1);
                                                    this.setState({ fileList: fileList })
                                                }
                                            }}
                                            onChange={({ fileList }) => this.setState({ fileList })}
                                            onPreview={(file) => {
                                                let url = file.url;
                                                if (file.response && file.response.attachment) url = file.response.attachment.url;
                                                if (url) window.open(url)
                                            }}
                                            maxCount={1}
                                        >
                                            {/* <Button icon={<UploadOutlined />}>Upload</Button> */}
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>

                                        </Upload>
                                    </Form.Item>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <Button htmlType="button" style={{ margin: '0 8px' }} onClick={() => this.setState({ visibleForm: false })}>
                                    Đóng
                                    </Button>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingForm} disabled={this.state.loadingForm}>
                                    Tiếp tục
                                </Button>
                            </div>
                        </Form>
                    </Modal>
                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Trả lời tự động: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <Tooltip title={'Thêm kịch bản'}>
                                <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true, fileList: [] }, () => {
                                    if (this.formH) this.formH.resetFields();
                                })}><i className="far fa-plus"></i></a>
                            </Tooltip>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                type: "",
                                active: ""
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="channel_id">
                                                <Input placeholder="ID Fanpge" />
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="type">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Loại</Select.Option>
                                                    <Select.Option value={"is_direct"}>Tin nhắn</Select.Option>
                                                    <Select.Option value={"is_comment"}>Bình luận</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="active">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={1}>kích hoạt</Select.Option>
                                                    <Select.Option value={2}>Tắt</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Từ khóa</th>
                                        <th>Fanpage</th>
                                        <th className="text-center">Tin nhắn</th>
                                        <th className="text-center">Bình luận</th>
                                        <th className="text-center">Files</th>
                                        <th>Nội dung</th>
                                        <th className="text-center">Active</th>
                                        <th className="text-center">Ngày tạo</th>
                                        <th className="text-right">Hành động</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.keywords}</td>
                                                    <td>
                                                        <div style={{ whiteSpace: "break-spaces" }}>{item.channels.join('\n')}</div>
                                                    </td>
                                                    <td className="text-center">
                                                        {item.is_direct ? <input type="checkbox" checked={true} /> : <input type="checkbox" checked={false} />}
                                                    </td>
                                                    <td className="text-center">
                                                        {item.is_comment ? <input type="checkbox" checked={true} /> : <input type="checkbox" checked={false} />}
                                                    </td>
                                                    <td className="text-center">{item.attachments && item.attachments.length > 0 ? <input type="checkbox" checked /> : <input type="checkbox" checked={false} />}</td>

                                                    <td>
                                                        <textarea disabled className="form-control" value={item.content}></textarea>
                                                    </td>
                                                    <td className="text-center"><Switch checked={item.active == 1 ? true : false} onChange={(e) => this.save({ _id: item._id, active: e ? 1 : 2 })} /></td>
                                                    <td className="text-center">{moment(item.created_time).format('HH:mm DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <Tooltip title="Chi tiết">
                                                            <Button size="small" type="primary" className="mr-2" onClick={() => {
                                                                this.setState({
                                                                    visibleForm: true
                                                                }, () => {
                                                                    setTimeout(() => {
                                                                        try {
                                                                            this.formH.setFieldsValue(item);
                                                                            let fileList = item.attachments.map((item, i) => {
                                                                                return {
                                                                                    uid: `${i}`,
                                                                                    status: 'done',
                                                                                    url: item.url,
                                                                                    type: item.type
                                                                                }
                                                                            })
                                                                            this.setState({ fileList: fileList })
                                                                        } catch (err) {
                                                                            console.log(err);
                                                                        }
                                                                    }, 0)
                                                                })
                                                            }}><i className="far fa-eye"></i></Button>
                                                        </Tooltip>
                                                        <Tooltip title="Xóa">
                                                            <Button size="small" type="danger" onClick={() => this.remove(item._id)}><i className="far fa-trash"></i></Button>
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
