import React, { Component } from "react";
import {
  Switch,
  message,
  Modal,
  Button,
  Form,
  Input,
  Checkbox,
  notification,
  InputNumber,
  Empty,
  Tooltip,
  Select,
  Popover,
  Radio, Table, Pagination, Cascader, Tag,
  DatePicker 
} from "antd";
import { fetchData } from "../../../lib/apis";
import { LoadSpinner } from "../../../widgets";
import { formatPrice } from '../../../lib/helpers';
import config from '../../../lib/config';
import { TweenOneGroup } from 'rc-tween-one';

import moment from 'moment';
import { DownloadOutlined, SyncOutlined, UnorderedListOutlined, FilterOutlined, FileDoneOutlined,
ClockCircleOutlined, LeftOutlined, RightOutlined, VerticalAlignBottomOutlined,
DownSquareOutlined, CheckOutlined, CloseOutlined
 } from '@ant-design/icons';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import locale from 'antd/es/date-picker/locale/vi_VN';

const {Option} = Select;
const { RangePicker } = DatePicker;

const optionsFilterAdvanced = [{
  value: 'address',
  label: 'Địa chỉ',
  isLeaf: false,
  type_filter: 'address',
  type_query: 'address'
}, {
  value: 'Nhân viên',
  label: 'Nhân viên',
  isLeaf: false,
  type_filter: 'staff',
  type_query: 'staff'
}];

export default class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: [],
      arrIdData: [],
      visible: false,
      confirmLoading: false,
      last_time_connect: 0,
      total: 0,
      loading: false,
      totalPage: 1,
      optionsFilterAdvanced: optionsFilterAdvanced,
      selectedOptionsFilter: [],
      queryString: '',
      filterObj: {}
    };
    this.limit = 20;
    this.page = 1;
    this.next_page = true;

    this.formH = null;
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentWillMount() {
    this.getListData();
    // window.onmessage = (event) => {
    //   console.log(event);
    // }
  }
  componentWillReceiveProps(nextProps) {}
  componentDidMount() {}
  getListData = async (options = {}) => {
    try {
      this.setState({ loading: true });
      let query = `limit=${this.limit}&page=${this.page}`;
      for (let [key, value] of Object.entries(this.state.filterObj)) {
        value['query'].forEach((item) => {
           query += `&${item.key}=${item.value}`          
        })
      }

      let res = await fetchData({
        url: `api/v2/orders/list?${query}`,
      });
      if (this.limit != res.data.length){
        this.next_page = false;
      } else {
        this.next_page = true;
      }
      let listData = options['type'] == 'reset' ? res.data : this.state.listData.concat(res.data);
      listData.sort((a, b) => {
        return b.time_created - a.time_created;
      });
      let array_id = [];
      let data = [];
      listData.forEach((item, i) => {
        if (array_id.indexOf(item._id) == -1) {
          array_id.push(item._id);
          data.push(item);
        }
      });
      this.setState(
        {
          listData: data,
          total: res.total,
          loading: false,
          totalPage: Math.floor(res.total / res.limit)
        }
      );
    } catch (err) {
      message.warning({
        content: "Đã có lỗi xảy ra, vui lòng thử lại sau!",
        key: "orders",
      });
    }
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState(
      {
        visible: false,
      },
      () => {
        if (this.formH) this.formH.resetFields();
      }
    );
  };
  onFinish = (values) => {
    console.log("Success:", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  handleScroll(e) {
    if (
      e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop &&
      this.next_page
    ) {
      this.page += 1;
      this.next_page = false;
      e.target.scrollTop -= 50;
      this.getListData();
    }
  }
  onCheckAllChange = e => {
    let arrIdData = this.state.arrIdData;
    let listData = this.state.listData;
    listData.forEach((item, i ) => {
      if(e.target.checked) {
        if(arrIdData.indexOf(item._id) == -1){
          arrIdData.push(item._id);
        }
      } else {
        arrIdData = [];
      }
    })
    this.setState({
      checkAll: e.target.checked,
      arrIdData: arrIdData
    });
  };
  remove = async (array_id = []) => {
    message.loading({content: 'loading..', duration: 10, key: 'proxy'});
    try {
      await fetchData({ url: 'api/v1/admin/proxy/delete_many', method: 'delete', data: {ids: array_id}});
      message.success({ content: 'Thao tác thành công', key: 'proxy'});
      this.page = 1;
      this.getListData({type: 'reset'});
    } catch (err){
      message.warning({
        content: err.message ? err.message : "Đã có lỗi xảy ra, vui lòng thử lại sau!",
        key: "orders",
      });
    }
  }
  exportToCSV = () => {
    this.setState({ loadingDownloadExcel: true });
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let fileExtension = '.xlsx';
    let now = Date.now();
    let fileName = 'donhang_vnpsoftware_'+moment(now).format('DD_MM_YYYY')+'_'+now;
    let csvData = [];
    this.state.listData.forEach((item, i) => {
      let products = '';
      item.list_products.forEach((product, ii) => {
        products += `${product.name} - sl ${product.count} - ${formatPrice(product.price)}đ/1sp \n`
      })
      csvData.push({
        stt: i + 1,
        order_id: item.order_id,
        custormer_name: item.full_name,
        phone: item.phone,
        address: item.address,
        province: item.provinces ? item.provinces.name : '',
        district: item.districts ? item.districts.name : '',
        commune: item.communes ? item.communes.name: '',
        shipping_fee: item.shipping_fee,
        discount: item.discount,
        price: item.total_price,
        note: item.note,
        product: products,
        time_created: moment(item.time_created).format('H:mm DD-MM-YYYY')
      })
    })

    let ws = XLSX.utils.json_to_sheet(csvData);
    let wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    let excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    let data = new Blob([excelBuffer], {type: fileType});
    setTimeout(() => {
      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingDownloadExcel: false });
    }, 1000)

   }
  changeFilterAdvanced = (value, selectedOptions) => {
    // console.log('selectedOptions', selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    const optionsFilterAdvanced = this.state.optionsFilterAdvanced;
    if(targetOption.type_filter == 'address') {
      if(targetOption.value == 'address') {
        optionsFilterAdvanced[0]['children'] = optionsFilterAdvanced[0]['children'] ? optionsFilterAdvanced[0]['children'] : [];
        optionsFilterAdvanced[0]['children'][0] = {
          label: <Button type="primary" disabled style={{width: "100%", borderRadius: "20px"}}>Lọc</Button>,
          value: 'btn_filter_address'
        }
      } else {
        optionsFilterAdvanced[0]['children'][0] = {
          label: <Button type="primary" style={{width: "100%", borderRadius: "20px"}}>Lọc</Button>,
          value: 'btn_filter_address',
          type_filter: 'address',
          action: 'btn'
        }
      }
      if(!targetOption.action) {
        var selectedOptionsFilter = [...selectedOptions];
        selectedOptionsFilter.splice(0, 1);
        this.setState({
          selectedOptionsFilter: selectedOptionsFilter
        }, () => {
          if(targetOption.isLeaf) {
            this.setFilter({
              key: targetOption.type_filter,
              options: this.state.selectedOptionsFilter,
              label: selectedOptions[0]['label']
            });
          }
        })
      } else {
        this.setFilter({
          key: targetOption.type_filter,
          options: this.state.selectedOptionsFilter,
          label: selectedOptions[0]['label']
        });
      }
    } else {
      if(targetOption.isLeaf){
        console.log(targetOption);
        this.setFilter({
          key: targetOption.type_filter,
          options: [
            {
              ...targetOption
            }
          ],
          label: selectedOptions[0]['label']
        });
      }
    }
  };

  loadDataAdvanced = async (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.children = [];
    // console.log('loadDataAdvanced', targetOption);
    let type_address = null;
    let parent_id = ''
    if(targetOption.type_query == 'address') {
      type_address = 'provinces';
    } else if(targetOption.type_query == 'provinces'){
      type_address = 'districts';
      parent_id = targetOption.value;
    } else if(targetOption.type_query == 'districts'){
      type_address = 'communes';
      parent_id = targetOption.value;
    }
    if(type_address) {
      targetOption.loading = true;
      try {
        let res = await fetchData({url: `api/v2/geo/${type_address}?parent_id=${parent_id}`});
        if(type_address == 'provinces'){
          targetOption.children.push({
            label: <Button type="primary" disabled style={{width: "100%", borderRadius: "20px"}}>Lọc</Button>,
            value: 'btn_filter_address'
          })
        }
        res.data.forEach((item, i) => {
          targetOption.children.push({
            label: item.name,
            value: item.id,
            isLeaf: type_address == 'communes' ? true : false,
            type_filter: 'address',
            type_query: type_address
          })
        })
        targetOption.loading = false;
      } catch (err) {

      }
    }
    if(targetOption.type_filter == 'staff') {
      targetOption.loading = true;
      try {
        let res = await fetchData({url: `api/v2/staff/list`});
        res.data.forEach((item, i) => {
          targetOption.children.push({
            label: item.user_name_vnp_sw,
            value: item.id_user_vnp_sw,
            isLeaf: true,
            type_filter: 'staff',
            type_query: 'staff'
          })
        })

        targetOption.loading = false;
      } catch (err) {

      }
    }

    this.setState({
      optionsFilterAdvanced: [...this.state.optionsFilterAdvanced],
    });
  };
  forMapTag = (key, value) => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          this.handleCloseFilter(key);
        }}
        className={'order-tag-filter'}
      >
        {value.label}
      </Tag>
    );
    return (
      <span key={value.label} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };
  handleCloseFilter = (key) => {
    let filterObj = this.state.filterObj;
    delete filterObj[key];

    this.setState({ filterObj }, () => this.getListData({ type: 'reset'}));
  }
  setFilter = (options) => {
    let filterObj = this.state.filterObj;

    let label = options['label']+': ';
    let query = [];
    options['options'].forEach((item, i) => {
      label += item.label;
      if (i < options['options'].length - 1) label += ' - ';
      query.push({
        key: item.type_query,
        value: item.value
      })
    })
    filterObj[options['key']] = {
      query: query,
      label: label,
      type: 2
    }
    console.log(filterObj);
    this.setState({
      filterObj: filterObj
    }, () => {
      this.getListData({ type: 'reset'});
    })
  }
  render() {
    var dataSource = [];
    this.state.listData.forEach((item, i) => {
      dataSource.push({
        key: i,
        order_id: '#'+item.order_id,
        full_name: item.full_name,
        total_price: formatPrice(item.total_price)+'đ',
        note: item.note,
        shop_type: config.warehouse[item.shop_type] ? config.warehouse[item.shop_type] : 'Không xác định',
        time_created: moment(item.time_created).format('HH:mm DD-MM-YYYY')
      })
    })
    var tagFilters = [];
    for (let [key, value] of Object.entries(this.state.filterObj)) {
      if(value.type == 2) tagFilters.push(this.forMapTag(key, value));
    }

    // const tagFilters = this.state.filterArray.map(this.forMapTag);
    return (
      <div
        className="content-m list-h"
        // onScroll={(e) => this.handleScroll(e)}
      >
        <LoadSpinner show={this.state.loading} />
        <Modal
          title="Tải xuống excel"
          visible={this.state.visibleDownloadExcel}
          onCancel={() => {
            this.setState({
              visibleDownloadExcel: false
            });
          }}
          footer={null}
          className={'update-order-tool'}
        >
          <div className="order-export-excel-container">
            <div className="order-export-excel-header">
              <Button type="primary" icon={<VerticalAlignBottomOutlined />} onClick={() => {
                this.exportToCSV();
              }} loading={this.state.loadingDownloadExcel}>
                Tải xuống
              </Button>
            </div>
          </div>
          <div className="scan-orders">
            <div className="title">
              <div><CheckOutlined /> {this.state.listData.length} đơn được chọn</div>
            </div>
            <div className="title">
              <Button size={"small"} type="white" icon={<DownSquareOutlined />} shape={'round'}>
                Chọn toàn bộ đơn hàng theo bộ lọc hiện tại
              </Button>
            </div>
          </div>
        </Modal>
        <div className="list-c list-m">
          <div className="tools-container">
              <div style={{display: "flex", alignItems: "center"}}>
                <Tooltip placement="bottom" title={'Tải lại danh sách đơn hàng'}>
                  <Button type="primary" shape="circle" icon={<SyncOutlined />} onClick={() => {
                      this.page = 1;
                     this.getListData({type: 'reset'});
                  }}/>
                </Tooltip>
                <Tooltip placement="bottom" title={'Tải xuống excel'}>
                  <Button type="primary" shape="circle" icon={<DownloadOutlined />} onClick={() => {
                    this.setState({visibleDownloadExcel: true})
                  }}/>
                </Tooltip>
                <Tooltip placement="bottom" title={'Sắp xếp đơn hàng'}>
                  <Popover placement="rightTop" onVisibleChange={(e) => {
                    if(!e) {
                      let filterSort = this.state.filterObj['sort'];
                      let sortName = 'time_created';
                      let sortValue = -1;

                      if(filterSort) {
                        filterSort['query'].forEach((item) => {
                          if(item.key == 'sort_name') sortName = item.value;
                          if(item.key == 'sort_value') sortValue = item.value;
                        })
                      }

                      this.setState({sortName, sortValue});
                    }
                  }} content={
                    <div>
                      <div className="pop-sort-content">
                        <div className="item-content">
                          <label className="label">Sắp xếp theo</label>
                            <Radio.Group value={this.state.sortName ? this.state.sortName : 'time_created'} 
                              ref={(evt) => this.sortName = evt}
                              onChange={(e) => this.setState({sortName: e.target.value}) }
                            >
                              <Radio style={{
                                display: 'block',
                                height: '30px',
                                lineHeight: '30px',
                              }} value={'time_created'}>
                                Thời điểm tạo đơn
                              </Radio>
                            </Radio.Group>
                        </div>
                        <div className="item-content">
                          <label className="label">Chọn thứ tự sắp xếp</label>
                            <Radio.Group value={this.state.sortValue ? this.state.sortValue : -1} 
                              ref={(evt) => this.sortValue = evt}
                              onChange={(e) => this.setState({sortValue: e.target.value}) }
                            >
                              <Radio value={1}>
                                Tăng dần
                              </Radio>
                              <Radio value={-1} style={{marginLeft: "50px"}}>
                                Giảm dần
                              </Radio>
                            </Radio.Group>
                        </div>
                      </div>
                      <div className="pop-footer">
                        <Button type="primary" shape="round" icon={<FileDoneOutlined />} onClick={(e) => {
                          let sortName = this.state.sortName ? this.state.sortName : 'time_created';
                          let sortValue = this.state.sortValue ? this.state.sortValue : -1;
                          let filterObj = this.state.filterObj;
                          filterObj['sort'] = {
                            query: [{ key: 'sort_name', value: sortName}, { key: 'sort_value', value: sortValue}]
                          }
                          this.setState({filterObj: filterObj}, () => {
                            this.getListData({ type: 'reset'});
                          })
                        }}>Sắp xếp</Button>
                      </div>
                    </div>
                  } trigger="click" overlayClassName="sort-orders">
                    <Button type="primary" shape="circle" icon={<UnorderedListOutlined />} />
                  </Popover>
                </Tooltip>
                <Tooltip placement="bottom" title={'Bộ lọc nâng cao'}>
                  <Button type="primary" shape="circle" icon={!this.state.visibleFilterAd ? <FilterOutlined /> : <CloseOutlined />} onClick={() => {
                    this.setState({
                      visibleFilterAd: !this.state.visibleFilterAd
                    })
                  }}/>
                </Tooltip>
                <Tooltip placement="bottom" title={'Lọc theo khoảng thời gian'}>
                  <Popover trigger="click" placement="rightTop" title={"Chọn khoảng thời gian"} content={
                    <div>
                      <RangePicker
                        ranges={{
                          'Hôm nay': [moment(), moment()],
                          'Tháng này': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        onChange={(e) => console.log(e)}
                        locale={locale}
                        onChange={(moments, dateString) => {
                          let time_start = moments[0].startOf('day').unix() * 1000;
                          let time_end = moments[1].endOf('day').unix() * 1000;
                          this.setState({
                            time_start: time_start,
                            time_end: time_end
                          })
                        }}
                        format="DD/MM/YYYY"
                        allowClear={false}
                        separator='~'
                      />
                      <div className="text-center" style={{marginTop: "10px"}}>
                        <Button type="primary" shape="round" onClick={() => {
                          let filterObj = this.state.filterObj;
                          filterObj['time_created'] = {
                            query: [{key: 'time_start', value: this.state.time_start}, 
                            {key: 'time_end', value: this.state.time_end}]
                          }
                          this.setState(filterObj, () => {
                            this.getListData({type: 'reset'});
                          })
                        }}>Xác nhận</Button>
                      </div>
                    </div>
                  }>
                    <Button type="primary" shape="circle" icon={<ClockCircleOutlined />} />
                  </Popover>
                </Tooltip>

                <Button onClick={() => {
                  this.setState({
                    filterObj: {}
                  }, () => {
                    this.getListData({type: 'reset'});
                  })
                }} disabled={Object.keys(this.state.filterObj).length == 0 ? true : false} type="primary" shape="round">Xóa lọc</Button>

              </div>
          </div>

          <div className="tools-container" style={!this.state.visibleFilterAd ? {marginTop: "10px", borderRadius: "5px", display: "none"} : {marginTop: "10px", borderRadius: "5px"}}>
            <div style={{display: "flex"}}>
              <Cascader
                placeholder={'Thêm kiểu lọc'}
                options={this.state.optionsFilterAdvanced}
                loadData={this.loadDataAdvanced}
                onChange={this.changeFilterAdvanced}
                changeOnSelect
                value={''}
                className="input-ant-m"
                onFocus={(e) => {
                  // console.log('focus', e);
                }}
              />
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: 'from',
                  duration: 100,
                  onComplete: e => {
                    e.target.style = '';
                  },
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                appear={false}
                style={{marginLeft: "10px"}}
              >
                {tagFilters}
              </TweenOneGroup>
            </div>
          </div>
          <div className="tools-container" style={{marginTop: "10px", background: "#fff", "padding": "8px 5px", borderRadius: "5px"}}>
            <div>
                <Radio.Group defaultValue="all" buttonStyle="solid" size={'small'}>
                  <Radio.Button value="all">Tất cả ({this.state.total})</Radio.Button>
                </Radio.Group>
            </div>
            <div>
              <Button size={'small'} type="white" shape="circle" icon={<LeftOutlined />} disabled={this.page > 1 ? false : true}
                onClick={(e) => {
                  if(this.page > 1) this.page -= 1;
                  this.getListData({type: 'reset'});
                }}
              />
              <Button size={'small'} type="white" shape="circle" icon={<RightOutlined />} disabled={!this.next_page ? true : false}
                onClick={(e) => {
                  if(this.next_page) this.page += 1;
                  this.getListData({type: 'reset'});
                }}
              />
              <Select size={'small'} value={this.limit} style={{width: "80px"}} onChange={(e) => {
                this.page = 1;
                this.limit = e;
                this.getListData({type: 'reset'});
              }}>
                  <Option value={20}>20</Option>
                  <Option value={50}>50</Option>
                  <Option value={100}>100</Option>
                  <Option value={200}>200</Option>
                  <Option value={500}>500</Option>
              </Select>
            </div>
          </div>
          <div className="content-list list-orders">
              <Table columns={[
                  {
                    title: "Đơn hàng",
                    dataIndex: "order_id"
                  },
                  {
                    title: "Khách hàng",
                    dataIndex: "full_name"
                  },
                  {
                    title: "Tổng tiền",
                    dataIndex: "total_price"
                  },
                  {
                    title: "Ghi chú",
                    dataIndex: "note"
                  },
                  {
                    title: "Kho",
                    dataIndex: "shop_type"
                  },
                  {
                    title: "Thời gian",
                    dataIndex: "time_created"
                  } 
              ]} dataSource={dataSource} pagination={false} scroll={{ y: "calc(100vh - 240px)" }}
                style={{position: "absolute", top: 0, left: 0, bottom: 0, right: 0}}
                rowSelection={{
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                  },
                  getCheckboxProps: record => ({
                    disabled: record.name === 'Disabled User',
                    name: record.name,
                  }),
                }}
               />
              
            </div>
        </div>
      </div>
    );
  }
}
