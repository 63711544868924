import React, { Component } from 'react';
import {makeImageUrl} from '../lib/helpers';

export default class SourceContact extends Component{
  constructor(props){
    super(props);
    this.state = {
        contact: this.props.contact
    }
  }
  componentWillReceiveProps(nextProps){
    //   console.log(nextProps);
      if(nextProps.contact){
        this.setState({
            contact: nextProps.contact
        })
      }
  }
  componentWillMount(){

  }
  render(){
    if(this.state.contact && this.state.contact.source_contact){
        let img = null;
        if(this.state.contact.source_contact.type_post == 1){
            img = <img src={this.state.contact.source_contact.picture}
                onLoad={(e)=>{
                    e.target.style.opacity = 1;
                }}
            />
        } else if(this.state.contact.source_contact.image_versions2 && this.state.contact.source_contact.image_versions2.candidates){
            img = <img src={this.state.contact.source_contact.image_versions2.candidates[0]['url']}
                onLoad={(e)=>{
                    e.target.style.opacity = 1;
                }}
            />
        }
        return (
            <div className="source-contact">
                <a href={this.state.contact.source_contact.post_link} target="_blank" className="link-post"></a>
                <div className="content">
                    {this.state.contact.source_contact.caption_content && this.state.contact.source_contact.caption_content.length > 100 ? 
                    this.state.contact.source_contact.caption_content.slice(0, 100)+'..' : 
                    this.state.contact.source_contact.caption_content}
                </div>
                <div className="image">
                    {img}
                    <a href={this.state.contact.source_contact.post_link} target="_blank" className="icon-link-post"><i className="material-icons">arrow_forward</i></a>
                </div>
            </div>
        )
    }
    return '';
  }
}
