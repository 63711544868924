import React, { Component } from 'react';
import {Form, Input, Button, message, Modal} from 'antd'
import {fetchData} from '../../../lib/apis';
import {AccountMetaData, setCookie, dimens} from '../../../config/settings';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default class JoinCompany extends Component{
  constructor(props){
    super(props);
    this.state = {
        confirmLoading: false
    }
    this.formH = null;
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
   
  }
  componentDidMount(){
  }
  handleSubmit = async (values) => {
    try {
      this.setState({
        confirmLoading: true,
      }, () => {
        fetchData({
            url: `api/v1/company/join?code=${values.code_join}`,
            method: 'get',
            data: values,
            method: 'post'
        }).then((res) => {
            setCookie(dimens.name_company, JSON.stringify(res.data), 7);
            message.success({content: 'Thao tác thành công!', duration: 3})
            this.setState({
                confirmLoading: false
            }, () => {
                if (this.formH) this.formH.resetFields();
                window.location.href = "/";
            });
        }).catch((err) => {
            this.setState({
                confirmLoading: false,
            }, () => {
                let content = err.message ? err.message : 'Đã có lỗi xảy ra vui lòng thử lại sau!';
                message.warning({ content: content, key: 'error_api', duration: 2 });
            })
        })
      });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  onFinishFailed = async (error) => {
    console.log(error);
  }
  render(){
    return (
        <div className="content-m">
            <div className="list-c">
              <div className="form-company">
                  <Form
                      name="basic"
                      onFinish={this.handleSubmit}
                      onFinishFailed={this.onFinishFailed}
                      ref={(evt) => this.formH = evt}
                  >
                      <div className="logo">
                          <img src={require('../../../img/background.png')}/>
                          <h2>Tham gia chi nhánh</h2>
                      </div>
                      <Form.Item
                          name="code_join"
                          rules={[{ required: true, message: 'Mã tham gia không được để trống' }]}
                      >
                          <Input placeholder="Mã tham gia *" />
                      </Form.Item>
                      <Form.Item>
                          <Button type="primary" loading={this.state.confirmLoading} htmlType="submit">
                          Hoàn tất
                          </Button>
                          {
                            this.props.UserLogin.is_root_company ? <Link to="/company/create" style={{marginLeft: "10px", fontWeight: "500"}}>Tạo chi nhánh mới</Link> : null
                          }
                      </Form.Item>
                  </Form>
              </div>
            </div>
        </div>
    )
  }
}
