import React, { useEffect, useState, useRef } from "react";

import { Form, Input, Button, Alert, DatePicker, Result, Table, Select } from "antd";
import moment from "moment";
import { formatPrice } from "../../../lib/helpers";

const Widget = (props) => {
    return <React.Fragment>
        <div className="pl-4">
            <Table 
                dataSource={[
                    {
                        name: "Dịch vụ",
                        value: props.data.reduce((a, b) => a + b.dich_vu, 0)
                    },
                    {
                        name: "Thuốc",
                        value: props.data.reduce((a, b) => a + b.thuoc, 0)
                    },
                    {
                        name: "Kính",
                        value: props.data.reduce((a, b) => a + b.kinh, 0)
                    },
                    {
                        name: "Gói mổ",
                        value: props.data.reduce((a, b) => a + b.goi_mo, 0)
                    }
                ]}
                columns={[
                    {
                        dataIndex: "name",
                        render: (value, record) => {
                            return <React.Fragment>
                                {record.name} = <span className="text-danger">{formatPrice(record.value)} VND</span>
                            </React.Fragment>
                        }
                    }
                ]}
                showHeader={false}
                pagination={false}
                bordered={false}
                size="small"
            />
            {/* <p>Dịch vụ = <span className="text-danger">{formatPrice(props.data.reduce((a, b) => a + b.dich_vu, 0))} VND</span></p>
            <p>Thuốc = <span className="text-danger">{formatPrice(props.data.reduce((a, b) => a + b.thuoc, 0))} VND</span></p>
            <p className="mb-0">Kính = <span className="text-danger">{formatPrice(props.data.reduce((a, b) => a + b.kinh, 0))} VND</span></p> */}
        </div>
    </React.Fragment>;
};

export default Widget;
