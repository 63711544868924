import React, { Component } from 'react';
import {Switch, message, Modal, Button, Form, Input, Checkbox, Tooltip, Dropdown, Menu} from 'antd';

export default class ItemStaff extends Component{
  constructor(props){
    super(props);
    this.state = {
        show: true
    }
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
   console.log(nextProps)
  }
  componentDidMount(){
    
  }
  updateRole = (e, role_new) => {
    e.preventDefault();
    let staff = {
        ...this.props.staff
    };
    let new_role = true;
    let role_menu = [];
    staff.role_menu.forEach((role, i) => {
        if(role.role_name == role_new.role_name){
            role_menu[i] = role_new.role_name;
            new_role = false;
        } else {
            role_menu[i] = role.role_name;
        }
    })
    if(new_role){
        role_menu.push(role_new.role_name);
        staff['role_menu'].push(role_new);
        this.props.updateRoleStaff({
            staff: staff,
            data: {
                role_menu: JSON.stringify(role_menu)
            }
        });
    }
    
  }
  removeRole = (role_name) => {
    let staff = {
        ...this.props.staff
    };
    let role_menu = [];
    let role_menu_detail = [];
    staff.role_menu.forEach((role, i) => {
        if(role.role_name != role_name){
            role_menu.push(role.role_name);
            role_menu_detail.push(role);
        }
    })
    staff['role_menu'] = role_menu_detail;
    this.props.updateRoleStaff({
        staff: staff,
        data: {
            role_menu: JSON.stringify(role_menu)
        }
    });
  }
  render(){
    if(!this.state.show || !this.props.staff.user) return null;
    let user = this.props.staff.user;
    let dropDownRole = (
        <Menu className="menu-role">
            {
                this.props.listRole.map((role, i) => {
                    return <Menu.Item key={i}>
                    <a href="#" onClick={(e) => this.updateRole(e, role)}>
                        {role.display_name}
                        <span>{role.description ? role.description : 'Mô tả'}</span>
                    </a>
                </Menu.Item>
                })
            }
        </Menu>
    );
    return (
        <div className="item-data">
            <div className="item">
                <div className="avatar-c">
                    {
                        user.avatar ?
                            <img className="avatar" alt="avatar"
                                src={user.avatar}
                                onLoad={(e) => {
                                    e.target.className = "avatar-ready avatar";
                                }}
                                onError={(e) => {
                                    e.target.src = "/user.jpg"
                                }}
                            />
                            : <div className={`lettertile dark-color5`}>
                                <div>{user.fullname.slice(0, 1)}</div>
                            </div>
                    }
                    {/* <div className="dot" style={{ backgroundColor: statusOb.color }}></div> */}
                </div>
                <div className="name-connnect">
                    {user.fullname}
                </div>
            </div>
            <div className="role-list">
                {
                    this.props.staff.role_menu.map((role,i) => {
                        return <div key={`${role.role_menu_id}`} 
                        className={`role-item ${role.role_name}`}>
                            <span className="">{role.display_name}</span>
                            {
                                this.props.staff.user_id == this.props.UserLogin._id && role.role_name == 'admin'
                                ? null : <i className="fal fa-times" onClick={() => this.removeRole(role.role_name)}></i>
                            }
                        </div>
                    })
                }
                <Dropdown visible={this.state.visible} 
                onVisibleChange={(e) => this.setState({visible: e})}
                 overlay={dropDownRole} placement="bottomRight" trigger={['click']}>
                    <div className="btn-add-role">
                        <i className="fal fa-plus"></i>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
  }
}
