import React, { useRef, useState } from 'react';
import { Switch, message, Modal, Button, Form, Input, Tooltip, DatePicker, Table, Alert, Result } from 'antd';
import moment from 'moment';

import WidgetFormKBYT from "../../khaibaoyte/form";
import { useEffect } from 'react';

const KhaiBaoYTeFaceView = (props) => {
    const [ statusJoin, setstatusJoin ] = useState(true);

    return (
        <div id="kbyt-face-box" className="list-connect content-m">
            <div className="list-c">
                {
                    !statusJoin ? 
                    <Result 
                        title="403"
                        subTitle="Bạn không có quyền truy cập dữ liệu"
                        status={403}
                    />
                    : <WidgetFormKBYT 
                        companyId={props.companySelected.company_id}
                        userLogin={props.UserLogin}
                        isFaceView={true}
                        socket={props.socket}
                        setstatusJoin={setstatusJoin}
                    />
                }
            </div>
        </div>
    )
}

export default KhaiBaoYTeFaceView;
