import React, { useEffect, useMemo, useRef } from "react";

const AudioRecord = (props) => {
    const ref = useRef();
    
    useEffect(() => {
        ref.current.src = props.file;
        ref.current.load();
    }, [ref.current])

    // const Audio = useMemo(() => {
    //     return 
    // }, [ props.file ])

    return <React.Fragment>
        <div className="d-flex mb-3" style={{ background: "#f1f3f4", borderRadius: "15px" }}>
            <audio 
                controls="controls" 
                name="media" 
                className="audio"
                ref={ref}
            >
                <source type="audio/*" />
            </audio>
        </div>
    </React.Fragment>
}

export default AudioRecord;