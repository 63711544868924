import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";

import { LoadingComponent } from '../../widgets';

import { Form, Input, Select, Checkbox, Radio, Button, Table, message, DatePicker, Alert } from "antd";

import moment from "moment";
import qs from "qs";

const KhaiBaoYTe = (props) => {
    const [setting, setSetting] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [loadingForm, setLoadingForm] = useState(false);
    const [listLevel, setListLevel] = useState({
        loading: false,
        data: []
    })
    const [listDepartment, setListDepartment] = useState({
        loading: false,
        data: []
    })

    const form_feedback = useRef();

    const params_search = new URLSearchParams(props.history.location.search);

    const [valueSuccess, setValueSuccess] = useState(params_search.get("success"));

    const company_id = props.match.params.company_id;

    const initialValues = useRef({

    })

    const getSetting = async () => {
        try {
            setLoading(true);
            let response = await props.fetchData({
                url: `api/v1/public/company/setting?company_id=${props.match.params.company_id}`
            })
            setSetting(response.data);

            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }

    const onSubmit = async () => {
        try {
            setLoadingForm(true);

            let values = null;

            try {
                values = await form_feedback.current.validateFields();
            } catch (err) {
                let field_name = err.errorFields[0]['name'][0];
                document.getElementById(`form_${field_name}`).scrollIntoView({
                    behavior: 'smooth',
                    block: "start"
                });
                throw ({ message: err.errorFields[0]['errors'][0] });
            }

            let response = await props.fetchData({
                url: `api/v1/company/register_email`,
                method: "post",
                data: {
                    company_id: company_id,
                    ...values
                }
            })

            setLoadingForm(false);

            window.location.href = props.history.location.pathname + '?success=1';

        } catch (err) {
            setLoadingForm(false);
            showMessage("error", err.message);
        }
    }

    useEffect(() => {
        getSetting();
        getLevel();
        getDepartment();
    }, [])

    const showMessage = (type, text) => {
        message[type](text);
    }

    const getLevel = async () => {
        try {
            setListLevel({
                ...listLevel,
                loading: true
            });

            let response = await props.fetchData({
                url: `api/v1/service/list?type=LIST_CHUCVU&limit=100&company_id=${props.match.params.company_id}`,
                method: "get",
            })

            setListLevel({
                loading: false,
                data: response.data
            });

        } catch (err) {
            setListLevel({
                ...listLevel,
                loading: false
            });
            showMessage("error", err.message);
        }
    }

    const getDepartment = async () => {
        try {
            setListDepartment({
                ...listLevel,
                loading: true
            });

            let response = await props.fetchData({
                url: `api/v1/service/list?type=LIST_BOPHAN&limit=100&company_id=${props.match.params.company_id}`,
                method: "get",
            })

            setListDepartment({
                loading: false,
                data: response.data
            });

        } catch (err) {
            setListDepartment({
                ...listLevel,
                loading: false
            });
            showMessage("error", err.message);
        }
    }

    return <div id="app-container" style={{ background: "#fff", overflowY: "auto", paddingTop: `${props.statusBarHeight}px` }} className={"page-feedback"}>
        <Helmet>
            <title>Đăng ký Email</title>
        </Helmet>
        {
            setting ? <React.Fragment>
                <div className="container">
                    <div className="row">
                        <img src={setting.banner} className="img-fluid img-thumbnail col-12" alt="Responsive image" />
                    </div>
                </div>
                {
                    valueSuccess ?
                        <div className="container body-content mt-5">
                            <div className="mb-3 row">
                                <Button onClick={() => {
                                    window.location.href = props.history.location.pathname;
                                }}><i className="fa fa-arrow-left mr-2"></i> Quay lại</Button>
                            </div>
                            <div className="row justify-content-center alert-success">
                                <span className="alert">
                                    <h4>Đăng ký Email thành công</h4>
                                </span>
                            </div>
                        </div>
                        : <div className="container body-content mt-5">
                            <div className="row border p-3 pt-5 pb-5 p-md-5" >
                                <div className="col-12">
                                    <h3 className="text-center"><b>Đăng ký Email</b></h3>
                                </div>
                                <Form
                                    className="col-12"
                                    ref={form_feedback}
                                    initialValues={initialValues.current}
                                    layout="vertical"
                                >
                                    <Form.Item label="Họ và tên" name="full_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: "không được để trống"
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Nhập họ và tên.." size="large" />
                                    </Form.Item>
                                    <Form.Item label="Bộ phận" name="department" 
                                        rules={[
                                            {
                                                required: true,
                                                message: "không được để trống"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch={!props.isAppNative}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            placeholder="Chọn bộ phận.."

                                        >
                                            {
                                                listDepartment.data.map((item) => {
                                                    return <Select.Option value={item.name}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Chức vụ" name="level" 
                                        rules={[
                                            {
                                                required: true,
                                                message: "không được để trống"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch={!props.isAppNative}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            placeholder="Chọn chức vụ.."
                                            
                                        >
                                            {
                                                listLevel.data.map((item) => {
                                                    return <Select.Option value={item.name}>{item.name}</Select.Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Email mong muốn" name="email_note" >
                                        <Input placeholder="Nhập chức vụ.." size="large" />
                                    </Form.Item>
                                    {/* <Form.Item label="Ghi chú" name="note">
                                        <Input.TextArea size="large" />
                                    </Form.Item> */}
                                </Form>
                                <Form.Item className="col-12 mt-3">
                                    <Button loading={loadingForm} size="large" id="submitform" className="w-100" type="primary" onClick={onSubmit}>Gửi đăng ký</Button>
                                </Form.Item>
                            </div>
                        </div>
                }
            </React.Fragment> : loading ? <LoadingComponent /> : <div className="text-center p-5">
                <h5>Chi nhánh chưa được thiết lập, vui lòng thử lại!</h5>
            </div>
        }
    </div>
}

export default KhaiBaoYTe;