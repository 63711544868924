import React, { Component, useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Upload, Alert, notification, Tooltip, Spin, Select } from 'antd';
import { UploadOutlined, InboxOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import { fetchData } from "../../lib/apis";

import { sleep } from "../../lib/helpers";

import * as XLSX from 'xlsx';

const ImportCustomer = (props) => {
    const [file, changeFile] = useState();
    const [message, handleMessage] = useState();
    const [type, handleType] = useState(1);

    const [loading, handleLoading] = useState(false);

    const [task, handleTask] = useState({
        total: 0
    });

    const [reload, handleReload] = useState(false);

    const form = useRef();

    useEffect(() => {
        if (loading) {
            (async () => {
                try {
                    let values = await form.current.getFieldsValue();

                    let data = [];

                    if (values['type'] == 1) {
                        data = values['data'].split("\n").map((item) => {
                            return {
                                full_name: "",
                                phone: item.replace(/\s/, "")
                            }
                        }).filter((item) => item.phone)
                    } else {
                        data = await readFile(values['upload']['file']);
                        data = data.map((item) => {
                            return {
                                full_name: item.split(',')[0],
                                phone: item.split(',')[1],
                                address: item.split(',')[2] || "",
                                email: item.split(',')[3] || "",
                            }
                        }).filter((item) => item.phone)
                    }

                    task.total = data.length;
                    task.success = 0;
                    task.error1 = 0;

                    handleReload(!reload);

                    while (data.length > 0 && loading) {
                        let data_post = data.splice(0, 50);

                        let response = await fetchData({
                            url: `api/v1/contact/import`,
                            method: 'post',
                            data: {
                                data: data_post
                            }
                        })

                        task.success += response.success;
                        task.error1 += response.error1;

                        handleTask({ ...task });

                        await sleep(1200);
                    }

                    handleLoading(false);

                    handleMessage({
                        text: "Thao tác thành công",
                        type: "success"
                    })

                    props.onFinish();

                } catch (err) {
                    handleLoading(false);
                    handleMessage({
                        text: err.message || "Đã có lỗi xảy ra",
                        type: "error"
                    })
                }
            })()
        }
    }, [loading])

    useEffect(() => {
        if (props.visible) {
            form.current.resetFields();
            handleTask({
                total: 0,
                success: 0,
                error1: 0
            })
        }
    }, [props.visible])

    const readFile = (file) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsBinaryString(file, "UTF-8");
            reader.onload = function (evt) {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                /* Update state */
                resolve(data.split('\n'));
            }
            reader.onerror = function (evt) {
                reject(evt);
            }
        })
    }

    return <Modal
        title={'Tải lên khách hàng'}
        visible={props.visible}
        onCancel={props.hide}
        footer={null}
        className="ant-modal-20"
        centered
    >
        <Form
            ref={form}
            initialValues={{
                type: 1
            }}
        >
            {
                message ? <Alert message={message.text} type={message.type} className="mb-3" /> : null
            }

            <Form.Item name="type">
                <Select value={type} onChange={(e) => handleType(e)}>
                    <Select.Option value={1}>Nhập text</Select.Option>
                    <Select.Option value={2}>Nhập file</Select.Option>
                </Select>
            </Form.Item>

            {
                type == 2 ?
                    <Form.Item name="upload">
                        <Upload.Dragger beforeUpload={() => false} accept={'.xlsx'} showUploadList={false} onChange={(value) => changeFile(value.file)}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">{file ? file.name : 'Support for a single or bulk upload.'}</p>
                        </Upload.Dragger>
                    </Form.Item> :
                    <React.Fragment>
                        <Form.Item name="data">
                            <Input.TextArea style={{ minHeight: "200px" }} placeholder="0362xxxxxx" />
                        </Form.Item>
                        <p className="ant-upload-text">Mỗi dòng 1 khách</p>
                    </React.Fragment>
            }
            {
                task ?
                    <Form.Item>
                        <p>Tổng: {task.total || 0}</p>
                        <p>Thành công: {task.success || 0}</p>
                        <p>Khách đã tồn tại: {task.error1 || 0}</p>
                    </Form.Item> : null
            }
            <div className="d-flex align-items-center justify-content-end">
                <Button
                    htmlType="button"
                    className="mr-3 d-flex align-items-center justify-content-center"
                    onClick={() => window.open(`/file-mau-khach-hang.xlsx`)}>
                    <DownloadOutlined /> Tải file mẫu
                </Button>
                {
                    loading ? <Button htmlType="button" type="danger"
                        className="d-flex align-items-center justify-content-center"
                        onClick={() => handleLoading(false)}><Spin
                            indicator={<LoadingOutlined style={{ fontSize: 16, color: "#fff" }} spin />}
                            className="mr-2 d-flex" /> Dừng</Button> :
                        <Button htmlType="button" type="primary" onClick={() => handleLoading(true)} >Thực hiện</Button>
                }
            </div>
        </Form>
    </Modal>
}

export default ImportCustomer;
