import React, { Component } from 'react';
import { Row, Col, Input, Button, Avatar } from 'antd';
import moment from 'moment';

export default class PopupFilterPost extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listPosts: [],
            select_media_id: null,
            post_temp: null,
            filter_bol: false
        }
        this.hideFiltrerPost = this.hideFiltrerPost.bind(this);
    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {
        if (!this.state.filter_bol) {
            // this.setState({
            //     select_media_id: '',
            //     post_temp: ''
            // })
        }
    }
    selectPost = (post) => {
        this.setState({ select_media_id: post.media_id, post_temp: post })
    }
    hideFiltrerPost = () => {
        this.setState({
            select_media_id: '',
            post_temp: ''
        }, () => {
            this.props.hideFiltrerPost();
        })
    }
    actionFilter = (post) => {
        this.setState({
            filter_bol: true
        }, () => {
            this.props.selectFilterPost(post);
        })
    }
    handleScroll(e) {
        if (e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop) {
            this.props.getPost();
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="list-post">
                        <Input.Search
                            placeholder="ID"
                            onSearch={value => {
                                let data = this.props.data.filter((post, i) => {
                                    if (post.media_id == value) return post;
                                })
                                if (data.length > 0) this.setState({ select_media_id: data[0]['media_id'], post_temp: data[0] })
                            }}
                            style={{ width: '100%' }}
                        />
                        <div className="list-all">
                            <div className="post-infinite-scroll" onScroll={this.handleScroll.bind(this)}>
                                {
                                    this.props.data ?
                                        this.props.data.map((post, i) => {
                                            return <div key={post.media_id} className={this.state.select_media_id == post.media_id ? "list-post-filter-item selected" : "list-post-filter-item"}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.selectPost(post)
                                                }}
                                            >
                                                <div className="image" style={{ backgroundImage: `url(${post.picture ? post.picture : require('../img/no_images.png')})` }}></div>
                                                <div className="content">
                                                    <div className="title">
                                                        <span className="id-name">{post.media_id}</span>
                                                        <div className="time">{moment(post.created_time).format('kk:mm, Do MMMM YYYY')}</div>
                                                    </div>
                                                    <div className="owner">
                                                        <div className="h-avatar">
                                                            <img className="avatar"
                                                                src={post.from ? post.from.avatar : ''}
                                                                onError={(e) => e.target.src = require('../img/user.jpg')}
                                                            />
                                                        </div>
                                                        <span>{post.from ? post.from.full_name : ''}</span>
                                                    </div>
                                                    {/* <div className="notification-item">27-3-2020 2h</div> */}
                                                </div>
                                            </div>
                                        })
                                        : null
                                }
                            </div>
                        </div>
                        <div className="bottom d-md-none">
                            <Button onClick={this.hideFiltrerPost}>Bỏ lọc</Button>
                            {
                                this.state.post_temp ?
                                    <Button type="primary" onClick={() => this.actionFilter(this.state.post_temp)}>Lọc</Button> :
                                    <Button type="primary" disabled >Lọc</Button>
                            }

                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-none d-md-block">
                    <div className="select-post">
                        {
                            this.state.post_temp ?
                                <div className="content">
                                    <div className="title">
                                        Lọc theo bài viết
                                        <span>{this.state.post_temp.media_id}</span>
                                    </div>
                                    <div className="post-select">
                                        <div className="info-owner">
                                            <div className="h-avatar">
                                                <img className="avatar"
                                                    src={this.state.post_temp.from ? this.state.post_temp.from.avatar : ''}
                                                    onError={(e) => e.target.src = require('../img/user.jpg')}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px" }}>
                                                <span style={{ color: "rgb(78, 106, 164)" }}>{this.state.post_temp.from ? this.state.post_temp.from.full_name : ''}</span>
                                                <span style={{ fontSize: "12px", color: "rgb(97, 103, 112)" }}>{moment(this.state.post_temp.created_time).format('kk:mm, DD/MM/YYYY')}</span>
                                            </div>
                                        </div>
                                        <div>
                                            {this.state.post_temp.caption_content}
                                        </div>
                                        {
                                            this.state.post_temp.picture ?
                                                <div className="image" style={{ marginTop: "10px" }}><img src={this.state.post_temp.picture} style={{ width: "100%" }} /></div> : null
                                        }
                                    </div>
                                </div> :
                                <div className="empty-post content">Vui lòng chọn 1 bài viết từ bên trái</div>
                        }
                        <div className="bottom">
                            <Button onClick={this.hideFiltrerPost}>Bỏ lọc</Button>
                            {
                                this.state.post_temp ?
                                    <Button type="primary" onClick={() => this.actionFilter(this.state.post_temp)}>Lọc</Button> :
                                    <Button type="primary" disabled >Lọc</Button>
                            }

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
