import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {fetchData} from '../lib/apis';
import ItemTag from '../options/item-tag';

import { SketchPicker } from 'react-color';
import {Button, Modal} from 'antd';

export default class PopupManageTags extends Component{
  constructor(props){
    super(props);
    this.state = {
        handleTag: {},
        list_tags: this.props.tagsOption,
        new_tag: false,
        reload: false
    }
    this.handleColorTag = this.handleColorTag.bind(this);
    this.saveTag = this.saveTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
    this.setState({
        list_tags: nextProps.tagsOption
    })
  }
  componentDidMount(){
    
  }
  handleColorTag = (tag_id, tag_color, show = true) => {
    this.setState({
        handleTag : {
            tag_color: tag_color,
            tag_id: tag_id
        },
        showSelectColor: show
    })
  }
  newTag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(!this.state.new_tag){
        let list_tags = this.state.list_tags;
        list_tags.push({
            tag_color: '#2196f3',
            tag_name: 'nhãn',
            tag_id: 'new_'+new Date().getTime()
        });
        this.setState({
            new_tag: true,
            list_tags: list_tags
        })
    }
  }
  saveTag = (tag) => {
    let url = 'api/v2/tag/add';
    let method = 'post';
    if(tag.tag_id.toString().indexOf('new') == -1){
        url = `api/v2/tag/update/${tag.tag_id}`;
        method = 'put';
    } else{
        this.setState({
            new_tag: false
        })
    }
    fetchData({
        url: url,
        method: method,
        data: {
            tag_color: tag.tag_color,
            tag_name: tag.tag_name
        }
    }).then((res) => {
        if(res.code == 200){
            let tags = this.props.tagsOption;
            if(tag.tag_id.toString().indexOf('new') == -1){
                for(let i = 0; i < tags.length; i++){
                    if(tags[i].tag_id == tag.tag_id){
                        tags[i] = tag;
                        break;
                    }
                }
            } else{
                tags[tags.length - 1] = res.data;
            }
            this.props.handleTagsOption(tags);
        }
        return true;
    }).catch((err) => {
        console.log(err);
        return false;
    })
  }
  removeTag = (tag_id) => {
    fetchData({
        url: `api/v2/tag/delete`,
        method: 'post',
        data: {
            tag_id: tag_id
        }
    }).then((res) => {
        if(res.code == 200){
            let tags = this.props.tagsOption;
            for(let i = 0; i < tags.length; i++){
                if(tags[i].tag_id == tag_id){
                    tags.splice(i, 1)
                    break;
                }
            }
            this.props.handleTagsOption(tags);
        }
    }).catch((err) => {
        console.log(err);
    })
  }
  hide = () => {
    let list_tags = this.state.list_tags;
    if(list_tags.length > 0 && list_tags[list_tags.length - 1]['tag_id'].toString().indexOf('new') != -1){
        list_tags.pop();
    }
    this.setState({
        new_tag: false
    })
    this.props.hide();
  }
  render(){
    // if(!this.props.show){
    //     return null;
    // }
    return (
        <Modal 
            title={'Quản lý nhãn'}
            visible={this.props.show}
            footer={[
                <Button key="back" onClick={this.hide}>
                Đóng
                </Button>,
                <Button key="submit" type="primary" onClick={this.newTag}>
                Thêm mới
                </Button>,
            ]}
            onCancel={this.hide}
            className="modal-h-antd modal-content-md"
        >
             {
                this.state.showSelectColor ?
                <div id="modal" className="modal-color" style={{zIndex: 99999}}>
                    <div className="modal-evt" onClick={() => this.setState({showSelectColor: false})}></div>
                    <div className="modal-body">
                        <SketchPicker
                            color={ this.state.handleTag ? this.state.handleTag.tag_color : '#2196f3' }
                            onChange={(color) => this.setState({handleTag: {tag_color: color.hex, tag_id: this.state.handleTag ? this.state.handleTag.tag_id : null}})}
                        /> 
                    </div>
                </div>
                : ''
            }
            <div className="modal-body modal-form">
                <table className="table-option-tags">
                    <thead>
                        <tr>
                            <th className="name_tag">Tên nhãn</th>
                            <th>Màu</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.list_tags.map((item, i) => {
                            return item.tag_id.toString().indexOf('new') == -1 ?
                                <ItemTag
                                    saveTag={this.saveTag} key={i} tag={item}
                                    handleTag={this.state.handleTag} handleColorTag={this.handleColorTag}
                                    removeTag={this.removeTag}
                                />
                                : <ItemTag
                                    saveTag={this.saveTag} edit={true} key={i} tag={item}
                                    handleTag={this.state.handleTag}
                                    handleColorTag={this.handleColorTag}
                                    setNewTag={() => {
                                        let list_tags = this.state.list_tags;
                                        list_tags.splice(list_tags.length - 1);
                                        this.setState({ new_tag: false, list_tags: list_tags })
                                    }}
                                    removeTag={this.removeTag}
                                />
                        })}
                    </tbody>
                </table>
            </div>
        </Modal>
    )
    return(
        <div className="modal modal-h modal-manage-tags">
            {
                this.state.showSelectColor ?
                <div id="modal" className="modal-color" style={{zIndex: 99999}}>
                    <div className="modal-evt" onClick={() => this.setState({showSelectColor: false})}></div>
                    <div className="modal-body">
                        <SketchPicker
                            color={ this.state.handleTag ? this.state.handleTag.tag_color : '#2196f3' }
                            onChange={(color) => this.setState({handleTag: {tag_color: color.hex, tag_id: this.state.handleTag ? this.state.handleTag.tag_id : null}})}
                        /> 
                    </div>
                </div>
                : ''
            }
            <div className="modal-fix" onClick={() => this.hide()}></div>
            <div className="modal-content modal-content-md">
                <div className="modal-header">
                    <div>
                        <i className="material-icons" style={{marginRight: '10px'}}>edit</i>
                        Quản lý nhãn
                    </div>
                    <div style={{cursor: 'pointer'}}><i className="material-icons" onClick={() => this.props.hide()}>close</i></div>
                </div>
                <div className="modal-body modal-form">
                    <table className="table-h table-option-tags">
                        <thead>
                            <tr>
                                <th className="name_tag">Tên nhãn</th>
                                <th>Màu</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.list_tags.map((item, i) => {
                                return item.tag_id.toString().indexOf('new') == -1 ?
                                <ItemTag 
                                saveTag={this.saveTag} key={i} tag={item} 
                                handleTag={this.state.handleTag} handleColorTag={this.handleColorTag}
                                removeTag={this.removeTag} 
                                />
                                : <ItemTag 
                                saveTag={this.saveTag} edit={true} key={i} tag={item}
                                handleTag={this.state.handleTag} 
                                handleColorTag={this.handleColorTag} 
                                setNewTag={() => {
                                    let list_tags = this.state.list_tags;
                                    list_tags.splice(list_tags.length - 1);
                                    this.setState({new_tag: false, list_tags: list_tags})
                                }}
                                removeTag={this.removeTag} 
                                />
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer">
                    <button className="btn-h" onClick={() => this.hide()}>Bỏ qua</button>
                    <button className="btn-h blue" onClick={(e) => this.newTag(e)}>Thêm mới</button>
                </div>
            </div>
        </div>
    )
  }
}
