import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const InteractiveDetailComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_interactive_detail')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_interactive_detail"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Nguồn",
                    dataIndex: "source"
                },
                {
                    title: "Nhân viên",
                    responsive: ["md"],
                    dataIndex: "sale",
                    render: (item) => {
                        return item ? item.fullname : ""
                    }
                },
                {
                    title: "Khách hàng",
                    responsive: ["md"],
                    dataIndex: "user_id"
                },
                {
                    title: "Khách mới",
                    dataIndex: "is_cus_new",
                    render: (item) => {
                        return item ? <i className="far fa-check"></i> : null
                    }
                },
                {
                    title: "Khách cũ",
                    dataIndex: "is_cus_new",
                    render: (item) => {
                        return !item ? <i className="far fa-check"></i> : null
                    }
                }
            ]}
            pagination={false}
            summary={pageData => {
                let total_cus_new = 0;
                let total_cus_old = 0;

                pageData.forEach(({ is_cus_new }) => {
                    total_cus_new += is_cus_new ? 1 : 0;
                    total_cus_old += is_cus_new ? 0 : 1;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_cus_new}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_cus_old}</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />

    </div>
}

export default InteractiveDetailComponent;