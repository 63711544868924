import React, { Component } from 'react';
import { Button, Input, Form, Modal, Steps, Select, Radio, DatePicker, TimePicker, Spin, Checkbox, Upload, Pagination, Tooltip, Alert, InputNumber } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined, UploadOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { fetchData } from '../../../lib/apis';
import qs from 'qs';

const { Option } = Select;

export default class RatingView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            total: 0,
            loading: false,
            setting: null
        }
        this.limit = 20;
        this.page = 1;
    }
    componentWillMount = () => {
        this.getListData();
        this.getSetting();
    }
    componentDidMount = () => {

    }

    save = async (values) => {
        try {
            this.setState({ loadingForm: true });

            let response = await fetchData({
                url: `api/v1/rating/setting`,
                data: values,
                method: 'post'
            });

            this.setState({ loadingForm: false, setting: response.data})

            this.props.notification({
                content: 'Thao tác thành công',
                title: 'Cập nhập thiết lập',
                type: 'success'
            })

        } catch (err) {
            this.setState({ loadingForm: false })
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Cập nhập thiết lập',
                type: 'error'
            })
        }
    }

    getListData = async (type) => {
        try {
            this.setState({ loading: true });
            if (type == 'reset') {
                this.page = 1;
            }
            let params = {
                page: this.page,
                limit: this.limit
            }
            if (this.formFilter) params = { ...params, ...this.formFilter.getFieldsValue() };

            let res = await fetchData({
                url: `api/v1/rating/log?${qs.stringify(params)}`
            });
            this.setState({ listData: res.data, loading: false, total: res.total });
        } catch (err) {
            this.setState({ loading: false });
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    getSetting = async (type) => {
        try {
            let res = await fetchData({
                url: `api/v1/rating/setting`
            });
            this.setState({
                setting: res.data
            })
        } catch (err) {
            this.props.notification({
                content: err.message || 'Đã có lỗi xảy ra',
                title: 'Kết nối dữ liệu',
                type: 'error'
            })
        }
    }

    handleFilterData = ({ type, input }) => {
        this.page = 1;
        if (type == 'form' && input.target.tagName == 'INPUT' && input.target.type == 'text') {
            if (this.timeSearch) clearTimeout(this.timeSearch);
            this.timeSearch = setTimeout(() => {
                this.getListData({ type: 'reset' });
            }, 300);
        } else {
            this.getListData({ type: 'reset' });
        }
    }

    render() {
        return (
            <div className="list-connect content-m">
                <div className="list-c">
                    <Modal
                        title={'Thiết lập'}
                        visible={this.state.visibleForm}
                        className={"form-modal"}
                        footer={null}
                        onCancel={() => this.setState({ visibleForm: false })}
                        width={800}
                    >
                        <Form
                            name="basic"
                            ref={(evt) => this.formH = evt}
                            onFinish={this.save}
                            initialValues={{
                                content: this.state.setting ? this.state.setting.content : "",
                                time_interactive: this.state.setting ? this.state.setting.time_interactive : 24,
                                hours_send_range: this.state.setting ? this.state.setting.hours_send_range : "",
                                is_active: this.state.setting ? this.state.setting.is_active : false,
                                content_reply_1: this.state.setting ? this.state.setting.content_reply_1 : "",
                                content_reply_2: this.state.setting ? this.state.setting.content_reply_2 : ""
                            }}
                            layout="vertical"
                        >
                            {
                                this.state.setting && this.state.setting.is_active ? 
                                <Alert type="info" message="Khảo sát đang được thực hiện"/> :
                                <Alert type="info" message="Khảo sát đang tắt"/>
                            }
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="content"
                                        rules={[{ required: true, message: 'Nội dung tin nhắn không được để trống' }]}
                                        label="Nội dung"
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <div style={{ marginBottom: "15px" }}>
                                        <Button type="default" size={"small"} style={{ marginRight: "5px" }}
                                            onClick={() => this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + '#{page_name}' })}
                                        >{`#{PAGE_NAME}`}</Button> Chèn tên page
                                                    </div>

                                    <div style={{ marginBottom: "15px" }}>
                                        <Button type="default" size={"small"} style={{ marginRight: "5px" }}
                                            onClick={() => this.formH.setFieldsValue({ content: this.formH.getFieldValue('content') + '#{SPIN_1|SPIN_2}' })}
                                        >{`#{SPIN_1|SPIN_2}`}</Button> Chọn từ khóa ngẫu nhiên (Khuyên dùng)
                                            </div>
                                    <Form.Item name="hours_send_range" label="Giờ khảo sát">
                                        <Input placeholder="vd: 09-16" />
                                    </Form.Item>
                                    <Form.Item label="Thời gian tương tác cuối">
                                        <div className="d-flex align-items-center">
                                            <Form.Item name="time_interactive" noStyle>
                                                <InputNumber min={1}/>
                                            </Form.Item>
                                            <div className="ml-2">Giờ trước</div>
                                        </div>
                                    </Form.Item>
                                    <Form.Item name="is_active" valuePropName="checked" style={{display: "none"}}>
                                        <Checkbox>Kích hoạt</Checkbox>
                                    </Form.Item>
                                </div>
                                <div className="col-md-6">
                                    <Form.Item
                                        name="content_reply_1"
                                        label="Nội dung TL khách (Hài lòng)"
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                    <Form.Item
                                        name="content_reply_2"
                                        label="Nội dung TL khách (Không hài lòng)"
                                    >
                                        <Input.TextArea style={{ minHeight: "120px" }} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div style={{ marginTop: "5px", marginBottom: "15px"}} className="note">
                                <i>Lưu ý: </i>
                                <p><i>- Áp dụng cho tất cả các fanpage được kích hoạt trong chi nhánh.</i></p>
                            </div>
                            <Form.Item className="text-right">
                                <Button htmlType="button" type="ghost" style={{marginRight: "5px"}} disabled={this.state.loadingForm} onClick={() => {
                                    this.formH.setFieldsValue({ is_active: false })
                                    this.formH.submit();
                                }}>
                                    Tắt
                                </Button>
                                <Button htmlType="button" type="primary" disabled={this.state.loadingForm} onClick={() => {
                                    this.formH.setFieldsValue({ is_active: true })
                                    this.formH.submit();
                                }}>
                                    Bật
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <div style={{ marginBottom: "15px" }} className="table-header">
                        <h3>Log khảo sát: {this.state.total}</h3>
                        <div className="d-flex overflow-auto" style={{ marginBottom: "15px" }}>
                            <Tooltip title={'Làm mới'}>
                                <a className="btn btn-primary text-white mr-2" onClick={this.getListData}><i className="far fa-sync"></i></a>
                            </Tooltip>
                            <a className="btn btn-primary text-white mr-2" onClick={() => this.setState({ visibleForm: true })}><i className="far fa-cog mr-2"></i> Thiết lập</a>
                        </div>
                        <Form
                            ref={(evt) => this.formFilter = evt}
                            initialValues={{
                                status: "",
                            }}
                            onChange={(e) => this.handleFilterData({ type: 'form', input: e })}
                        >
                            <div className="row justify-content-between">
                                <div className="col-7">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Item name="page_id" >
                                                <Input placeholder="Page id"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Item name="status">
                                                <Select style={{ width: "100%" }} onChange={(e) => this.handleFilterData({ type: 'select', input: e })}>
                                                    <Select.Option value="">Trạng thái</Select.Option>
                                                    <Select.Option value={1}>Gửi thành công</Select.Option>
                                                    <Select.Option value={2}>Gửi thất bại</Select.Option>
                                                    <Select.Option value={3}>Đã phản hồi</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Pagination
                                        total={this.state.total}
                                        showSizeChanger
                                        pageSize={this.limit}
                                        pageSizeOptions={[20, 50, 100, 200]}
                                        current={this.page}
                                        onShowSizeChange={(current, size) => {
                                            this.limit = size;
                                            this.getListData();
                                        }}
                                        onChange={(current) => {
                                            this.page = current;
                                            this.getListData();
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className="table-responsive">
                        <Spin spinning={this.state.loading}>
                            <table className="table table-striped table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Stt</th>
                                        <th>Page ID</th>
                                        <th>UID</th>
                                        <th>Log</th>
                                        <th>Trạng thái</th>
                                        <th>Ngày phản hồi</th>
                                        <th>Thời gian</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.listData.length > 0 ?
                                            this.state.listData.map((item, i) => {
                                                let stt = this.limit * (this.page - 1) + (i + 1);
                                                return <tr key={item._id}>
                                                    <td>
                                                        {stt}
                                                    </td>
                                                    <td>{item.page_id}</td>
                                                    <td><a href={`/chat/${item.contact_id}`} target="_blank">{item.user_id}</a></td>
                                                    <td>
                                                        <textarea className="form-control" value={item.log} style={{minHeight: "100px"}}></textarea>
                                                    </td>
                                                    <td>
                                                        {item.status == 0 ? <span>Đang xử lý</span> : item.status == 1 ?
                                                            <span className="text-success">Thành công</span> :
                                                            item.status == 2 ? <span className="text-danger">Thất bại</span> :
                                                                <span className="text-primary">Đã phản hồi</span>}
                                                    </td>
                                                    <td>{item.date_rating ? moment(item.date_rating).format('DD-MM-YYYY') : null}</td>
                                                    <td>{moment(item.created_time).format('HH:mm:ss DD-MM-YYYY')}</td>
                                                </tr>
                                            }) : <tr>
                                                <td colSpan="12" className="text-center">Chưa có dữ liệu</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    }
}
