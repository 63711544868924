import React, { useState, useEffect } from "react";

import { Modal, Spin, Empty } from "antd";

import { WidgetSearchMedical } from "../../../widgets";
import { NotificationMessage } from "../../../lib/helpers";
import { fetchData } from '../../../lib/apis';

const Popup = (props) => {
    const [data, setData ] = useState(null);
    const [loading, setloading ] = useState(false);
    const { visible, hide } = props;

    useEffect(() => {
        if(visible){
            getData();
        }
    }, [visible])

    const getData = async () => {
        setloading(true);
        try{
            setData(null);
            let response = await fetchData({url: `api/v1/${props.contact_id}/search-medical`, method: 'get'});
            setData(response.data);
        } catch(err){
            NotificationMessage({ type: "warning", response: err })
        }
        setloading(false);
    }

    return <Modal
        title="Tra cứu khám bệnh"
        visible={visible}
        onCancel={hide}
        footer={null}
        className="modal-search-medical"
        width={600}
    >
        <div id="content_tra_cuu" className="mb-5 search-medical" >
            <Spin spinning={loading} >
                {
                    data ? 
                    <WidgetSearchMedical data={data} type={2} />
                    : <Empty description="Chưa có dữ liệu" className='mt-3'/>
                }
            </Spin>
        </div>
    </Modal>
}

export default Popup;