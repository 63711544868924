import React from 'react';

import { Switch, message, Modal, Button, Form, Input, Checkbox, notification, Steps, Spin, Alert, TreeSelect, Tooltip, Select, Tag, Tabs, DatePicker, Table } from 'antd';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/vi_VN';

const InteractiveComponent = (props) => {
    const { exportToCSV, loading, listData } = props;

    return <div className="table-responsive">
        <div className="text-right">
            <Button type="primary" onClick={() => {
                exportToCSV({
                    dom: document.querySelector('#statistic_interactive')
                })
            }} className="mb-2" style={{ borderRadius: "5px" }}>
                <i className="far fa-download"></i>
            </Button>
        </div>

        <Table
            id="statistic_interactive"
            bordered
            loading={loading}
            dataSource={listData}
            columns={[
                {
                    title: "Nguồn",
                    dataIndex: "source"
                },
                {
                    title: "Nhân viên",
                    dataIndex: "sale",
                    responsive: ["md"],
                    render: (item) => {
                        return item ? item.fullname : ""
                    }
                },
                {
                    title: "Khách mới",
                    dataIndex: "cus_new"
                },
                {
                    title: "Khách cũ",
                    dataIndex: "cus_old"
                }
            ]}
            pagination={false}
            summary={pageData => {
                let total_cus_new = 0;
                let total_cus_old = 0;

                pageData.forEach(({ cus_new, cus_old }) => {
                    total_cus_new += cus_new;
                    total_cus_old += cus_old;
                });

                return pageData.length > 0 && (
                    <Table.Summary.Row>
                        <Table.Summary.Cell className="d-none d-md-table-cell"></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>Tổng</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_cus_new}</b></Table.Summary.Cell>
                        <Table.Summary.Cell className="text-danger"><b>{total_cus_old}</b></Table.Summary.Cell>
                    </Table.Summary.Row>
                );
            }}
        />

    </div>
}

export default InteractiveComponent;