import React, { Component } from 'react';
import cx from "classnames";

export default class ItemTag extends Component{
  constructor(props){
    super(props);
  }
  componentWillMount(){

  }
  componentWillReceiveProps(nextProps){
    
  }
  handleClick = (tag) => {
    if(tag.parent_id == 0){
      this.props.handleParent(tag.tag_id);
    } else if(this.props.handleUpdateTag){
      this.props.handleUpdateTag({
        tag: tag,
        contact: this.props.contactInfoSelected
      })
    }
  }
  render(){
    return(
        <div 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.handleClick(this.props.tag)
            }} 
            className={cx('item-tag-h', this.props.active ? "active" : "", this.props.tag.parent_id == 0 ? "tag-parent" : "")} 
            style={{backgroundColor: this.props.tag.tag_color}}
        >
            {this.props.tag.tag_name}
        </div>
    )
  }
}
